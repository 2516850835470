import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'lu-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss']
})
export class FileChooserComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  @Input() file: File;
  @Input() acceptType: string;
  @Input() acceptFormats: Array<string>;
  @Output() fileChange = new EventEmitter<File>();
  public dropOver = false;
  public accept = 'image/*';
  constructor() { }

  ngOnInit() {}

  fileDroped(files: FileList) {
    if (files.length === 0
      || this.file === files[0]) {
      return;
    }
    // Filter by accept subs
    const file = files[0];
    if (typeof this.accept === 'string') {
      let match = false;
      const accepts = this.accept.split(',').map(ac => ac.toLocaleLowerCase());
      _.forEach(accepts, accept => {
        const wildcard = accept.match(/(^.+\/)\*$/);
        if (wildcard && file.type.match('^' + wildcard[1])) {
          return match = true;
        }
        if (file.type.match('^' + accept + '$')) {
          return match = true;
        }
        if (accept === 'image/jpg'
          && file.type.match(/^image\/jpeg$/)) {
          return match = true;
        }
      });
      if (!match) {
        return;
      }
    }
    this.file = file;
    this.fileChange.emit(this.file);
  }

  fileSelectChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files.length === 0
      || this.file === target.files[0]) {
      return;
    }
    const fileType = target.files[0].name.split('.').splice(-1)[0];
    const isInclude = _.includes(this.acceptFormats, fileType);
    if (target.files.length > 0 && !isInclude) {
      this.removeFile();
      return alert('フォーマットが異なっています。正しいフォーマットをアップロードしてください。\n[' + this.acceptType + ']');
    }
    this.file = target.files[0];
    this.fileChange.emit(this.file);
  }

  browseFile(event: MouseEvent) {
    this.fileInput.nativeElement.click();
    event.preventDefault();
    event.stopPropagation();
  }

  removeFile() {
    this.file = undefined;
    this.fileInput.nativeElement.value = '';
    this.fileChange.emit(this.file);
  }

  async convertToDataURL(file: File): Promise<string> {
    const reader = new FileReader();
    return await new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  }

  convertToFile(dataURI: string, fileName: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeType = dataURI.match(/:([a-z]+\/[a-z]+);/)[1];
    const content = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      content[i] = byteString.charCodeAt(i);
    }
    return new File([content], fileName, { type: mimeType });
  }
}
