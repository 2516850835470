/**
 * .lu API
 * .lu API仕様とモデル定義
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The permission level is the stage at whitch data can be read.
 * 0 - Allow read within public data.
 * 1 - Allow read within confidential data.
 * 2 - Allow read within secret data.  3 - Allow read all data.
 */
export type SecurityClearance = 0 | 1 | 2 | 3;

export const SecurityClearance = {
    NUMBER_0: 0 as SecurityClearance,
    NUMBER_1: 1 as SecurityClearance,
    NUMBER_2: 2 as SecurityClearance,
    NUMBER_3: 3 as SecurityClearance
};

