import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray, AbstractControl, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEmpty, unionWith, isEqual, findIndex, chunk, get, find, filter as _filter } from 'lodash';
import * as moment from 'moment';

import { GroupsResolver } from '@lu/resolvers/groups';
import {
  ConnectionTwitter,
  ConnectionInstagram,
  ConnectionTikTok,
  Member,
  ConnectionYoutube,
} from '@lu/models';
import { environment } from '@lu/environment';
import { prefectures } from '@lu/definitions/prefectures';
import { MatchingService } from '@lu/services/matching.service';
import { UserSearchService } from '@lu/services/user-search.service';

type ageStep = 10 | 20 | 30 | 40 | 50 | 60;
export interface SearchCondition {
  // search phrase for fullName, fullNameKana, displayName
  keyword: string | null;
  groups: Member['groups'];
  tags: Member['tag_masters'][];
  segment: Member['member_status_master'][];
  gender: Member['gender'][];
  married: Member['married'][];
  hasChildren: Member['hasChildren'][];
  children: {
    gender: Member['gender'][];
    /** Steps of age groups for building range query. */
    age: ageStep[];
  };
  /** Steps of age groups for building range query. */
  age: ageStep[];
  /** Steps for building range query.
   *  Form values will be strings.
   */
  weight: (Member['weight'] | string)[];
  mainURL: boolean[];
  prefecture: string;
  connections: {
    serviceName: any,
    /**
     * Steps of followersCount for building range query.
     * Used only when seviceName is twitter or instagram.
     * Form values will be strings.
     */
    followersCount?: ((ConnectionInstagram | ConnectionTwitter)['followersCount'] | string)[];
  }[];
  order: string /** json strings of SortQuery for select-box */;
}

export interface UserSearchCondition {
  data: {
    [key: string]: SearchCondition
  };
  followersCountData: {
    instagram: [];
    twitter: [];
  };
}

const isNumOrNumericString = (str: any) => str !== null && str !== '' && !isNaN(+str);

@Component({
  selector: 'lu-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnDestroy {
  @Output() statusChanges = new EventEmitter<SearchCondition>();
  @Output() valueChanges = new EventEmitter<SearchCondition>();

  public userSearchForm = new FormGroup({
    keyword: new FormControl(null),
    groups: new FormControl([]),
    tags: new FormControl([]),
    segment: new FormControl([]),
    gender: new FormControl([]),
    married: new FormControl([]),
    age: new FormControl([]),
    weight: new FormArray([
      new FormControl(null, Validators.pattern(/^((?<!0)[1-9]\d*|0)(\.\d*)?$/)),
      new FormControl(null, Validators.pattern(/^((?<!0)[1-9]\d*|0)(\.\d*)?$/)),
    ]),
    mainURL: new FormControl([]),
    hasChildren: new FormControl([]),
    children: new FormGroup({
      gender: new FormControl([]),
      age: new FormControl([]),
    } as { [K in keyof SearchCondition['children']]: AbstractControl }),
    connections: new FormControl([]),
    prefecture: new FormControl(null),
    order: new FormControl('order:ASC'),
  } as { [K in keyof SearchCondition]: AbstractControl });
  public followersCountStepsForm = new FormGroup({
    /** 'NULL' strings is alias of null for display select-box options */
    ['instagram']: new FormArray([new FormControl('NULL'), new FormControl('NULL')]),
    ['twitter']: new FormArray([new FormControl('NULL'), new FormControl('NULL')]),
  });
  public groupList: Array<any>;
  public segmentList: any; // Array<Master & {_id: string}>
  public tagList: any; // Array<Master & { _id: string }>
  public tagGroupList: any; // Array<MasterGroup & { _id: string }>
  public accountList = [
    { serviceName: 'instagram', label: 'Instagram' },
    { serviceName: 'twitter', label: 'Twitter' },
    { serviceName: 'youtube', label: 'Youtube' },
    { serviceName: 'tiktok', label: 'Tiktok' },
  ];
  public followersCountSteps = [
    [1001, 5001, 10001, 15001, 20001, 30001, 40001, 50001],
    [5000, 10000, 15000, 20000, 30000, 40000, 50000]
  ];
  public ageSteps: ageStep[] = [10, 20, 30, 40, 50, 60];
  public marriedList = [
    { label: '既婚', value: true },
    { label: '独身', value: false },
    { label: '不明', value: null },
  ];
  public genderList = [
    { label: '未設定', value: null },
    { label: '女性', value: Member.GenderEnum.Female },
    { label: '男性', value: Member.GenderEnum.Male },
    { label: '未回答', value: Member.GenderEnum.Unanswered },
  ];
  public hasChildrenList = [
    { label: '子あり', value: true },
    { label: '子なし', value: false },
    { label: '不明', value: null },
  ];
  public mainULRList = [
    { label: 'あり', value: true },
    { label: 'なし', value: false },
  ];
  public sortedList = [
    { label: '媒体指定並び順', query: 'order:ASC' },
    { label: 'システム登録順', query: 'created_at:DESC' },
    { label: 'あいうえお順', query: 'fullNameKana:ASC' },
    { label: 'Instagramフォロワー順', query: 'connection_instagrams_followersCount:DESC' }
  ];
  public prefectures = prefectures;
  public panelOpenState = false;
  private onDestroy$ = new Subject();

  constructor(
    private groupsResolver: GroupsResolver,
    private apiService: MatchingService,
    private userSearchService: UserSearchService
  ) { }

  ngOnInit() {
    this.subscribeMemberSegments();
    this.subscribePublicTags();
    this.subscribeTagGroups();
    this.groupsResolver.resolve().subscribe(groups => {
      this.groupList = groups;
    });
    this.userSearchForm.statusChanges.subscribe(o => this.statusChanges.emit(o));
    this.userSearchForm.valueChanges.subscribe(o => this.valueChanges.emit(o));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  subscribeMemberSegments() {
    this.apiService.getMaster('member-status-masters', { _sort: 'order:ASC', parentMasterGroupId_null: false })
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(segments =>
        this.segmentList = segments, err => console.error(err));
  }

  subscribePublicTags() {
    this.apiService.getMaster(
      'tag-masters',
      {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      }
    )
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(tags => this.tagList = tags, err => console.error(err));
  }

  subscribeTagGroups() {
    this.apiService.getMaster(
      'tag-masters',
      {
        _sort: 'order:ASC',
        parentMasterGroupId_null: true
      }
    )
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(tags => this.tagGroupList = tags, err => console.error(err));
  }

  set valid(_: boolean) { }
  get valid() {
    return this.userSearchForm.valid;
  }

  set invalid(_: boolean) { }
  get invalid() {
    return this.userSearchForm.invalid;
  }

  set pristine(_: boolean) { }
  get pristine() {
    return this.userSearchForm.pristine;
  }

  set dirty(_: boolean) { }
  get dirty() {
    return this.userSearchForm.dirty;
  }

  set touched(_: boolean) { }
  get touched() {
    return this.userSearchForm.touched;
  }

  set untouched(_: boolean) { }
  get untouched() {
    return this.userSearchForm.untouched;
  }

  existsNonGroupedMaster(list: any[]) {
    return _filter(list, o => !get(o, 'parentMasterGroupId')).length > 0;
  }

  existsBelongedMaster(id: string, list: any[]): boolean {
    return !!find(list, ['parentMasterGroupId', id]);
  }

  /**
   * Set value to controls having unique values array.
   */
  setValuesUnion(ctrl: AbstractControl, ...value) {
    const list = Array.isArray(ctrl.value) ? ctrl.value : [];
    list.push(...value);
    ctrl.setValue(unionWith(list, isEqual));
  }

  /**
   * Remove values from controls having array.
   */
  spliceValues(ctrl: AbstractControl, ...value) {
    const list = Array.isArray(ctrl.value) ? ctrl.value : [];
    value.forEach(v => {
      const pos = findIndex(list, o => isEqual(o, v));
      if (pos === -1) {
        return;
      }
      list.splice(pos, 1);
    });
    ctrl.setValue(list);
  }

  /**
   * Sort values among passed control to arguments.
   */
  sortContorlValues(sort = 'asc', ...comparedCtrls: AbstractControl[]) {
    const sortableObjects = comparedCtrls.map(c => ({ ctrl: c, value: c.value }));
    sortableObjects.sort((a, b) => {
      const diff = a.value - b.value;
      const [aCtrl, bCtrl] = [a.ctrl, b.ctrl];
      const isNumberString = (s: string) => s !== null && s !== '' && !isNaN(+s);
      if (!isNumberString(a.value) || !isNumberString(b.value)) {
        return 1;
      }
      switch (sort) {
        case 'asc':
          if (diff < 0) {
            a.ctrl = bCtrl;
            b.ctrl = aCtrl;
          }
          break;
        case 'desc':
          if (diff > 0) {
            a.ctrl = bCtrl;
            b.ctrl = aCtrl;
          }
          break;
      }
      return diff;
    });
    sortableObjects.map(obj => obj.ctrl.setValue(obj.value));
  }

  generatedOrCondition(querList: any) {
    const index = querList.length;
    const orQuery = {} as any;
    if (index <= 0) {
      return orQuery;
    }
    querList.forEach((element: any, i: number) => {
      const key = Object.keys(element)[0];
      const value = Object.values(element)[0];
      orQuery[`_where[_or][${i}][${key}]`] = value;
    });

    return orQuery;
  }

  generateOrQuery() {
    return { _or: [] };
  }

  buildSearchQurey() {
    const {
      keyword, groups, tags, segment, gender, married, age,
      weight, mainURL, hasChildren, children, connections, prefecture, order
    } = this.userSearchForm.value as SearchCondition;
    const followersCountSteps = this.followersCountStepsForm.value as { [key: string]: (number | string)[] };

    const searchData = {
      data: this.userSearchForm.value,
      followersCountData: followersCountSteps
    }  as UserSearchCondition;
    this.userSearchService.userSearchSubject.next(searchData);

    const query = { _where: [], _sort: 'order:ASC', _limit: 0, _start: 0 };

    query._where.push({ leaved: false });

    if (keyword) {
      const keywords = keyword.split(/\s+/).filter(v => !!v);
      if (keyword.length) {
        const orQuery = this.generateOrQuery();
        keywords.forEach(key => {
          orQuery._or.push(
            {
              fullName_containss: key
            },
            {
              fullNameKana_containss: key
            },
            {
              displayName_containss: key
            }
          );
        });
        const idRegexp = /^id:(\d+)$/i;
        keywords.filter(k => idRegexp.test(k)).forEach(k => {
          const memberNumber = +k.match(idRegexp)[1];
          orQuery._or.push({
            id: memberNumber
          });
        });
        query._where.push(orQuery);
      }
    }
    if (!isEmpty(segment)) {
      query._where.push({ member_status_master: segment });
    }
    if (!isEmpty(groups)) {
      query._where.push({ groups_in: groups });
    }
    if (!isEmpty(tags)) {
      query._where.push({ tag_masters_in: tags });
    }
    if (!isEmpty(connections)) {
      const orQuery = this.generateOrQuery();
      connections.forEach(list => {
        switch (list.serviceName) {
          case 'instagram':
            orQuery._or.push({
              connection_instagrams_null: false
            });
            break;
          case 'twitter':
            orQuery._or.push({
              connection_twitter_null: false
            });
            break;
          case 'youtube':
            orQuery._or.push({
              connection_youtube_null: false
            });
            break;
          case 'tiktok':
            orQuery._or.push({
              connection_tik_tok_null: false
            });
            break;
          default:
            break;
        }
      });
      query._where.push(orQuery);
    }
    Object.entries(followersCountSteps).forEach(entry => {
      const [serviceName, steps] = entry;
      if (entry[1].every(o => !isNumOrNumericString(o))) {
        return;
      }
      chunk(steps, 2).forEach(list => {
        list.forEach((step, i) => {
          switch (serviceName) {
            case 'instagram':
              if (step !== 'NULL') {
                if (i % 2 === 0) {
                  query._where.push({
                    'connection_instagrams.followersCount_gte': step
                  });
                } else {
                  query._where.push({
                    'connection_instagrams.followersCount_lte': step
                  });
                }
              }
              break;
            case 'twitter':
              if (step !== 'NULL') {
                if (i % 2 === 0) {
                  query._where.push({
                    'connection_twitter.followersCount_gte': step
                  });
                } else {
                  query._where.push({
                    'connection_twitter.followersCount_lte': step
                  });
                }
              }
              break;
            default:
              break;
          }
        });
      });
    });
    if (!isEmpty(married)) {
      const orQuery = this.generateOrQuery();
      married.forEach(doc => {
        if (doc === null) {
          orQuery._or.push({
            married_null: true
          });
        } else {
          orQuery._or.push(
            {
              married: doc
            }
          );
        }
      });
      query._where.push(orQuery);
    }
    if (!isEmpty(gender)) {
      const orQuery = this.generateOrQuery();
      gender.forEach(doc => {
        if (doc === null) {
          orQuery._or.push({
            gender_null: true
          });
        } else {
          orQuery._or.push({
            gender: doc
          });
        }
      });
      query._where.push(orQuery);
    }
    if (!isEmpty(hasChildren)) {
      const orQuery = this.generateOrQuery();
      hasChildren.forEach(doc => {
        if (doc === null) {
          orQuery._or.push({
            hasChildren_null: true
          });
        } else {
          orQuery._or.push({
            hasChildren: doc
          });
        }
      });
      query._where.push(orQuery);
    }
    if (!isEmpty(children.age) || !isEmpty(children.gender)) {
      const kidAge = children.age;
      const kidGender = children.gender;
      const birthDay = 'children.birthDay';
      const genders = 'children.gender';
      if (!isEmpty(kidAge)) {
        const orQuery = this.generateOrQuery();
        kidAge.forEach(doc => {
          let objs = {} as any;
          const pos = this.ageSteps.indexOf(doc);
          const [prev, next] = [this.ageSteps[pos - 1], this.ageSteps[pos + 1]];
          if (!prev) {
            objs[birthDay + '_gte'] = (moment().subtract(next, 'year').toDate()).toISOString();
            // end of steps
          } else if (!next) {
            objs[birthDay + '_lte'] = (moment().subtract(doc, 'year').toDate()).toISOString();
          } else {
            objs[birthDay + '_lte'] = (moment().subtract(doc, 'year').toDate()).toISOString();
            objs[birthDay + '_gte'] = (moment().subtract(next, 'year').toDate()).toISOString();
          }
          orQuery._or.push(objs);
          objs = {};
        });
        query._where.push(orQuery);
      }
      if (!isEmpty(kidGender)) {
        const orQuery = this.generateOrQuery();
        kidGender.forEach(doc => {
          let objs = {} as any;
          objs[genders] = doc;
          orQuery._or.push(objs);
          objs = {};
        });
        query._where.push(orQuery);
      }
    }
    if (!isEmpty(age)) {
      const orQuery = this.generateOrQuery();
      const birthDay = 'birthDay';
      age.forEach(step => {
        const objs = {} as any;
        const pos = this.ageSteps.indexOf(step);
        const [prev, next] = [this.ageSteps[pos - 1], this.ageSteps[pos + 1]];
        if (!prev) {
          orQuery._or.push({ birthDay_gte: (moment().subtract(next, 'year').toDate()).toISOString() });
          // end of steps
        } else if (!next) {
          orQuery._or.push({ birthDay_lte: (moment().subtract(step, 'year').toDate()).toISOString() });
        } else {
          objs[birthDay + '_lte'] = (moment().subtract(step, 'year').toDate()).toISOString();
          objs[birthDay + '_gte'] = (moment().subtract(next, 'year').toDate()).toISOString();
          orQuery._or.push(objs);
        }
      });
      query._where.push(orQuery);
    }
    if (!isEmpty(weight) && weight.some(o => isNumOrNumericString(o))) {
      const orQuery = this.generateOrQuery();
      const objs = {} as any;
      const weights = 'weight';
      chunk(weight, 2).forEach(weightChunk => {
        weightChunk.forEach((w, i) => {
          if (!isNumOrNumericString(w)) {
            return;
          }
          switch (i % 2) {
            case 0:
              objs[weights + '_gte'] = w;
              break;
            case 1:
              objs[weights + '_lte'] = w;
              break;
          }
        });
      });
      orQuery._or.push(objs);
      query._where.push(orQuery);
    }
    if (!isEmpty(mainURL)) {
      const orQuery = this.generateOrQuery();
      mainURL.forEach(hasUrl => {
        orQuery._or.push({
          mainSiteURL_null: !hasUrl
        });
      });
      query._where.push(orQuery);
    }
    if (!isEmpty(prefecture)) {
      query._where.push({ 'address.address_containss': prefecture });
    }
    if (order) {
      query._sort = order;
    }
    return query;
  }

  devlog(...args) {
    if (environment.production) {
      return;
    }
    args.forEach(v => console.log(v));
  }
}
