import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material';

export interface ConfirmationDialogData {
  html?: string;
  text?: string;
  apply?: boolean;
  cancel?: boolean;
  applyText?: string;
  cancelText?: string;
  applyButtonColor?: ThemePalette;
  cancelButtonColor?: ThemePalette;
}

@Component({
  selector: 'lu-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {
    if (typeof this.data !== 'object') {
      this.data = {};
    }
    if (typeof this.data.apply !== 'boolean') {
      this.data.apply = true;
    }
    if (typeof this.data.cancel !== 'boolean') {
      this.data.cancel = true;
    }
  }

  ngOnInit() {}
}
