import {
  Component,
  OnInit,
  Input,
  Injectable,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChange,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, NativeDateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import * as moment from 'moment';
import * as _ from 'lodash';

// extend mat datapicker
const MY_DATE_FORMATS = {
   parse: {
     dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
   },
   display: {
     dateInput: 'input',
     monthYearLabel: {year: 'numeric', month: 'short'},
     dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
     monthYearA11yLabel: {year: 'numeric', month: 'long'},
   }
};
@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
  getDateNames(): string[] {
    const dateNames: string[] = [];
    for (let i = 0; i < 31; i++) {
      dateNames[i] = String(i + 1);
    }
    return dateNames;
  }
  format(date: Date, displayFormat: any): string {
    const padZero = (char: any): string => _.padStart(String(char), 2, '0');
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    if (displayFormat === 'input') {
      return `${year}-${padZero(month)}-${padZero(day)} ${padZero(hour)}:${padZero(minutes)}`;
    } else {
      return `${year}/${padZero(month)}/${padZero(day)}`;
    }
  }
}

@Component({
  selector: 'lu-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MyDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ]
})
export class DatetimePickerComponent implements OnInit, OnChanges {
  @Input() label;
  @Input() appearance = 'outline';
  @Input() disabled = false;
  @Input() date = new Date();
  @Input() invalid = false;
  @Output() datetimeChange = new EventEmitter<Date>();
  @ViewChild('timePicker', {static: true}) timePicker: NgxMaterialTimepickerComponent;
  public privateDate = new Date();
  public formControl = new FormControl(null);
  public dateFieldFocused = false;
  public timeFieldFocused = false;

  constructor() { }

  ngOnInit() {
    // datepickerに変更値をアサイン
    this.datetimeChange
      .subscribe((date: Date) => {
        // console.log(date);
        this.formControl.patchValue(date);
      });
  }

  ngOnChanges(changes: {[key: string]: SimpleChange}) {
    const {date, invalid} = changes;
    if (date) {
      if (this.date instanceof Date) {
        this.privateDate = new Date(this.date);
      } else {
        this.privateDate = new Date();
      }
      this.datetimeChange.emit(this.date);
    }
    if (invalid) {
      if (invalid.currentValue === true) {
        this.formControl.setErrors({invalid: true});
      } else {
        this.formControl.setErrors(null);
      }
    }
  }

  dateChange(result: MatDatepickerInputEvent<Date>) {
    const d = result.value;
    const [year, month, date] = [d.getFullYear(), d.getMonth(), d.getDate()];
    this.privateDate.setFullYear(year, month, date);
    this.datetimeChange.emit(this.privateDate);
  }

  onTimeSelected(time: string) {
    const [hour, minutes] = time.split(':');
    this.privateDate.setHours(+hour);
    this.privateDate.setMinutes(+minutes);
    this.datetimeChange.emit(this.privateDate);
  }

  fixDateModelValue(target: HTMLInputElement, unit: any) {
    const valid = +target.value > 0;
    if (!valid) {
      let collectValue = moment(this.date).get(unit);
      if (unit === 'month') {
        ++collectValue;
      }
      target.value = String(collectValue);
    }
    target.value = _.padStart(target.value, 2, '0');
    target.type = 'text';
  }

  get today() {
    return new Date();
  }

  get maxDateAtCurrentDate() {
    return moment(this.date).daysInMonth();
  }

  get _year() {
    return this.privateDate.getFullYear();
  }
  set _year(a) {}

  get _month() {
    return this.privateDate.getMonth() + 1;
  }

  get _date() {
    return this.privateDate.getDate();
  }

  get _hour() {
    return this.privateDate.getHours();
  }

  get _minutes() {
    return this.privateDate.getMinutes();
  }

  get _zeroPaddingMonth() {
    return _.padStart(String(this._month), 2, '0');
  }
  set _zeroPaddingMonth(a) {}

  get _zeroPaddingDate() {
    return _.padStart(String(this._date), 2, '0');
  }
  set _zeroPaddingDate(a) {}

  get _zeroPaddingHour() {
    return _.padStart(String(this._hour), 2, '0');
  }
  set _zeroPaddingHour(a) {}

  get _zeroPaddingMinutes() {
    return _.padStart(String(this._minutes), 2, '0');
  }

  set _zeroPaddingMinutes(a) {}

  get _stringTime() {
    return `${this._zeroPaddingHour}:${this._zeroPaddingMinutes}`;
  }

  yearChanges(value: any) {
    this.privateDate.setFullYear(+value);
    this.datetimeChange.emit(this.privateDate);
  }

  monthChanges(value: string) {
    const trimedValue = value.substr(-2, 2);
    // 空文字(数字以外)か0ならスルー
    if (!+trimedValue) {
      return;
    }
    const nextMonthIndex = +trimedValue - 1;
    this.privateDate.setMonth(nextMonthIndex);
    this.datetimeChange.emit(this.privateDate);
  }

  dateChanges(value: string) {
    const trimedValue = value.substr(-2, 2);
    if (!+trimedValue) {
      return;
    }
    this.privateDate.setDate(+trimedValue);
    this.datetimeChange.emit(this.privateDate);
  }

  hourChanges(value: string) {
    const trimedValue = value.substr(-2, 2);
    if (!trimedValue) {
      return;
    }
    this.privateDate.setHours(+trimedValue);
    this.datetimeChange.emit(this.privateDate);
  }

  minutesChanges(value: string) {
    const trimedValue = value.substr(-2, 2);
    if (!trimedValue) {
      return;
    }
    this.privateDate.setMinutes(+trimedValue);
    this.datetimeChange.emit(this.privateDate);
  }

  carryAmount(event: KeyboardEvent, unit: any) {
    const target = event.target as HTMLInputElement;
    const castedValue = !target.value ? target.value : +target.value;
    // 最大か最小で、上下矢印キー入力のみ
    if (!event.code.match(/^(ArrowUp|ArrowDown)$/i)
        || (String(castedValue) !== target.attributes.getNamedItem('min').value
            && String(castedValue) !== target.attributes.getNamedItem('max').value)) {
      return;
    }
    // increment or decrement
    const amount = event.code.match(/^ArrowUp$/i) ? 1 : -1;
    // 年
    if (unit === 'year') {
      const nextYear = this._year <= 0 ? this._year : this._year + amount ;
      this.privateDate.setFullYear(nextYear);
      this.datetimeChange.emit(this.privateDate);
    // 月
    } else if (unit === 'month') {
      const nextMonth = this._month + amount - 1;
      this.privateDate.setMonth(nextMonth);
      this.datetimeChange.emit(this.privateDate);
    // 日付
    } else if (unit === 'date') {
      const nextDate = this._date + amount;
      this.privateDate.setDate(nextDate);
      this.datetimeChange.emit(this.privateDate);
    // 時
    } else if (unit === 'hour') {
      const nextHour = this._hour + amount;
      this.privateDate.setHours(nextHour);
      this.datetimeChange.emit(this.privateDate);
    // 分
    } else if (unit === 'minutes') {
      const nextMinutes = this._minutes + amount;
      this.privateDate.setMinutes(nextMinutes);
      this.datetimeChange.emit(this.privateDate);
    }
    event.preventDefault();
    event.stopPropagation();
  }
}
