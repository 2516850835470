import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router, NavigationStart } from '@angular/router';
import { pairwise, filter } from 'rxjs/operators';
import { UserSearchService } from '@lu/services/user-search.service';
@Injectable()
export class UserSearchResolver implements Resolve<any> {
  state = false;
  constructor(
    private router: Router,
    private userSearchService: UserSearchService,
  ) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof NavigationStart), pairwise())
      .subscribe((events) => {
        if (events[0].url.startsWith('/user/detail')
          && events[1].url === '/user/list'
          && events[1].navigationTrigger === 'popstate') {
          this.state = true;
        } else {
          this.state = false;
        }
      });
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.state ? this.userSearchService.getSearchedData : null;
  }
}
