import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  FormArray,
  Validators
} from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

import {
  AdminUser,
  Group,
  TagMaster,
  MemberConfidentialTagMaster,
  ProjectKindMaster,
  ProductMaster,
  CategoryMaster,
  BrandMaster,
  OccupationMaster,
  IndustryMaster,
  JobMaster,
  MemberStatusMaster,
  NewGroup,
  NewTagMaster,
  NewMemberConfidentialTagMaster,
  NewProjectKindMaster,
  NewProductMaster,
  NewCategoryMaster,
  NewBrandMaster,
  NewOccupationMaster,
  NewIndustryMaster,
  NewJobMaster,
  NewMemberStatusMaster,
  ServicePermissions,
  GroupPermissions
} from '@lu/models';
import { MatSelectChange } from '@angular/material';
import { MatchingService } from '@lu/services/matching.service';
import { groupEditor, serviceAdmin, groupManager, defaultPermissions } from '@lu/definitions/role';

export type orderMaster = ProjectKindMaster | ProductMaster | CategoryMaster | BrandMaster;
export type memberMaster = TagMaster | MemberConfidentialTagMaster | OccupationMaster
  | IndustryMaster | JobMaster | MemberStatusMaster;

export type orderMasterCreate = NewProjectKindMaster | NewProductMaster | NewCategoryMaster | NewBrandMaster;
export type memberMasterCreate = NewTagMaster | NewMemberConfidentialTagMaster | NewOccupationMaster
  | NewIndustryMaster | NewJobMaster | NewMemberStatusMaster;

export type MasterCreate = (orderMasterCreate | memberMasterCreate);

export type Masters = (orderMaster | memberMaster) & { type?: Masters.TypeEnum | null, kind?: Masters.KindEnum | null };

// tslint:disable-next-line: no-namespace
export namespace Masters {
  export type TypeEnum = 'brand' | 'category' | 'industry' | 'job' | 'member-confidential-tag' |
    'member-status' | 'occupation' | 'product' | 'project-kind' | 'tag';
  export const TypeEnum = {
    Brand: 'brand' as TypeEnum,
    Category: 'category' as TypeEnum,
    Industry: 'industry' as TypeEnum,
    Job: 'job' as TypeEnum,
    MemberConfidentialTag: 'member-confidential-tag' as TypeEnum,
    MemberStatus: 'member-status' as TypeEnum,
    Occupation: 'occupation' as TypeEnum,
    Product: 'product' as TypeEnum,
    ProjectKind: 'project-kind' as TypeEnum,
    Tag: 'tag' as TypeEnum
  };
  export type KindEnum = 'order' | 'member';
  export const KindEnum = {
    Order: 'order' as KindEnum,
    Member: 'member' as KindEnum
  };
}

export interface SearchCondition {
  kind?: Masters.KindEnum;
  type: Masters.TypeEnum | 'groups';
}

@Component({
  selector: 'lu-master-edit',
  templateUrl: './master-edit.component.html',
  styleUrls: ['./master-edit.component.scss']
})
export class MasterEditComponent implements OnInit {
  public servicePermissions: ServicePermissions | GroupPermissions;
  public masterSearchForm = new FormGroup({
    condition: new FormGroup({
      kind: new FormControl(null),
      type: new FormControl(null),
    }),
    loading: new FormControl(false)
  });
  public serviceAdmin = serviceAdmin;
  public groupEditor = groupEditor;
  public groupManager = groupManager;
  public defaultPermission = defaultPermissions;
  public masterItemsForm = new FormArray([]);
  public dividedMasterItemsForm: Record<string | null, FormArray> = {};
  public noParentIdAlias = '__NO_PARENT__';
  public masterGroupItemsForm = new FormArray([]);
  public masterTypes = [
    {
      label: 'タグ',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Member,
        type: Masters.TypeEnum.Tag,
      },
    },
    {
      label: '編集部用タグ',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Member,
        type: Masters.TypeEnum.MemberConfidentialTag,
      },
    },
    {
      label: '案件種別',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Order,
        type: Masters.TypeEnum.ProjectKind,
      },
    },
    {
      label: '商材',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Order,
        type: Masters.TypeEnum.Product,
      },
    },
    {
      label: 'カテゴリー',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Order,
        type: Masters.TypeEnum.Category,
      },
    },
    {
      label: 'ブランド',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Order,
        type: Masters.TypeEnum.Brand
      },
    },
    {
      label: '媒体',
      collection: 'groups',
      condition: {
        kind: null,
        type: 'groups' as 'groups'
      },
    },
    {
      label: '職業',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Member,
        type: Masters.TypeEnum.Occupation
      },
    },
    {
      label: '業種',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Member,
        type: Masters.TypeEnum.Industry
      },
    },
    {
      label: '職種',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Member,
        type: Masters.TypeEnum.Job
      },
    },
    {
      label: '会員ステータス',
      collection: 'masters',
      condition: {
        kind: Masters.KindEnum.Member,
        type: Masters.TypeEnum.MemberStatus
      },
    },
  ];

  constructor(
    private aRoute: ActivatedRoute,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    const { condition } = this.masterTypes[0];
    this.masterSearchForm.patchValue({ condition });
    this.getDocuments(condition);
    this.searchWithCollectionChange();
    this.aRoute.data.subscribe(data => {
      const permiss = data.servicePermissions;
      if (permiss.length > 0) {
        switch (permiss[0].role) {
          case AdminUser.RoleEnum.ServiceAdmin:
            this.servicePermissions = serviceAdmin;
            break;
          case AdminUser.RoleEnum.GroupAdmin:
            this.servicePermissions = groupManager;
            break;
          case AdminUser.RoleEnum.GroupEditor:
            this.servicePermissions = groupEditor;
            break;
          default:
            this.servicePermissions = defaultPermissions;
            break;
        }
      } else {
        this.servicePermissions = defaultPermissions;
      }
    });
  }

  get masterCollectionLabel() {
    const { label } = _.find(this.masterTypes, ['condition', this.masterSearchForm.value.condition]);
    return label;
  }

  hasWritePermission(type: string = this.masterSearchForm.value.condition.type) {
    const canWrite = (base: any) => {
      return base.create && base.update && base.delete;
    };
    switch (type) {
      case 'groups':
        return canWrite(this.servicePermissions.group);
      default:
        return canWrite(this.servicePermissions.master);
    }
  }

  searchWithCollectionChange() {
    this.masterSearchForm.get('condition')
      .valueChanges
      .subscribe(condition => {
        this.getDocuments(condition);
      });
  }

  private getMasterGroups(collection: string, condition: SearchCondition): Promise<any> {
    this.dividedMasterItemsForm = {};
    this.masterGroupItemsForm.clear(); // Refresh controller
    // Resolve immediately.
    if (collection === 'groups') {
      return Promise.resolve();
    }
    // Get MasterGroups
    return new Promise<void>((resolve, reject) => {
      const masterType = condition.type === 'groups' ? 'groups' : (condition.type) + '-masters';
      const masterGroupPara = {
        parentMasterGroupId_null: true,
        _sort: 'order:ASC'
      };
      this.apiService.getMasterGroup(masterType, masterGroupPara).subscribe(
        masterGp => {
          masterGp.forEach((docSnap: Masters, i: number) => {
            const doc = docSnap;
            const data: Masters = doc;
            data.type = condition.type as Masters.TypeEnum;
            this.addMasterGroupControl(i, data);
          });
          resolve();
        }, err => {
          console.error(err);
          reject();
        }
      );
    });
  }

  private getGroupsOrMasters(collection: string, condition: SearchCondition): Promise<any> {
    this.masterSearchForm.patchValue({ loding: true });
    this.masterItemsForm.clear(); // Refresh controller
    return new Promise<void>((resolve, reject) => {
      const getGpPara = {
        _sort: 'order:ASC'
      };
      const getMasterPara = {
        parentMasterGroupId_null: false,
        _sort: 'order:ASC'
      };
      const masterType = condition.type === 'groups' ? 'groups' : (condition.type) + '-masters';
      const collectionRef = collection === 'groups' ?
        this.apiService.getGroups(getGpPara) :
        this.apiService.getMaster(masterType, getMasterPara);
      collectionRef.subscribe(
        docs => {
          docs.forEach((docSnap, i: number) => {
            const doc = docSnap;
            const data = doc;
            switch (collection) {
              case 'groups':
                data.name = (doc as Group).groupName;
                break;
              default:
                data.name = (doc as Masters).name;
                data.kind = (doc as Masters).kind;
                data.parentMasterGroupId = (doc as Masters).parentMasterGroupId;
                break;
            }
            data.type = condition.type as Masters.TypeEnum;
            this.addControl(i, data);
          });
          this.masterSearchForm.patchValue({ loding: false });
          resolve();
        }, error => {
          console.error(error);
          reject();
        }
      );
    });
  }

  async getDocuments(condition: SearchCondition) {
    // Ref from getter have a little bit lag.
    const { collection } = _.find(this.masterTypes, o => _.isEqual(o.condition, condition));
    await Promise.all([
      this.getMasterGroups(collection, condition),
      this.getGroupsOrMasters(collection, condition),
    ]);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.applyOrderChange();
    } else {
      const newParentMasterGroupId = event.container.id;
      const prevParentMasterGroupId = event.previousContainer.id;
      const targetFormArray = this.dividedMasterItemsForm[prevParentMasterGroupId];
      const targetCtrl = targetFormArray.at(event.previousIndex);
      targetCtrl.patchValue({ parentMasterGroupId: newParentMasterGroupId });
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.submitName(targetCtrl);
    }
  }

  dropGroup(event: CdkDragDrop<string[]>) {
    const ctrl = this.masterGroupItemsForm.at(event.previousIndex);
    this.masterGroupItemsForm.removeAt(event.previousIndex);
    this.masterGroupItemsForm.insert(event.currentIndex, ctrl);
    this.applyOrderChange(true);
  }

  getMasterItemsWithMasterGroupOrder() {
    const savedCtrls = [];
    this.masterGroupItemsForm.controls.forEach(masterGroupCtrl => {
      const masterGroupId = masterGroupCtrl.value.id;
      this.dividedMasterItemsForm[masterGroupId].controls.filter(ctrl => {
        savedCtrls.push(ctrl);
      });
    });
    if (this.dividedMasterItemsForm[this.noParentIdAlias] !== undefined) {
      this.dividedMasterItemsForm[this.noParentIdAlias].controls.forEach(ctrl => {
        savedCtrls.push(ctrl);
      });
    }
    return savedCtrls;
  }

  /**
   * apply order changed.
   * skip unregistered controls.
   */
  async applyOrderChange(isMasterGroup = false) {
    const savedCtrls = isMasterGroup ?
      this.masterGroupItemsForm.controls.filter(ctrl => ctrl.value.id) :
      this.getMasterItemsWithMasterGroupOrder();
    _.chunk(savedCtrls, 500).map(ctrls => {
      ctrls.forEach(async (ctrl, i) => {
        const { id, order, type } = ctrl.value;
        if (order === i || !id) {
          ctrl.patchValue({ order: i });
          return;
        }
        try {
          ctrl.patchValue({ pending: true });
          const editData = {
            order: i
          };
          const collectionType = type === 'groups' ? 'groups' : type + '-masters';
          this.apiService.updateMasterGroup(id, editData, collectionType).subscribe(data => {
            if (data) {
              return true;
            }
          }, error => console.error(error));
          ctrl.patchValue({ order: i });
        } catch (err) {
          console.error(err);
        }
        ctrl.patchValue({ pending: false });
      });
      if (isMasterGroup) {
        this.applyOrderChange();
      }
    });
  }

  defaultCtrl() {
    return new FormGroup({
      id: new FormControl(null),
      _name: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      order: new FormControl(null),
      kind: new FormControl(null),
      parentMasterGroupId: new FormControl(null),
      pending: new FormControl(false),
      type: new FormControl(null),
    });
  }

  addControl(
    index = -1,
    data: { id?: string } & Partial<Pick<Masters, 'name' | 'order' | 'kind' |
      'parentMasterGroupId' | 'type'>>
  ) {
    const ctrl = this.defaultCtrl();
    const parentMasterGroupId = data.parentMasterGroupId || this.noParentIdAlias;
    ctrl.patchValue({ ...data, _name: data.name });
    if (this.dividedMasterItemsForm[parentMasterGroupId] === undefined) {
      this.dividedMasterItemsForm[parentMasterGroupId] = new FormArray([]);
    }
    this.dividedMasterItemsForm[parentMasterGroupId].insert(index, ctrl);
    if (!ctrl.value.id) {
      ctrl.markAsDirty();
    }
  }

  addMasterGroupControl(
    index = -1,
    data: { id?: string } & Partial<Pick<Masters, 'name' | 'order' | 'kind' |
      'parentMasterGroupId' | 'type'>>
  ) {
    const ctrl = this.defaultCtrl();
    ctrl.patchValue({ ...data, _name: data.name });
    if (this.dividedMasterItemsForm[ctrl.value.id] === undefined) {
      this.dividedMasterItemsForm[ctrl.value.id] = new FormArray([]);
    }
    this.masterGroupItemsForm.insert(index, ctrl);
    if (!ctrl.value.id) {
      ctrl.markAsDirty();
    }
  }

  revertName(ctrl: AbstractControl) {
    const prevName = ctrl.value._name;
    ctrl.patchValue({ name: prevName }); // set cached name
    if (ctrl.value.id) {
      ctrl.markAsPristine();
    }
  }

  async submitName(ctrl: AbstractControl, isMasterGroup = false) {
    const { id, name, order, parentMasterGroupId, type, masterId } = ctrl.value;
    let ref: any;
    try {
      ctrl.patchValue({ pending: true });
      if (id) {
        ref = await this.updateDocument(id, name, order, parentMasterGroupId);
      } else {
        ref = await this.setDocument({ name, order, parentMasterGroupId });
      }
      // set to cache
      ref.subscribe(resultData => {
        ctrl.patchValue({
          id: id ? id : resultData.id,
          _name: name,
          pending: false,
          type,
        });
        if (!id && isMasterGroup) {
          this.dividedMasterItemsForm[resultData.id] = new FormArray([]);
        }
        ctrl.markAsPristine();
        this.applyOrderChange();
      });
    } catch (err) {
      console.error(err);
      ctrl.patchValue({ pending: false });
      // Handling submit error.
      const nameCtrl = ctrl.get('name');
      nameCtrl.valueChanges.pipe(take(1)).subscribe((() => {
        nameCtrl.setErrors({ submit: null });
      }));
      nameCtrl.setErrors({ submit: true }); // add error message
      nameCtrl.markAsTouched();
    }
  }

  autoGeneratedId() {
    const masterGroupItems = this.masterGroupItemsForm.value;
    const ids: number[] = [];
    if (masterGroupItems.length > 1) {
      masterGroupItems.map(masterGroupItem => {
        if (masterGroupItem.id) {
          const id = Number(masterGroupItem.id);
          ids.push(id);
          const childMasters = this.dividedMasterItemsForm[id].value;
          childMasters.map(childMaster => {
            ids.push(childMaster.id);
          });
        }
      });
      return (_.max(ids) + 1);
    }
    return 1;
  }

  async setDocument(
    data: Partial<Pick<Masters, 'name' | 'order' | 'kind' | 'parentMasterGroupId'>>
  ) {
    const { type } = this.masterSearchForm.value.condition;
    let masterId = '';
    if (type === 'member-confidential-tag') {
      masterId = 'tagId';
    } else if (type === 'member-status') {
      masterId = 'statusId';
    } else if (type === 'project-kind') {
      masterId = 'kindId';
    } else {
      masterId = type + 'Id';
    }
    let doc: NewGroup | MasterCreate;
    switch (type) {
      case 'groups':
        doc = {
          groupName: data.name,
          order: data.order
        };
        break;
      default:
        doc = {
          name: data.name,
          order: data.order,
          parentMasterGroupId: data.parentMasterGroupId === null ? null : data.parentMasterGroupId
        };
    }
    const collectionType = type === 'groups' ? 'groups' : type + '-masters';
    const ref = this.apiService.createMasterGroup(collectionType, doc);
    return ref;
  }

  async updateDocument(
    id: string,
    name: string,
    order: number,
    parentMasterGroupId: string
  ) {
    const { type } = this.masterSearchForm.value.condition;
    let body = {} as (NewGroup | MasterCreate);
    const data: any = {
      order,
    };
    switch (type) {
      case 'groups':
        data.groupName = name;
        body = data;
        break;
      default:
        data.name = name;
        data.parentMasterGroupId = parentMasterGroupId;
        body = data;
        break;
    }
    const collectionType = type === 'groups' ? 'groups' : type + '-masters';
    const ref = this.apiService.updateMasterGroup(id, body, collectionType);
    return ref;
  }

  async deleteMaster(
    ctrl: AbstractControl,
    index: number,
    parentMasterGroupId: string,
    isMasterGroup = false
  ) {
    const { id, type } = ctrl.value;
    if (isMasterGroup
      && this.dividedMasterItemsForm[parentMasterGroupId].length > 0) {
      alert('データを持っているグループは削除できません。');
      return;
    }
    if (id) {
      const collectionType = type === 'groups' ? 'groups' : type + '-masters';
      const ref = this.apiService.deleteMasterGroup(id, collectionType);
      ref.subscribe(
        doc => {
          if (doc) {
            return true;
          }
        }, err => {
          console.error(err);
        }
      );
    }
    if (isMasterGroup) {
      delete this.dividedMasterItemsForm[id];
      this.masterGroupItemsForm.removeAt(index);
    } else {
      this.dividedMasterItemsForm[parentMasterGroupId].removeAt(index);
    }
    this.applyOrderChange();
  }

  masterSearchFormChange(event: MatSelectChange) {
    this.masterSearchForm.patchValue({ condition: event.value });
  }

  getConnectedList() {
    const cdkConnectedIdList = [];
    this.masterGroupItemsForm.controls.forEach(ctrl => {
      if (ctrl.value.id === this.noParentIdAlias) {
        return;
      }
      if (ctrl.value.id) {
        cdkConnectedIdList.push((ctrl.value.id).toString());
      }
    });
    return cdkConnectedIdList;
  }

  log(...args) {
    console.log(...args);
  }
}
