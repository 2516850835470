import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

@Injectable()
export class PermittedGroupsResolver implements Resolve<any> {
 constructor(
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {uid} = this.afAuth.auth.currentUser;
    return this.afStore.doc<any>(`permittedGroups/${uid}`)
      .valueChanges()
      .pipe(take(1));
  }
}
