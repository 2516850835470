import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { DatetimePickerComponent } from './datetime-picker.component';

@NgModule({
  declarations: [
    DatetimePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    NgxMaterialTimepickerModule
  ],
  exports: [
    DatetimePickerComponent
  ]
})
export class DatetimePickerModule { }
