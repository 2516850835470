import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { firestore } from 'firebase/app';
import { Subject, from } from 'rxjs';
import { takeUntil, take, map } from 'rxjs/operators';
import { findIndex, isEqual, cloneDeep } from 'lodash-es';

@Component({
  selector: 'lu-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.scss']
})
export class ServiceSettingsComponent implements OnInit, OnDestroy {
  public notificationSettings: any;
  public notificationSettingsForm = new FormGroup({
    order: new FormGroup({
      member: new FormGroup({
        segments: new FormControl([]),
      }),
    }),
    created: new FormGroup({
      at: new FormControl(null),
      by: new FormControl(null),
    }),
    modified: new FormGroup({
      at: new FormControl(null),
      by: new FormControl(null),
    }),
  });
  public memberSegments = [];
  private onDestroy$ = new Subject();

  constructor(
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.getCurrentSettings();
    this.subscribeSegments();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  getCurrentSettings() {
    this.afStore.doc<any>('serviceSettings/notification')
      .valueChanges()
      .pipe(take(1))
      .subscribe(doc => {
        console.log(doc);
        this.notificationSettings = doc;
        this.notificationSettingsForm.patchValue(cloneDeep(doc));
      }, err => console.error(err));
  }

  subscribeSegments() {
    this.afStore.collection<any>('masters', ref => {
      return ref.where('kind', '==', 'member')
                .where('type', '==', 'segment');
    })
      .valueChanges({idField: '_id'})
      .pipe(
        map(list => list.sort((a, b) => a.order - b.order)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(masters => {
        this.memberSegments = masters;
      }, err => console.error(err));
  }

  unionArray(list: any[], item) {
    console.log(findIndex(list, o => isEqual(o, item)));
    if (findIndex(list, o => isEqual(o, item)) >= 0) {
      return;
    }
    list.push(item);
  }

  arrayRemove(list: any[], item) {
    const index = findIndex(list, o => isEqual(o, item));
    if (index === -1) {
      return;
    }
    list.splice(index, 1);
  }

  updateNotificationSettings() {
    const ref = this.afStore.doc('serviceSettings/notification');
    const {uid} = this.afAuth.auth.currentUser;
    const authedTimestamp = {by: uid, at: firestore.FieldValue.serverTimestamp()};
    // Create
    if (!this.notificationSettingsForm.value.created.at) {
      this.notificationSettingsForm.patchValue({
        created: authedTimestamp,
      });
    }
    this.notificationSettingsForm.patchValue({
      modified: authedTimestamp,
    });
    console.log(this.notificationSettingsForm.value);
    from(ref.set(this.notificationSettingsForm.value))
      .subscribe(() => {
        console.log('updateNotificationSettings() completed.');
      }, err => console.error(err));
  }

  updateNotificationOrderMemberSegments(event: MatCheckboxChange) {
    const ctrl = this.notificationSettingsForm.get('order.member.segments');
    const segments = ctrl.value;
    if (event.checked) {
      this.unionArray(segments, event.source.value);
    } else {
      this.arrayRemove(segments, event.source.value);
    }
    ctrl.patchValue(segments);
    console.log(segments);
  }
}
