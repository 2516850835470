import { Component, OnInit, Renderer2, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@lu/environment';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { groupEditor, Role, roles } from '@lu/definitions/role';
import { AdminUser, Group, ServicePermissions, GroupPermissions } from '@lu/models';
import { AdminService } from '@lu/services/admin.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'lu-csvdl-items-selectable',
  templateUrl: './csvdl-items-selectable.component.html',
  styleUrls: ['./csvdl-items-selectable.component.scss']
})
export class CsvdlItemsSelectableComponent implements OnInit {
  @ViewChild('accordion', { static: false }) private accordion: ElementRef;
  @ViewChild('accordionHead', { static: false }) private accordionHead: ElementRef;
  @Output() public changeCheckboxData: EventEmitter<any> = new EventEmitter();
  @Input() isEntry: boolean;
  public servicePermissions: ServicePermissions;
  public adminList: Array<AdminUser>;
  public groupList: Array<Group & { _id: string }>;
  public role: Role;
  public csvDataList = new FormGroup({});
  public accordionOpened = false;
  public basicInfoList = [];
  public personalInfoList = [];
  public profileList = [];
  public otherList = [];
  public basicInfoListForEntry = [];
  public groupEditor: any;
  public checkedRadio1: boolean;
  public checkedRadio2: boolean;
  public checkedBasic: boolean;
  public checkedPersonal: boolean;
  public basicInfoChecklist = [];
  public personalInfoChecklist = [];
  public personalInfoCheck = [];
  public basicInfoCheck = [];

  defaultBasicInfoList() {
    return [
      {
        name: 'ID',
        key: 'memberNumber',
        checked: false
      },
      {
        name: 'ニックネーム（表示名）',
        key: 'displayName',
        checked: false
      },
      {
        name: 'キャッチコピー',
        key: 'catchphrase',
        checked: false
      },
      {
        name: 'プロフィール',
        key: 'profile',
        checked: false
      },
      {
        name: '年齢',
        key: 'age',
        checked: false
      },
      {
        name: 'ハピコミュ登録日',
        key: 'anniversary',
        checked: false
      },
      {
        name: 'BlogURL',
        key: 'mainURL',
        checked: false
      },
      {
        name: 'Instagramアカウント1',
        key: 'instagram.username',
        checked: false
      },
      {
        name: 'Instagramフォロワー数',
        key: 'instagram.followersCount',
        checked: false
      },
      {
        name: 'タグ',
        key: 'tags',
        checked: false
      },
      {
        name: 'プロフィール画像1(メイン)',
        key: 'image1',
        checked: false
      },
    ];
  }

  defaultBasicInfoListForEntry() {
    return [
      {
        name: 'エントリー日',
        key: 'entriedAt',
        checked: false
      },
      {
        name: 'ステータス',
        key: 'status',
        checked: false
      }
    ];
  }

  defaultPersonalInfoList() {
    return [
      {
        name: '氏名',
        key: 'fullName',
        checked: false
      },
      {
        name: '氏名(ふりがな)',
        key: 'fullNameKana',
        checked: false
      },
      {
        name: '郵便番号',
        key: 'postalCode',
        checked: false
      },
      {
        name: '住所',
        key: 'address',
        checked: false
      },
      {
        name: 'メールアドレス1',
        key: 'email1',
        checked: false
      },
      {
        name: 'メールアドレス2',
        key: 'email2',
        checked: false
      },
      {
        name: '電話番号1',
        key: 'phoneNumber1',
        checked: false
      },
      {
        name: '電話番号2',
        key: 'phoneNumber2',
        checked: false
      }
    ];
  }

  defaultProfileList() {
    return [
      {
        name: '連携用アカウント',
        key: 'foreignKey',
        checked: false
      },
      {
        name: '会員ステータス',
        key: 'segment',
        checked: false
      },
      {
        name: '性別',
        key: 'gender',
        checked: false
      },
      {
        name: '生年月日',
        key: 'birthDay',
        checked: false
      },
      {
        name: '生年月日の非公開',
        key: 'uncertainAge',
        checked: false
      },
      {
        name: '結婚状況',
        key: 'married',
        checked: false
      },
      {
        name: '子の有無',
        key: 'hasChildren',
        checked: false
      },
      {
        name: '子供の生年月日',
        key: 'childbirthDay',
        checked: false
      },
      {
        name: '職業',
        key: 'occupation',
        checked: false
      },
      {
        name: '業種',
        key: 'industry',
        checked: false
      },
      {
        name: '職種',
        key: 'job',
        checked: false
      },
      {
        name: '身長',
        key: 'height',
        checked: false
      },
      {
        name: '体重',
        key: 'weight',
        checked: false
      },
      {
        name: '媒体',
        key: 'groups',
        checked: false
      },
      {
        name: '編集部用タグ',
        key: 'confidentialTags',
        checked: false
      },
      {
        name: '編集部備考',
        key: 'remarks',
        checked: false
      },
      {
        name: 'プロフィール画像2(サブ)',
        key: 'image2',
        checked: false
      },
      {
        name: 'プロフィール画像3(サブ)',
        key: 'image3',
        checked: false
      },
      {
        name: 'プロフィール画像4(サブ)',
        key: 'image4',
        checked: false
      },
      {
        name: 'PRボード',
        key: 'summary',
        checked: false
      },
      {
        name: '公開日時',
        key: 'openPeriod',
        checked: false
      },
    ];
  }

  defaultOtherList() {
    return [
      {
        name: 'Twitterアカウント',
        key: 'twitter.screenName',
        checked: false
      },
      {
        name: 'Twitterフォロワー数',
        key: 'twitter.followersCount',
        checked: false
      },
      {
        name: 'Instagramアカウント2',
        key: 'instagram2.username',
        checked: false
      },
      {
        name: 'Youtubeアカウント',
        key: 'youtube',
        checked: false
      },
      {
        name: 'Tiktokアカウント',
        key: 'tiktok',
        checked: false
      },
      {
        name: 'LINE認証状態',
        key: 'providerLINE',
        checked: false
      },
      {
        name: 'その他URL1',
        key: 'otherSiteURL1',
        checked: false
      },
      {
        name: 'その他URL2',
        key: 'otherSiteURL2',
        checked: false
      },
      {
        name: 'その他URL3',
        key: 'otherSiteURL3',
        checked: false
      }
    ];
  }
  constructor(
    private renderer2: Renderer2,
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private adminService: AdminService
  ) { }

  async ngOnInit() {
    const { uid } = this.afAuth.auth.currentUser;
    this.checkedRadio1 = true;
    this.checkedRadio2 = false;
    this.checkedBasic = true;
    this.checkedPersonal = false;
    const data = await this.adminService.getAdminRole(uid);
    if (data && data.role) {
      this.role = data.role;
    } else {
      this.role = null;
    }
    // for development
    if (!environment.production && this.aRoute.snapshot.queryParams.opened) {
      requestAnimationFrame(() => this.toggleAccordion());
    }
    this.changeBasicItem();
    this.groupEditor = groupEditor;
  }

  toggleAccordion() {
    const accordionHTML: HTMLElement = this.accordion.nativeElement;
    const accordionHeadHTML: HTMLElement = this.accordionHead.nativeElement;
    const headHeight = accordionHeadHTML.offsetHeight;
    const childrenElement = accordionHTML.children;
    if (this.accordionOpened) {
      // close accordion
      this.renderer2.setStyle(accordionHTML, 'height', headHeight + 'px');
    } else {
      let childrenHeight = 0;
      _.forEach(childrenElement, (elem, i) => {
        const virtual = _.some(elem.classList, className => className.match(/^virtual-.+/));
        if (!virtual) {
          childrenHeight += (elem as HTMLElement).offsetHeight;
        }
      });
      // open accordion
      this.renderer2.setStyle(accordionHTML, 'height', childrenHeight + 'px');
    }
    this.accordionOpened = !this.accordionOpened;
  }

  changeBasicItem() {
    this.checkedBasic = true;
    this.checkedRadio1 = true;
    this.checkedRadio2 = false;
    this.basicInfoChecklist = [];
    this.basicInfoCheck = [];
    this.basicInfoList = this.defaultBasicInfoList();
    this.basicInfoListForEntry = this.defaultBasicInfoListForEntry();
    this.basicInfoChecklist.push(...this.basicInfoList);
    this.basicInfoCheck.push(...this.basicInfoList);
    if (this.isEntry) {
      this.basicInfoChecklist.push(...this.basicInfoListForEntry);
      this.basicInfoCheck.push(...this.basicInfoListForEntry);
    }
    this.personalInfoList = this.defaultPersonalInfoList();
    this.profileList = this.defaultProfileList();
    this.otherList = this.defaultOtherList();
    this.basicInfoList.forEach((basicInfo, index) => {
      basicInfo.checked = true;
    });
    this.basicInfoListForEntry.forEach((basicInfoEntry, index) => {
      basicInfoEntry.checked = true;
    });
    this.personalInfoList.forEach((personalInfo, index) => {
      personalInfo.checked = false;
    });
    this.profileList.forEach((profile, index) => {
      profile.checked = false;
    });
    this.otherList.forEach((other, index) => {
      other.checked = false;
    });
    this.changeCheckboxData.emit();
  }

  changePersonalItem() {
    this.checkedPersonal = true;
    this.checkedRadio2 = true;
    this.checkedRadio1 = false;
    this.personalInfoChecklist = [];
    this.personalInfoCheck = [];
    this.basicInfoList = this.defaultBasicInfoList();
    this.basicInfoListForEntry = this.defaultBasicInfoListForEntry();
    this.personalInfoList = this.defaultPersonalInfoList();
    this.profileList = this.defaultProfileList();
    this.otherList = this.defaultOtherList();
    this.basicInfoList.forEach((basicInfo, index) => {
      if (index < 2) {
        basicInfo.checked = true;
        this.personalInfoCheck.push(basicInfo);
      } else {
        basicInfo.checked = false;
      }
    });
    this.basicInfoListForEntry.forEach((element, index) => {
      element.checked = false;
    });
    const personalListChecked = [true, false, true, true, true, false, true, false];
    this.personalInfoList.forEach((personalInfo, index) => {
      this.personalInfoList[index].checked = personalListChecked[index];
    });
    this.personalInfoList.map(personalInfo => {
      if (personalInfo.checked === true) {
        this.personalInfoCheck.push(personalInfo);
      }
    });
    this.personalInfoChecklist.push(...this.personalInfoCheck);
    this.profileList.forEach((profile, index) => {
      profile.checked = false;
    });
    this.otherList.forEach((other, index) => {
      other.checked = false;
    });
    this.changeCheckboxData.emit();
  }

  getGroupRoleName(groupPermissions: GroupPermissions) {
    const role = _.find(roles, o => {
      return _.isEqual(o.permission.group, groupPermissions);
    });
    return role ? role.roleName : '' && role.permission.group;
  }

  changeCSVCheckbox(data) {
    data.checked = !data.checked;
    if (data.checked) {
      this.checkedRadio1 ?
        this.basicInfoChecklist.push(data) :
        this.personalInfoChecklist.push(data);
    }
    if (!data.checked) {
      this.checkedRadio1 ?
        this.basicInfoChecklist = this.basicInfoChecklist.filter(uncheckData => {
          return uncheckData.name !== data.name;
        }) :
        this.personalInfoChecklist = this.personalInfoChecklist.filter(uncheckData => {
          return uncheckData.name !== data.name;
        });
    }
    const listEqual = (firstList, secondList) => {
      if (firstList.length !== secondList.length) {
        return false;
      }
      for (const listdata of firstList) {
        if (!secondList.includes(listdata)) {
          return false;
        }
      }
      return true;
    };
    if (this.checkedRadio1) {
      listEqual(this.basicInfoChecklist, this.basicInfoCheck) ?
        this.checkedBasic = true : this.checkedBasic = false;
    }
    if (this.checkedRadio2) {
      listEqual(this.personalInfoChecklist, this.personalInfoCheck) ?
        this.checkedPersonal = true : this.checkedPersonal = false;
    }
    this.changeCheckboxData.emit();
  }
}
