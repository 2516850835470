import { Component, OnInit, Input } from '@angular/core';
import { Project } from '@lu/models';

@Component({
  selector: 'lu-order-status-chip',
  templateUrl: './order-status-chip.component.html',
  styleUrls: ['./order-status-chip.component.scss']
})
export class OrderStatusChipComponent implements OnInit {
  @Input() order: Pick<Project, 'status'|'publishStatus'|'closedStatus'>;
  public orderPublishStateEnum = Project.PublishStatusEnum;
  public orderClosedReasonEnum = Project.ClosedStatusEnum;
  public orderStatusEnum = Project.StatusEnum;

  constructor() { }

  ngOnInit() {
  }

}
