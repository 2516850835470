import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lu-master-list-item',
  templateUrl: './master-list-item.component.html',
  styleUrls: ['./master-list-item.component.scss']
})
export class MasterListItemComponent implements OnInit {

  @Input() formCtrl: FormControl;
  @Input() groupLabel = '';
  @Input() label: string;
  @Input() hasWritePermission = false;
  @Input() showInsert = true;
  @Output() apply: EventEmitter<void> = new EventEmitter();
  @Output() revert: EventEmitter<void> = new EventEmitter();
  @Output() insert: EventEmitter<number> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
}
