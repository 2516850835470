import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { Reward } from '@lu/models';

@Injectable()
export class RewardsResolver implements Resolve<Reward[]> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {orderId} = route.params;
    return this.afStore.collection<Reward>(`orders/${orderId}/rewards`)
      .valueChanges({idField: '_id'})
      .pipe(take(1));
  }
}
