import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  FormArray
} from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import * as _ from 'lodash';
import { take, map } from 'rxjs/operators';
import { MatchingService } from '@lu/services/matching.service';

import {
  Group,
  Member,
  NewMember
} from '@lu/models';
import { Path } from '@lu/path';

@Component({
  selector: 'lu-user-sort',
  templateUrl: './user-sort.component.html',
  styleUrls: ['./user-sort.component.scss']
})
export class UserSortComponent implements OnInit {
  public groupSearchForm = new FormGroup({
    group: new FormControl(null),
    loading: new FormControl(false)
  });
  public groupMemberItemsForm = new FormArray([]);
  public groups: Array<Group>;
  public members: Array<Member>;
  public path = Path;
  public queryParams: Params; // for debug

  constructor(
    private aRoute: ActivatedRoute,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    this.queryParams = this.aRoute.snapshot.queryParams;
    this.aRoute.data.subscribe(data => {
      if (data.groups) {
        const defaultGroup = _.get(_.head(data.groups), 'id');
        this.groups = data.groups;
        this.groupSearchForm.patchValue({ group: defaultGroup });
        this.getGroupMembers(defaultGroup);
        this.searchWhenGroupChange();
      }
    });
  }

  searchWhenGroupChange() {
    this.groupSearchForm.get('group')
      .valueChanges
      .subscribe(groupId => {
        this.getGroupMembers(groupId);
      });
  }

  getGroupMembers(groupId: string) {
    this.groupSearchForm.patchValue({ loading: true });
    this.groupMemberItemsForm.clear(); // Refresh controller
    const param = {
      groups: groupId,
      _limit: -1,
      _sort: 'order:ASC'
    };
    this.apiService.getMember(param)
      .subscribe(members => {
        this.members = members;
        this.members.forEach(m => console.log(m.id, m.order, m.displayName));
        this.setControls(members);
        this.groupSearchForm.patchValue({ loading: false });
      }, err => {
        console.error(err);
        this.groupSearchForm.patchValue({ loading: false });
      });


  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.applyOrderChange();
  }

  sortPredicate(a: Pick<Member, 'order'>, b: Pick<Member, 'order'>): number {
    const orderDiff = a.order - b.order;
    if (orderDiff === 0 || isNaN(orderDiff)) {
      const [aAt, bAt] = [_.get(a, 'updated_at'), _.get(b, 'updated_at')];
      console.log(aAt, bAt);
      // 後に更新された方を優先とする
      return aAt && bAt ?
        (new Date(bAt)).valueOf() - (new Date(aAt)).valueOf() : -1;
    }
    return orderDiff;
  }

  /**
   * apply order changed.
   */
  async applyOrderChange() {
    const savedCtrls = this.groupMemberItemsForm.controls;
    const updatedAt = new Date().toISOString();
    const promises: Promise<void>[] = [];
    const min = Math.min(...savedCtrls.map(c => c.value.order));
    promises.push(
      ...savedCtrls.map(async (ctrl, pos) => {
        const newOrder = pos + min;
        const { id, order } = ctrl.value;
        if (newOrder === order) {
          // No changes.
          return;
        }
        ctrl.patchValue({ pending: true });
        try {
          // 詳細画面での更新に被る可能性があるのでorderのみ更新する
          const userId: string = id;
          const update = { order: newOrder, updated_at: updatedAt };
          this.apiService.updateMemberOrder(userId, { order: newOrder }).subscribe(updatedMember => {
            if (updatedMember) {
              return updatedMember;
            }
          }, error => console.error(error));

          ctrl.patchValue(update);
        } catch (err) {
          // 更新失敗した場合ログを出力してスキップ
          console.error(err.message, { order, newOrder, id, data: ctrl.value });
        } finally {
          ctrl.patchValue({ pending: false });
        }
      })
    );

    await Promise.all(promises);

    this.groupMemberItemsForm.controls.sort((aCtrl, bCtrl) => {
      const [a, b]: Pick<Member, 'order'>[] = [aCtrl.value, bCtrl.value];
      return this.sortPredicate(a, b);
    });
  }

  // 必要なもののみ
  defaultCtrl() {
    return new FormGroup({
      uid: new FormControl(null),
      order: new FormControl(0),
      id: new FormControl(0),
      fullName: new FormControl(null),
      fullNameKana: new FormControl(null),
      displayName: new FormControl(null),
      image1: new FormControl(null),
      // metafield
      pending: new FormControl(false),
      data: new FormControl(null),
    } as { [K in keyof Partial<NewMember>]: AbstractControl });
  }

  setControls(memebrs: Member[]) {
    this.groupMemberItemsForm.reset([]);
    memebrs.forEach(member => {
      try {
        const ctrl = this.defaultCtrl();
        ctrl.patchValue({ ...member, data: member });
        this.groupMemberItemsForm.push(ctrl);
      } catch (err) {
        console.error(err, { data: member });
      }
    });
  }
}
