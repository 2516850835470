/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Member {
    id: string;
    uid?: string;
    order: number;
    foreignKey?: string;
    fullName: string;
    fullNameKana: string;
    displayName?: string;
    summary?: string;
    profile?: string;
    catchphrase?: string;
    lineId?: string;
    groups?: Array<object>;
    tag_masters?: Array<object>;
    member_status_master?: object;
    occupation_master?: object;
    industry_master?: object;
    job_master?: object;
    gender?: Member.GenderEnum;
    birthDay?: Date;
    uncertainAge: boolean;
    married?: boolean;
    hasChildren?: boolean;
    children?: Array<object>;
    height?: number;
    weight?: number;
    email1: string;
    email2?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    mainSiteURL?: string;
    mainSiteName?: string;
    otherSiteURL1?: string;
    otherSiteName1?: string;
    otherSiteURL2?: string;
    otherSiteName2?: string;
    otherSiteURL3?: string;
    otherSiteName3?: string;
    registeredDate?: Date;
    publishStartAt?: Date;
    leaved: boolean;
    leavedAt?: Date;
    projects?: Array<object>;
    entries?: Array<object>;
    member_confidential?: object;
    address?: object;
    bank_account?: object;
    payments?: Array<object>;
    reports?: Array<object>;
    connection_tik_tok?: object;
    connection_twitter?: object;
    connection_youtube?: object;
    image1?: object;
    image2?: object;
    image3?: object;
    image4?: object;
    connection_instagrams?: Array<object>;
    candidates?: Array<object>;
    notifications?: Array<object>;
    lineDisplayName?: string;
}
// tslint:disable-next-line: no-namespace
export namespace Member {
    export type GenderEnum = 'male' | 'female' | 'unanswered' | 'notKnown';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum,
        Unanswered: 'unanswered' as GenderEnum,
        NotKnown: 'notKnown' as GenderEnum
    };
}


