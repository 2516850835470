import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { nth } from 'lodash-es';

import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { Path } from '@lu/path';
import {
  Client,
  Group,
  Entry,
  Project,
  CategoryMaster,
  ProjectKindMaster,
  BrandMaster,
  ProductMaster,
} from '@lu/models';
import {
  EsOrderVirutualScrollStrategy,
} from './es-order-virutual-scroll-strategy';

import { MatchingService } from '@lu/services/matching.service';

@Component({
  selector: 'lu-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  providers: [EsOrderVirutualScrollStrategy, DatePipe]
})
export class OrderListComponent implements OnInit, OnDestroy {
  public orderSearchForm = new FormGroup({
    orderName: new FormControl(null),
    clientId: new FormControl(null),
    segment: new FormControl(null),
    categories: new FormControl([]),
    brand: new FormControl(null),
    nonCompetition: new FormControl(null),
    merchandise: new FormControl([]),
    eventPeriod: new FormGroup({
      startAt: new FormControl(null),
      endAt: new FormControl(null),
    }),
  });
  public groupList: Array<Group>;
  public clientList: Array<Client>;
  public categoryList: Array<CategoryMaster>;
  public segmentList: Array<ProjectKindMaster>;
  public brandList: Array<BrandMaster>;
  public merchandiseList: Array<ProductMaster>;
  public orderList: Array<Project>;
  public orderListPending = false;
  public orderListHasNext = true;
  public csvDownloadBtnClick = false;
  public readonly columnToDisplay = [
    'id',
    'orderName',
    'segment',
    'period',
    'limit',
    'memberNum',
    'clientName',
    'category',
    'toDetail'
  ];
  private statusJPLabelEnum = {
    Registered: '登録済',
    Draft: '下書き',
    Recruiting: '募集中',
    Closed: '募集終了',
    Canceled: '中止',
    Completed: '完了'
  };
  public readonly path = Path;
  private onDestroy$ = new Subject();

  private getMasterPara = {
    parentMasterGroupId_null: false,
    _sort: 'order:ASC'
  };

  constructor(
    private aRoute: ActivatedRoute,
    public dataSource: EsOrderVirutualScrollStrategy,
    private csvService: XLXSCSVService,
    private datePipe: DatePipe,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    this.subscribeCategories();
    this.subscribeSegments();
    this.subscribeBrands();
    this.subscribeMerchandise();
    this.subscribeClient();
    this.aRoute.data.subscribe((data: {
      groups: Array<Group>
    }) => {
      this.groupList = data.groups;
      this.search();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  subscribeCategories() {
    this.apiService.getMaster('category-masters', this.getMasterPara).subscribe(
      categoryItem => {
        if (categoryItem) {
          this.categoryList = categoryItem;
        }
      }, error => {
        console.error(error);
      }
    );
  }

  subscribeSegments() {
    this.apiService.getMaster('project-kind-masters', this.getMasterPara).subscribe(
      pjKindItem => {
        if (pjKindItem) {
          this.segmentList = pjKindItem;
        }
      }, error => {
        console.error('Something was wrong in project-kind-masters(item type) list ====> ', error);
      }
    );
  }

  subscribeBrands() {
    this.apiService.getMaster('brand-masters', this.getMasterPara).subscribe(
      brandItem => {
        if (brandItem) {
          this.brandList = brandItem;
        }
      }, error => {
        console.error('Something was wrong in brand list ====> ', error);
      }
    );
  }

  subscribeMerchandise() {
    this.apiService.getMaster('product-masters', this.getMasterPara).subscribe(
      productItem => {
        if (productItem) {
          this.merchandiseList = productItem;
        }
      }, error => {
        console.error('Something was wrong in category list ====> ', error);
      }
    );
  }

  subscribeClient() {
    this.apiService.getClient('').subscribe(
      clientItem => {
        if (clientItem) {
          this.clientList = clientItem;
        }
      }, error => {
        console.error('Something was wrong in category list ====> ', error);
      }
    );
  }

  getSegment(id: number): string | null {
    const segment = _.find(this.segmentList, ['id', id]);
    return segment ? segment.name : null;
  }

  isAdvertisement(id: string) {
    const segment = _.find(this.segmentList, ['id', id]);
    return segment ? segment.name === '広告案件' : false;
  }

  isEdit(id: string) {
    const segment = _.find(this.segmentList, ['id', id]);
    return segment ? segment.name === '編集案件' : false;
  }

  isEntryDecided(item: Entry | any): boolean {
    return item.status === Entry.StatusEnum.Decided
      || item.status === Entry.StatusEnum.Completed;
  }

  patchValueUnion(ctrl: AbstractControl, value) {
    let list: any[] = ctrl.value;
    if (!Array.isArray(list)) {
      list = [];
    }
    list.push(value);
    ctrl.patchValue(_.union(list));
  }

  spliceValue(ctrl: AbstractControl, value) {
    const list: any[] = ctrl.value;
    const pos = _.findIndex(list, o => _.isEqual(o, value));
    if (pos === -1) {
      return;
    }
    list.splice(pos, 1);
    ctrl.patchValue(list);
  }

  search() {
    let query = {};
    let period = {};
    query = this.getSearchQuery();
    period = this.getPeriodQuery();
    this.dataSource.searchOrders(query, period);
  }

  generateTimeInterval(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dt = date.getDate();
    const dts = dt < 10 ? '0' + dt : dt;
    const months = month < 10 ? '0' + month : month;
    return year + '-' + months + '-' + dts;
  }

  getPeriodQuery() {
    const queryObj = {} as any;
    const eventPeriod = this.orderSearchForm.get('eventPeriod').value;
    if (eventPeriod.startAt !== null && eventPeriod.endAt !== null) {
      // Start Range
      const dayofStart = new Date(eventPeriod.startAt);
      dayofStart.setHours(0, 0, 0, 0);
      queryObj.eventStartAt = dayofStart;

      // End Range
      const dayofEnd = new Date(eventPeriod.endAt);
      dayofEnd.setHours(23, 59, 59, 999);
      queryObj.eventEndAt = dayofEnd;
    } else if (eventPeriod.startAt !== null && eventPeriod.endAt === null) {
      // Start Range
      const dayofStart = new Date(eventPeriod.startAt);
      dayofStart.setHours(0, 0, 0, 0);
      queryObj.eventStartAt = dayofStart;
    } else if (eventPeriod.startAt === null && eventPeriod.endAt !== null) {
      // End Range
      const dayofEnd = new Date(eventPeriod.endAt);
      dayofEnd.setHours(23, 59, 59, 999);
      queryObj.eventEndAt = dayofEnd;
    }
    return queryObj;
  }

  getSearchQuery() {
    const { brand, categories, clientId, merchandise,
      nonCompetition, orderName, segment } = this.orderSearchForm.value;
    const queryObj: any = {
      brand_master: brand,
      category_masters_in: categories,
      client: clientId,
      product_masters_in: merchandise,
      allowDuplicatedEntry: nonCompetition,
      projectName_containss: orderName,
      project_kind_master: segment,
      _sort: 'updated_at:DESC',
      _limit: -1,
      _start: 0
    };
    return queryObj;
  }

  downloadOrderListCsv() {
    // カラムの指定
    this.csvDownloadBtnClick = true;
    this.dataSource.dataStream$.subscribe((data) => {
      if (!this.csvDownloadBtnClick) {
        return;
      }
      const fileName = 'order_list.csv';
      const csvDataList = [];
      data.map((dist: any) => {
        let orderStatus = '';
        switch (dist.status) {
          case Project.StatusEnum.Registered:
            if (dist.publishStatus === Project.PublishStatusEnum.Draft) {
              orderStatus = '[' + this.statusJPLabelEnum.Draft + ']';
              break;
            }
            orderStatus = '[' + this.statusJPLabelEnum.Registered + ']';
            break;
          case Project.StatusEnum.Recruiting:
            orderStatus = '[' + this.statusJPLabelEnum.Recruiting + ']';
            break;
          case Project.StatusEnum.Closed:
            if (dist.closedStatus === Project.ClosedStatusEnum.Canceled) {
              orderStatus = '[' + this.statusJPLabelEnum.Canceled + ']';
              break;
            }
            orderStatus = '[' + this.statusJPLabelEnum.Closed + ']';
            break;
          case Project.StatusEnum.Completed:
            orderStatus = '[' + this.statusJPLabelEnum.Completed + ']';
            break;
        }

        let productionPeriod = '';
        if (dist.eventStartAt !== null) {
          const startDate = this.datePipe.transform(dist.eventStartAt, 'yyyy/MM/dd');
          productionPeriod += `${startDate}〜`;
        }
        if (dist.eventEndAt !== null) {
          const endDate = this.datePipe.transform(dist.eventEndAt, 'yyyy/MM/dd');
          productionPeriod += (dist.eventEndAt ? '' : '〜') + endDate;
        }
        if (!productionPeriod) {
          productionPeriod = 'ー';
        }

        let entriesCount = 0;
        _.map(dist.entries, (entries) => {
          const count = this.isEntryDecided(entries) ? 1 : 0;
          entriesCount += count;
        });

        let categories = '';
        _.map(dist.category_masters, (cat: any) => {
          const categoryIndex = this.categoryList.findIndex(o => o.id === cat.id);
          if (categoryIndex < 0) {
            return;
          }
          categories += categories !== '' ? ',' : '';
          categories += this.categoryList[categoryIndex].name;
        });
        csvDataList.push({
          ID: dist.id,
          案件名: orderStatus + ' ' + dist.projectName,
          案件種別: dist.project_kind_master !== null ? this.getSegment(dist.project_kind_master.id) : '',
          本番期間: productionPeriod,
          エントリー締切: this.datePipe.transform(dist.recruitmentEndAt, 'yyyy/MM/dd HH:mm'),
          決定者: entriesCount + '人',
          クライアント名: dist.client === null ? '' : dist.client.clientName,
          案件のカテゴリ: categories
        });
      });
      this.csvService.downloadCSV(csvDataList, fileName);
      this.csvDownloadBtnClick = false;
    });
  }

  clientChanges(clients: any[]) {
    const client = nth(clients, 0);
    const ctrl = this.orderSearchForm.get('clientId');
    if (!client) {
      ctrl.reset();
    } else {
      // const { _id } = client;
      ctrl.patchValue(client.id);
    }
  }
}
