import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, Observable } from 'rxjs';
import * as moment from 'moment';
import { get, map as _map } from 'lodash-es';

import {
  Group,
  Member,
  Payment,
  Project,
} from '@lu/models';

import { MatchingService } from '@lu/services/matching.service';
import { MatTableDataSource } from '@angular/material';
import * as _ from 'lodash';

export interface QueryObjPayment {
  eventEndAt_gte: string;
  eventEndAt_lte: string;
}

@Component({
  selector: 'lu-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss']
})

export class PaymentDetailComponent implements OnInit, OnDestroy {
  public searchForm = new FormGroup({
    month: new FormControl(null)
  });
  public member: Member;
  public role: string;
  public monthList: string[] = [moment().startOf('M').toISOString(true)];
  public groupList: Group[];
  public orderCache: Record<string, Observable<Partial<Project>>> = {};
  public paymentList: (Payment & { order: Partial<Project> })[];
  public monthListData = [];
  public filterData = [];
  public columnToDisplay = [
    'order.orderNumber',
    'order.orderName',
    'amount',
    'order.eventPeriod',
    'order.client.clientName'
  ];
  public search$ = new Subject();
  public onDestroy$ = new Subject();
  public paymentWithProject = new MatTableDataSource<Element>();
  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    this.aRoute.data.subscribe(data => {
      this.member = data.member;
      this.role = data.servicePermissions[0].role;
      this.groupList = data.servicePermissions[0].groups;
    });
    // Assign default term
    const { month } = this.aRoute.snapshot.queryParams;
    const term = new Date(month);
    this.searchForm.patchValue({
      month: isNaN(term.valueOf()) ?
        this.monthList[0] :
        moment(term).startOf('M').toISOString(true)
    });
    this.search();
    this.generateTerm();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  lastday(year, month) {
    return new Date(year, month, 0).getDate();
  }

  getSearchQuery() {
    const formValue = this.searchForm.value;
    const queryObj = {} as QueryObjPayment;
    if (formValue.month) {
      const date = new Date((formValue.month));
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const months = month < 10 ? '0' + month : month;
      const lastday = this.lastday(year, month);
      queryObj.eventEndAt_gte = year + '-' + months + '-01T00:00:00.000Z';
      queryObj.eventEndAt_lte = year + '-' + months + '-' + lastday + 'T23:59:59.000Z';
    } else {
      return {};
    }
    return queryObj;
  }

  async search() {
    const query = this.getSearchQuery() as QueryObjPayment;
    this.getSearchQuery();
    const termString = moment(this.searchForm.value.month).format('YYYY-MM');
    this.paymentList = void 0;

    this.router.navigate([], {
      queryParams: { month: termString },
      replaceUrl: true,
    });

    const paramPayment = {
      // tslint:disable-next-line: no-string-literal
      member: this.member['id'],
      'project.eventEndAt_gte': query.eventEndAt_gte,
      'project.eventEndAt_lte': query.eventEndAt_lte,
    };
    if (this.role === 'groupEditor' || this.role === 'groupAdmin') {
      paramPayment['project.administrativeGroups'] = this.groupList.map(group => group.id);
    }
    const getPaymentList = () => {
      return new Promise<any[]>(payResolve => {
        this.apiService.getPayment(paramPayment)
          .subscribe(pay => {
            payResolve(pay);
          });
      });
    };

    const paymentList = await getPaymentList();
    console.log(paymentList);
    const projectId = paymentList.map((data: any) => {
      return data.project.id;
    });

    const paramProject = {
      // tslint:disable-next-line: no-string-literal
      id: projectId,
      // 'candidates.id': _.map(this.member.candidates, 'id'),
      eventEndAt_gte: query.eventEndAt_gte,
      eventEndAt_lte: query.eventEndAt_lte
    };
    this.apiService.getProject(paramProject).subscribe(
      (result: any) => {
        result.forEach((element: any) => {
          this.paymentList = [];
          paymentList.map(data => {
            if (!data.project) {
              return null;
            }
            if (element.id === data.project.id) {
              element.currency = data.currency;
              element.amount = data.amount;
            }
          });
        });
        const orderList = result;
        this.paymentList = [];
        let allTotal = 0;
        for (const project of orderList) {
          const paymentAggregation = {
            ...project,
            total: 0
          };
          let amountSum = 0;
          for (const pay of project.payments) {
            if (pay.amount && pay.member === this.member.id) {
              amountSum += Number(pay.amount);
            }
          }
          allTotal += amountSum;
          paymentAggregation.total = amountSum;
          this.paymentList.push(paymentAggregation);
        }
      }, error => {
        console.log('error', error);
      });
  }


  generateTerm() {
    this.monthListData = [];
    // tslint:disable-next-line: no-string-literal
    const moments = this.member['projects'].map((t: any) => moment(t.eventEndAt).startOf('M'));
    const current = moment(this.searchForm.value.month);
    moments.push(current);
    moments.sort((a, b) => a.valueOf() - b.valueOf());
    const dateStrings = moments.map(m => m.toISOString(true));
    this.monthListData = [...new Set(dateStrings)];
    this.monthList = this.monthListData;
  }

  getOrder(id: string): Observable<Partial<Project>> {
    const cache$ = this.orderCache[id];
    if (cache$) {
      return cache$;
    }
  }

  getColumnHeader(column: string) {
    switch (column) {
      case 'order.orderNumber': return 'ID';
      case 'order.orderName': return '案件名';
      case 'amount': return '金額';
      case 'order.eventPeriod': return '案件期間';
      case 'order.client.clientName': return 'クライアント名';
      default:
    }
  }

  generateCellWidth(column: string, payment: any) {
    switch (column) {
      case 'order.orderNumber':
        return get(payment, column) ? String(payment[column]).length : 3;
      case 'amount':
        return String(payment.amount).length + 1;
      default:
        return null;
    }
  }

  isCurrencyColumn(column: string) {
    return typeof column === 'string' && column.match(/^(amount|withholdings|payment)$/);
  }
}

