import { Component, OnInit, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface LodingDialogData {
  text?: string;
  html?: string;
  color?: ThemePalette;
  mode?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value?: number;
  bufferValue?: number;
  hiddenBar?: boolean;
}

@Component({
  selector: 'lu-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {

  constructor(
    public ref: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LodingDialogData,
  ) {}

  ngOnInit() {}
}
