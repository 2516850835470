import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MatSharedModule } from './mat-shared.module';
import { ComponentSharedModule } from './components/component-shared.module';
import { ReactiveFormsModule } from '@angular/forms';
// Path definitions
import { Path } from './path';
// guards
import { AuthGuard } from '@lu/guards/auth.guard';
import { EmailVerificationGuard } from '@lu/guards/email-verification.guard';
import { ServiceAdminGuard } from '@lu/guards/service-admin.guard';
// Resolvers
import { ServicePermissionsResolver } from '@lu/resolvers/service-permissions';
import { PermittedGroupsResolver } from '@lu/resolvers/permitted-groups';
import { GroupsResolver } from '@lu/resolvers/groups';
import { OrderResolver } from '@lu/resolvers/order';
import { RewardsResolver } from '@lu/resolvers/rewards';
import { OrderCandidatesResolver } from '@lu/resolvers/order-candidates';
import { OrderPublicConfidentialsResolver } from '@lu/resolvers/order-public-confidentials';
import { OrderSecretConfidentialsResolver } from '@lu/resolvers/order-secret-confidentials';
import { AdminResolver } from '@lu/resolvers/admin';
import { MemberResolver } from '@lu/resolvers/member';
import { MemberConfidentialsResolver } from '@lu/resolvers/member-confidentials';
import { MemberConnectionsResolver } from '@lu/resolvers/member-connections';
import { ClientResolver } from '@lu/resolvers/client';
import { MailRequestResolver } from '@lu/resolvers/mail-request';
import { MailRequestContentsResolver } from '@lu/resolvers/mail-request-contents';
import { MailRequestRequestsResolver } from '@lu/resolvers/mail-request-requests';
import { MailRequestResultsResolver } from '@lu/resolvers/mail-request-results';
import { UserSearchResolver } from '../app/resolvers/user-search';
// Pipes Module
import { PipesModule } from '@lu/pipes/pipes.module';
// Components
import { NotFoundComponent } from '@lu/pages/not-found/not-found.component';
import { LoginComponent } from '@lu/pages/login/login.component';
import { AdminUserListComponent } from '@lu/pages/admin-user-list/admin-user-list.component';
import { AdminUserDetailComponent } from '@lu/pages/admin-user-detail/admin-user-detail.component';
import { OrderListComponent } from '@lu/pages/order-list/order-list.component';
import { OrderDetailComponent } from '@lu/pages/order-detail/order-detail.component';
import { MailListComponent } from '@lu/pages/mail-list/mail-list.component';
import { MailDetailComponent } from '@lu/pages/mail-detail/mail-detail.component';
import { ClientListComponent } from '@lu/pages/client-list/client-list.component';
import { ClientDetailComponent } from '@lu/pages/client-detail/client-detail.component';
import { UserListComponent } from '@lu/pages/user-list/user-list.component';
import { UserDetailComponent } from '@lu/pages/user-detail/user-detail.component';
import { UserSortComponent } from '@lu/pages/user-sort/user-sort.component';
import { MasterEditComponent } from '@lu/pages/master-edit/master-edit.component';
import { PaymentListComponent } from '@lu/pages/payment-list/payment-list.component';
import { PaymentDetailComponent } from '@lu/pages/payment-detail/payment-detail.component';
import { ServiceSettingsComponent } from '@lu/pages/service-settings/service-settings.component';
import { HistoryListComponent } from '@lu/pages/history-list/history-list.component';
import { DirectiveModule } from './directives/directive.module';

const routes: Routes = [
  {
    path: Path.admin.list,
    component: AdminUserListComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
    },
  },
  {
    path: Path.admin.create,
    component: AdminUserDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
    },
  },
  {
    path: Path.admin.detail,
    component: AdminUserDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
      admin: AdminResolver
    },
  },
  {
    path: Path.user.list,
    component: UserListComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
      usersearch: UserSearchResolver,
    },
  },
  {
    path: Path.user.create,
    component: UserDetailComponent,
    resolve: {
      groups: GroupsResolver,
    },
  },
  {
    path: Path.user.detail,
    component: UserDetailComponent,
    resolve: {
      groups: GroupsResolver,
      member: MemberResolver,
      confidentials: MemberConfidentialsResolver,
    },
  },
  {
    path: Path.user.sort,
    component: UserSortComponent,
    resolve: {
      groups: GroupsResolver,
    },
  },
  {
    path: Path.client.list,
    component: ClientListComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
    },
  },
  {
    path: Path.client.detail,
    component: ClientDetailComponent,
    resolve: {
      client: ClientResolver,
    }
  },
  {
    path: Path.order.list,
    component: OrderListComponent,
    resolve: {
      groups: GroupsResolver,
    },
  },
  {
    path: Path.order.create,
    component: OrderDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
    },
  },
  {
    path: Path.order.detail,
    component: OrderDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
      order: OrderResolver,
    },
  },
  {
    path: Path.mail.list,
    component: MailListComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
    },
  },
  {
    path: Path.mail.create,
    component: MailDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
    },
  },
  {
    path: Path.mail.edit,
    component: MailDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
      mailRequest: MailRequestResolver,
    },
  },
  {
    path: Path.mail.detail,
    component: MailDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
      mailRequest: MailRequestResolver,
    },
  },  {
    path: Path.payment.list,
    component: PaymentListComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      groups: GroupsResolver,
    }
  },
  {
    path: Path.payment.detail,
    component: PaymentDetailComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver,
      member: MemberResolver,
    }
  },
  {
    path: Path.master.edit,
    component: MasterEditComponent,
    resolve: {
      servicePermissions: ServicePermissionsResolver
    },
  },
  {
    path: Path.serviceSettings.root,
    component: ServiceSettingsComponent,
    canActivate: [ServiceAdminGuard],
  },
  {
    path: Path.history.list,
    component: HistoryListComponent,
    canActivate: [ServiceAdminGuard],
  },
  {
    path: '',
    redirectTo: Path.admin.list,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatSharedModule,
    ComponentSharedModule,
    ReactiveFormsModule,
    PipesModule,
    DirectiveModule,
  ],
  exports: [RouterModule],
  declarations: [
    NotFoundComponent,
    AdminUserListComponent,
    AdminUserDetailComponent,
    OrderListComponent,
    OrderDetailComponent,
    MailListComponent,
    MailDetailComponent,
    ClientListComponent,
    ClientDetailComponent,
    UserListComponent,
    UserDetailComponent,
    UserSortComponent,
    MasterEditComponent,
    PaymentListComponent,
    PaymentDetailComponent,
    ServiceSettingsComponent,
    HistoryListComponent
  ],
  providers: [
    ServicePermissionsResolver,
    PermittedGroupsResolver,
    GroupsResolver,
    OrderResolver,
    RewardsResolver,
    OrderCandidatesResolver,
    OrderPublicConfidentialsResolver,
    OrderSecretConfidentialsResolver,
    AdminResolver,
    MemberResolver,
    MemberConfidentialsResolver,
    MemberConnectionsResolver,
    ClientResolver,
    MailRequestResolver,
    MailRequestContentsResolver,
    MailRequestRequestsResolver,
    MailRequestResultsResolver,
    UserSearchResolver
  ]
})
export class AppChildRoutingModule { }

const RootRouting: Routes = [
  {
    path: Path.auth.login,
    component: LoginComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, EmailVerificationGuard],
    loadChildren: () => import('./app-routing.module').then(m => m.AppChildRoutingModule),
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(RootRouting),
    CommonModule,
    MatSharedModule,
    ComponentSharedModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [RouterModule],
  declarations: [
    LoginComponent,
  ]
})
export class AppRoutingModule { }
