import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Project } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class OrderResolver implements Resolve<Project> {
  constructor(
    private apiService: MatchingService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const { orderId } = route.params;
    return this.apiService.getOneProject(Number(orderId));
  }
}
