import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup, AbstractControl, FormArray } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatchingService } from '@lu/services/matching.service';
import {
  Entry,
  Member,
  Report,
} from '@lu/models';

export interface OrderReportDialogData {
  entryMember: EntryMember[];
}
type EntryMember = { id: number, report: Report } &
  Entry &
  Pick<Member, 'id' | 'fullName' | 'displayName'>;

@Component({
  selector: 'lu-order-report-dialog',
  templateUrl: './order-report-dialog.component.html',
  styleUrls: ['./order-report-dialog.component.scss']
})
export class OrderReportDialogComponent implements OnInit, OnDestroy {
  public report: Report;
  public reportStatusForm = new FormGroup({
    status: new FormControl(null, Validators.required),
    message: new FormControl(null)
  });
  public reportForm = new FormGroup({
    id: new FormControl(null),
    message: new FormControl('', Validators.required),
    read: new FormControl(null),
    rejected: new FormControl(null),
    rejectedMessage: new FormControl(null),
    rejectedAt: new FormControl(null),
    rejectedBy: new FormControl(null),
    approved: new FormControl(null),
    approvedAt: new FormControl(null),
    approvedBy: new FormControl(null),
    member: new FormControl(null),
    project: new FormControl(null),
    reward: new FormControl(null),
    created_at: new FormControl(null),
    updated_at: new FormControl(null),
    file1: new FormControl(null),
    file2: new FormControl(null),
    file3: new FormControl(null)
  } as { [K in keyof Report]: AbstractControl });
  public imageForm = new FormControl([]);
  public statusList = [
    {
      label: 'エントリー中',
      value: Entry.StatusEnum.Entried,
    },
    {
      label: '内定',
      value: Entry.StatusEnum.Offered,
    },
    {
      label: '仮決定',
      value: Entry.StatusEnum.Approved,
    },
    {
      label: '本決定',
      value: Entry.StatusEnum.Decided,
    },
    {
      label: '完了',
      value: Entry.StatusEnum.Completed,
    },
    {
      label: 'キャンセル',
      value: Entry.StatusEnum.Canceled,
    },
  ];
  public reportStatusList = [
    {
      label: '受領',
      value: true,
    },
    {
      label: '再提出',
      value: false,
    },
  ];
  public downloadProcessURL: { [key: string]: boolean } = {};
  public readonly columnToDisplay = ['id', 'name', 'entrySchedule', 'status'];
  private onDestroy$ = new Subject();
  private admin: number;


  constructor(
    public dialogRef: MatDialogRef<OrderReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderReportDialogData,
    private afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    private http: HttpClient,
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    this.getCurrentUser();
    this.subscribeReport();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  get isEmptyReportMessage() {
    return _.isEmpty(this.reportStatusForm.get('message').value);
  }

  async getCurrentUser() {
    const { uid } = this.afAuth.auth.currentUser;
    const admin = () => {
      return new Promise<any>(resolver => {
        this.apiService.getAdminUser({ uid })
          // tslint:disable-next-line: deprecation
          .subscribe(doc => {
            resolver(doc[0].id);
          }, err => console.error(err));
      });
    };
    this.admin = await admin();
  }

  subscribeReport() {
    const reportId = this.data.entryMember[0].report.id;
    this.apiService.getReportWithPara(`?id=${reportId}`).pipe(
      takeUntil(this.onDestroy$),
    // tslint:disable-next-line: deprecation
    ).subscribe(snap => {
      this.report = snap[0];
      this.reportStatusForm.patchValue({
        status: this.report.approved,
        message: this.report.rejectedMessage,
      });
      this.setDocumentToForm();
    });
  }

  setDocumentToForm() {
    this.reportForm.patchValue(this.report);
    const files = [];
    if (this.report.file1) {
      files.push(this.report.file1);
    }
    if (this.report.file2) {
      files.push(this.report.file2);
    }
    if (this.report.file3) {
      files.push(this.report.file3);
    }
    this.imageForm.patchValue(files);
  }

  async downloadAttemptFile(file: any) {
    if (this.downloadProcessURL[file.url]) {
      return;
    }
    this.downloadProcessURL[file.url] = true;
    const blob = await (await fetch(file.url)).blob()
      .then(blobData => blobData)
      .then(data => {
        return data;
      });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloadProcessURL[file.url] = false;
  }

  emitReportStatus() {
    const by = this.admin;
    const at = new Date().toISOString() as any;
    const { status, message } = this.reportStatusForm.value;
    const approved = status;

    this.reportStatusForm.patchValue({ by });
    this.reportForm.patchValue({ read: true, updated_at: at, updated_by: by });

    if (approved) {
      this.reportForm.patchValue({
        approved,
        approvedAt: at,
        approvedBy: by,
      });
    } else {
      this.reportForm.patchValue({
        rejected: !approved,
        rejectedAt: at,
        rejectedBy: by,
        rejectedMessage : message
      });
    }
    this.dialogRef.close(this.reportForm.value);
  }
}
