/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewAdminUser {
    uid?: string;
    order: number;
    groups?: Array<string>;
    email: string;
    displayName?: string;
    summary?: string;
    role: NewAdminUser.RoleEnum;
    leaved?: boolean;
    createdBy?: string;
    updatedBy?: string;
}
// tslint:disable-next-line: no-namespace
export namespace NewAdminUser {
    export type RoleEnum = 'serviceAdmin' | 'groupAdmin' | 'groupEditor';
    export const RoleEnum = {
        ServiceAdmin: 'serviceAdmin' as RoleEnum,
        GroupAdmin: 'groupAdmin' as RoleEnum,
        GroupEditor: 'groupEditor' as RoleEnum
    };
}


