import { Injectable } from '@angular/core';
import {
  AdminUser,
  BankAccount,
  Candidate,
  Client,
  Entry,
  Group,
  MailActivity,
  Member,
  MemberConfidential,
  Message,
  Payment,
  Project,
  ProjectConfidential,
  ProjectHistory,
  ProjectKindMaster,
  Report,
  Reward
} from '@lu/models';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MatchingService extends ApiService {
  login(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/auth/local';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  getLoginUser(): Observable<any> {
    const queryUri = this.apiEndpoint + '/users/me';
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  /*
  for master edit setting page and group
  */
  getGroups(data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/groups';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getMaster(kind: string, data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + kind;
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getMasterGroup(masterKind: string, para: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + masterKind;
    const body = para;
    return this.apiConnecter('GET', queryUri, body);
  }

  updateMasterGroup(id: string, data: {}, type: string): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type + '/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  deleteMasterGroup(id: string, type: string): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type + '/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  createMasterGroup(type: string, data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type;
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  updateGroup(id: any, data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/groups/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  deleteGroupMember(gpid: any, mmid: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/groups/' + gpid + '/members/' + mmid;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  /**
   * client
   */

  getClient(data: {}): Observable<Array<Client>> {
    const queryUri = this.apiEndpoint + '/clients';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getEachClient(id: number, data: {}): Observable<Client> {
    const queryUri = this.apiEndpoint + '/clients/' + id;
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  addClient(data: {}): Observable<Client> {
    const queryUri = this.apiEndpoint + '/clients';
    return this.apiConnecter('POST', queryUri, data);
  }

  updateClient(id: string, data: {}): Observable<Client> {
    const queryUri = this.apiEndpoint + '/clients/' + id;
    return this.apiConnecter('PUT', queryUri, data);
  }

  /**
   * admin
   */

  getAdminUser(data: {}): Observable<Array<AdminUser>> {
    const queryUri = this.apiEndpoint + '/_admin-users';
    return this.apiConnecter('GET', queryUri, data);
  }

  createAdminUser(data: any): Observable<AdminUser> {
    const queryUri = this.apiEndpoint + '/_admin-users';
    const body = data;
    return this.apiConnecter('POST', queryUri, data);
  }

  updateAdminUser(id: string, data: any): Observable<AdminUser> {
    const queryUri = this.apiEndpoint + '/_admin-users/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  getAdminUserWithId(id: number): Observable<AdminUser> {
    const queryUri = this.apiEndpoint + '/_admin-users/' + id;
    const data = {};
    return this.apiConnecter('GET', queryUri, data);
  }

  deleteAdminUser(id: number): Observable<AdminUser> {
    const queryUri = this.apiEndpoint + '/_admin-users/' + id;
    const data = {};
    return this.apiConnecter('DELETE', queryUri, data);
  }

  /**
   * Member
   */
  getMember(data: {}): Observable<Array<Member>> {
    const queryUri = this.apiEndpoint + '/' + 'members';
    return this.apiConnecter('GET', queryUri, data);
  }

  getMemberCount(data: {}): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + 'members' + '/' + 'count';
    return this.apiConnecter('GET', queryUri, data);
  }

  getEachMember(id: string, data: {}): Observable<Member> {
    const queryUri = this.apiEndpoint + '/' + 'members' + '/' + id;
    return this.apiConnecter('GET', queryUri, data);
  }

  createMember(data: any, sendMail: string): Observable<Member> {
    const queryUri = this.apiEndpoint + '/' + 'members?sendmail=' + sendMail;
    return this.apiConnecter('POST', queryUri, data);
  }

  createMemberFromCSV(data: any, sendMail: string): Observable<Array<Member>> {
    const queryUri = this.apiEndpoint + '/' + 'members/csv?sendmail=' + sendMail;
    return this.apiConnecter('POST', queryUri, data);
  }

  deleteMember(id: number): Observable<Member> {
    const queryUri = this.apiEndpoint + '/members/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  updateMember(id: string, data: {}): Observable<Member> {
    const queryUri = this.apiEndpoint + '/' + 'members' + '/' + id + '?notification=false';
    return this.apiConnecter('PUT', queryUri, data);
  }

  updateMemberOrder(id: string, data: {}): Observable<Member> {
    const queryUri = this.apiEndpoint + '/members/updateorder/' + id;
    return this.apiConnecter('PUT', queryUri, data);
  }

  /**
   * Project history
   */
  getProjectHistory(data): Observable<ProjectHistory[]> {
    const queryUri = this.apiEndpoint + '/project-histories';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  createHistory(data: any): Observable<ProjectHistory> {
    const queryUri = this.apiEndpoint + '/project-histories';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  updateHistory(id: number, data: any): Observable<ProjectHistory> {
    const queryUri = this.apiEndpoint + '/project-histories/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /*
  for project(order)
  */
  getProject(data: any): Observable<Project[]> {
    const queryUri = this.apiEndpoint + '/projects';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getOneProject(id: number): Observable<Project> {
    const queryUri = this.apiEndpoint + '/projects/' + id;
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  createProject(data: any): Observable<Project> {
    const queryUri = this.apiEndpoint + '/projects';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  updateProject(id, data): Observable<Project> {
    const queryUri = this.apiEndpoint + '/projects/' + id;
    return this.apiConnecter('PUT', queryUri, data);
  }

  getProjectKindMaster(data): Observable<ProjectKindMaster> {
    const queryUri = this.apiEndpoint + '/project-kind-masters';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getPjConfidential(data: any): Observable<ProjectConfidential> {
    const queryUri = this.apiEndpoint + '/project-confidentials';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  createPjCredential(data: any): Observable<ProjectConfidential> {
    const queryUri = this.apiEndpoint + '/project-confidentials';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  updatePjCredential(id: number, data: any): Observable<ProjectConfidential> {
    const queryUri = this.apiEndpoint + '/project-confidentials' + '/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /**
   * reward
   */
  getReward(data: any): Observable<Reward[]> {
    const queryUri = this.apiEndpoint + '/rewards';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  createReward(data: any): Observable<Reward> {
    const queryUri = this.apiEndpoint + '/rewards';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  updateReward(id: number, data: any): Observable<Reward> {
    const queryUri = this.apiEndpoint + '/rewards' + '/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /**
   * Payment
   */
  getPayment(data): Observable<Payment[]> {
    const queryUri = this.apiEndpoint + '/payments';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  /**
   * Entry
   */
  getEntries(data: any): Observable<Entry[]> {
    const queryUri = this.apiEndpoint + '/entries';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  updateEntry(id, data): Observable<Entry> {
    const queryUri = this.apiEndpoint + '/entries/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  sendCancelMessage(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/entries/sendcancelmessage';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  /*
  for reports
  */
  getReport(data: any): Observable<Report[]> {
    const queryUri = this.apiEndpoint + '/reports';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getReportWithPara(data): Observable<Report> {
    const queryUri = this.apiEndpoint + '/reports' + data;
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  updateReport(id, data): Observable<Report> {
    const queryUri = this.apiEndpoint + '/reports/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  /**
   * for file
   */
  removeFile(id: number): Observable<any> {
    const queryUri = this.apiEndpoint + '/upload/files/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  /**
   * candidate
   */
  getEachCandidate(id: number): Observable<Candidate> {
    const queryUri = this.apiEndpoint + '/candidates/' + id;
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  /**
   * messages
   */
  getMessage(data): Observable<Message[]> {
    const queryUri = this.apiEndpoint + '/messages';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  getEachMessage(id: number): Observable<Message> {
    const queryUri = this.apiEndpoint + '/messages/' + id;
    const body = {};
    return this.apiConnecter('GET', queryUri, body);
  }

  updateMessage(id: string, data: any): Observable<Message> {
    const queryUri = this.apiEndpoint + '/messages/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  createMessage(data: any): Observable<Message> {
    const queryUri = this.apiEndpoint + '/messages';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  createMailActivity(data: any): Observable<MailActivity> {
    const queryUri = this.apiEndpoint + '/mail-activities';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  deleteMailActivity(id: number): Observable<MailActivity> {
    const queryUri = this.apiEndpoint + '/mail-activities/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  /**
   * Member confidential
   */
  getMemberConfidential(data: any): Observable<MemberConfidential> {
    const queryUri = this.apiEndpoint + '/member-confidentials';
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  /**
   * upload
   */
  uploadFile(data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/upload';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  /**
   * connection
   */
   getConnection(type: string, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type ;
    const body = data;
    return this.apiConnecter('GET', queryUri, body);
  }

  updateConnection(type: string, id: number, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type + '/' + id;
    const body = data;
    return this.apiConnecter('PUT', queryUri, body);
  }

  createConnection(type: string, data: any): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type;
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  deleteConnection(type: string, id: number): Observable<any> {
    const queryUri = this.apiEndpoint + '/' + type + '/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }

  /**
   * Bank Account
   */
  createBankAccount(data: any): Observable<BankAccount> {
    const queryUri = this.apiEndpoint + '/bank-accounts';
    const body = data;
    return this.apiConnecter('POST', queryUri, body);
  }

  deleteBankAccount(id: number): Observable<BankAccount> {
    const queryUri = this.apiEndpoint + '/bank-accounts/' + id;
    const body = {};
    return this.apiConnecter('DELETE', queryUri, body);
  }
}
