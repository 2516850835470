import { Injectable } from '@angular/core';
import {
  Router,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { Path } from '@lu/path';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth
  ) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.afAuth.authState.pipe(
      take(1),
      map(user => {
        // console.log(`state:`, state);
        // console.log(`next:`, next);
        // console.log(`emailVerified: ${user.emailVerified}`);
        if (user.emailVerified || state.url.startsWith('/setting')) {
          return true;
        } else {
          const reqeust = confirm('メールアドレスが認証されていません。登録アドレスに認証メールを送信しますか？');
          if (reqeust) {
            user.sendEmailVerification();
          }
          return this.router.createUrlTree(Path.auth.login.split('/'), {
            queryParams: {
              emailVerified: 0,
              redirectFrom: state.url
            }
          });
        }
      })
    );
  }
}
