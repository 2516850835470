import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

export interface ConfirmationInputDialogData {
  html?: string;
  text?: string[];
  needInput?: boolean;
  needInputText?: string;
  apply?: boolean;
  cancel?: boolean;
  applyText?: string;
  cancelText?: string;
  applyButtonColor?: ThemePalette;
  cancelButtonColor?: ThemePalette;
}

@Component({
  selector: 'lu-confirmation-dialog',
  templateUrl: './confirmation-input-dialog.component.html',
  styleUrls: ['./confirmation-input-dialog.component.scss']
})
export class ConfirmationInputDialogComponent implements OnInit {

  public inputForm = new FormControl(null);

  constructor(
    public dialogRef: MatDialogRef<ConfirmationInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationInputDialogData
  ) {
    if (typeof this.data !== 'object') {
      this.data = {};
    }
    if (typeof this.data.apply !== 'boolean') {
      this.data.apply = true;
    }
    if (typeof this.data.cancel !== 'boolean') {
      this.data.cancel = true;
    }
  }

  ngOnInit() {
    this.initInputForm();
  }

  initInputForm() {
    if (this.data.needInput && this.data.needInputText) {
      const regExp = new RegExp('^' + this.addEscape(this.data.needInputText) + '$');
      this.inputForm.setValidators([
        Validators.required,
        Validators.pattern(regExp),
      ]);
    }
  }

  addEscape(text: string): string {
    const needEscapeRegExp = new RegExp(/^[\.\*\+\^\|\[\]\(\)\?\$\{\}\\\-\/]$/);
    let result = '';
    for (let i = 0; i < text.length; i++) {
      needEscapeRegExp.test(text.charAt(i)) ?
        result += '\\' + text.charAt(i) :
        result += text.charAt(i);
    }
    return result;
  }

}
