import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthService } from '@lu/services/auth.service';
import { Path } from '@lu/path';

@Component({
  selector: 'lu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public userName: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      if (!user) {
        this.userName = null;
      } else {
        this.userName = user.displayName ? user.displayName : user.email;
      }
    });
  }

  async logout() {
    await this.authService.logout();
    this.router.navigate([Path.auth.login]);
  }

}
