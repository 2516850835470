// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG } from '@angular/fire/analytics';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { MatSharedModule } from './mat-shared.module';
import { ComponentSharedModule } from '@lu/components/component-shared.module';
import { IntercepterSharedModule } from '@lu/interceptors/intercepter-shared.module';
// Main component
import { AppComponent } from './app.component';
// environment
import { environment } from '@lu/environment';
// Custom paginator
import { MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './custom-paginator-configuration';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    MatSharedModule,
    ComponentSharedModule,
    IntercepterSharedModule,
  ],
  providers: [
    UserTrackingService,
    ScreenTrackingService,
    { provide: CONFIG, useValue: {
      send_page_view: false,
      allow_ad_personalization_signals: false,
      anonymize_ip: true
    } },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
