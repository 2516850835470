import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSidenavContent } from '@angular/material';

import { assign, pick, isEqual } from 'lodash-es';
import { filter, take } from 'rxjs/operators';

import { environment } from '@lu/environment';
import { DialogService } from './services/dialog.service';
import { version } from '../../package.json';

@Component({
  selector: 'lu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('sidenavContent', {
    read: MatSidenavContent,
    static: true
  }) sidenavContent: MatSidenavContent;
  public isAuthPage = false;
  public envChip = environment.envChip;
  public version: string = version;

  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private dialog: DialogService,
  ) { }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.sidenavContent.scrollTo({ top: 0 });
        if (event.urlAfterRedirects.match(/^\/auth/)) {
          this.isAuthPage = true;
        } else {
          this.isAuthPage = false;
        }
      });
    // Development only!
    // Be careful treating this code because become hole of security.
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => this.overwriteEnvValues());
  }

  overwriteEnvValues() {
    // Overwrite environment values only when develop and emulate in local.
    if (!environment.firebaseConfig.projectId.match(/^dott-matching-(dev|ci)$/)) {
      return;
    }
    const values = pick(this.aRoute.snapshot.queryParams, Object.keys(environment));
    if (!Object.keys(values).length || isEqual(environment, values)) {
      return;
    }
    assign(environment, values);
    this.dialog.openLoadingDialog({
      autoFocus: false,
      data: {
        text: '環境設定値を上書きしました（開発環境のみ有効）',
        hiddenBar: true,
      }
    });
  }
}
