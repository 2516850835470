import { Pipe, PipeTransform } from '@angular/core';
import { firestore } from 'firebase/app';

@Pipe({
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {
  transform(value: string | number | Date | firestore.Timestamp): Date {
    return value instanceof firestore.Timestamp ? value.toDate() : new Date(value);
  }
}
