import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSharedModule } from 'src/app/mat-shared.module';
import { DirectiveModule } from '@lu/directives/directive.module';
import { PipesModule } from '@lu/pipes/pipes.module';

import { DatetimePickerModule } from './datetime-picker/datetime-picker.module';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { HeaderComponent } from './header/header.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { FileChooserComponent } from './file-chooser/file-chooser.component';
import { UserSearchDialogComponent } from './user-search-dialog/user-search-dialog.component';
import { UserCardComponent } from './user-card/user-card.component';
import { OrderEntryUsersComponent } from './order-entry-users/order-entry-users.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { OrderStatusChipComponent } from './order-status-chip/order-status-chip.component';
import { UserBatchRegistrationComponent } from './user-batch-registration/user-batch-registration.component';
import { ConfirmationInputDialogComponent } from './confirmation-input-dialog/confirmation-input-dialog.component';
import { MemberInviteDialogComponent } from './member-invite-dialog/member-invite-dialog.component';
import { OrderReportDialogComponent } from './order-report-dialog/order-report-dialog.component';
import { ChipsAutocompleteComponent } from './chips-autocomplete/chips-autocomplete.component';
import { AsyncAutocompleteChipsComponent } from './async-autocomplete-chips/async-autocomplete-chips.component';
import { MasterListItemComponent } from './master-list-item/master-list-item.component';
import { ClientSelectBoxComponent } from './client-select-box/client-select-box.component';
import { CsvdlItemsSelectableComponent } from './csvdl-items-selectable/csvdl-items-selectable.component';
import { EntryCancelDialogComponent } from './entry-cancel-dialog/entry-cancel-dialog.component';

@NgModule({
  declarations: [
    SideNavigationComponent,
    HeaderComponent,
    UserSearchComponent,
    FileChooserComponent,
    UserSearchDialogComponent,
    UserCardComponent,
    OrderEntryUsersComponent,
    ConfirmationDialogComponent,
    LoadingDialogComponent,
    OrderStatusChipComponent,
    UserBatchRegistrationComponent,
    ConfirmationInputDialogComponent,
    MemberInviteDialogComponent,
    OrderReportDialogComponent,
    ChipsAutocompleteComponent,
    AsyncAutocompleteChipsComponent,
    MasterListItemComponent,
    ClientSelectBoxComponent,
    CsvdlItemsSelectableComponent,
    EntryCancelDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DatetimePickerModule,
    MatSharedModule,
    ReactiveFormsModule,
    DirectiveModule,
    PipesModule,
  ],
  exports: [
    DatetimePickerModule,
    SideNavigationComponent,
    HeaderComponent,
    UserSearchComponent,
    FileChooserComponent,
    UserSearchDialogComponent,
    UserCardComponent,
    OrderEntryUsersComponent,
    ConfirmationDialogComponent,
    LoadingDialogComponent,
    OrderStatusChipComponent,
    UserBatchRegistrationComponent,
    ConfirmationInputDialogComponent,
    MemberInviteDialogComponent,
    OrderReportDialogComponent,
    ChipsAutocompleteComponent,
    AsyncAutocompleteChipsComponent,
    MasterListItemComponent,
    ClientSelectBoxComponent,
    CsvdlItemsSelectableComponent,
    EntryCancelDialogComponent,
  ],
  entryComponents: [
    UserSearchDialogComponent,
    ConfirmationDialogComponent,
    LoadingDialogComponent,
    UserBatchRegistrationComponent,
    ConfirmationInputDialogComponent,
    MemberInviteDialogComponent,
    OrderReportDialogComponent,
    EntryCancelDialogComponent,
  ]
})
export class ComponentSharedModule { }
