/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Project {
    id: string;
    projectName: string;
    groups?: Array<object>;
    project_kind_master?: object;
    allowDuplicatedEntry: boolean;
    recruitmentStartAt: Date;
    recruitmentEndAt: Date;
    eventStartAt: Date;
    eventEndAt: Date;
    remarks?: string;
    messages?: string;
    publishStatus: Project.PublishStatusEnum;
    publishModifiedAt: Date;
    closedStatus?: Project.ClosedStatusEnum;
    closedModifiedAt?: Date;
    status: Project.StatusEnum;
    sendUpdateNotification: boolean;
    members?: Array<object>;
    brand_master?: object;
    category_masters?: Array<object>;
    client?: object;
    entries?: Array<object>;
    payments?: Array<object>;
    product_masters?: Array<object>;
    notificate: boolean;
    administrativeGroups?: Array<object>;
    project_confidential?: object;
    project_history?: object;
    reports?: Array<object>;
    reward?: object;
    file1?: object;
    file2?: object;
    candidates?: Array<object>;
    questionnaireURL?: string;
}
// tslint:disable-next-line: no-namespace
export namespace Project {
    export type PublishStatusEnum = 'draft' | 'public';
    export const PublishStatusEnum = {
        Draft: 'draft' as PublishStatusEnum,
        Public: 'public' as PublishStatusEnum
    };
    export type ClosedStatusEnum = 'deadline' | 'canceled';
    export const ClosedStatusEnum = {
        Deadline: 'deadline' as ClosedStatusEnum,
        Canceled: 'canceled' as ClosedStatusEnum
    };
    export type StatusEnum = 'registered' | 'recruiting' | 'closed' | 'completed';
    export const StatusEnum = {
        Registered: 'registered' as StatusEnum,
        Recruiting: 'recruiting' as StatusEnum,
        Closed: 'closed' as StatusEnum,
        Completed: 'completed' as StatusEnum
    };
}


