export const Path = {
  auth: {
    login: 'auth/login',
  },
  admin: {
    list: 'admin/list',
    create: 'admin/create',
    detail: 'admin/detail/:uid',
    /**
     * RouterPath 'admin/detail/:uid'
     * @param uid Firebase Auth uid
     */
    detailBase: 'admin/detail',
  },
  user: {
    list: 'user/list',
    create: 'user/create',
    detail: 'user/detail/:uid',
    sort: 'user/sort',
    /**
     * RouterPath 'user/detail/:uid'
     * @param uid Firebase Auth UID
     */
    detailBase: 'user/detail',
  },
  client: {
    list: 'client/list',
    create: 'client/create',
    detail: 'client/detail/:clientId',
    /**
     * RouterPath 'client/detail/:clientId'
     * @param clientId Firestore Document ID
     */
    detailBase: 'client/detail',
  },
  order: {
    list: 'order/list',
    create: 'order/create',
    detail: 'order/detail/:orderId',
    /**
     * RouterPath 'order/detail/:orderId'
     * @param orderId Firestore Document ID
     */
    detailBase: 'order/detail',
  },
  mail: {
    list: 'mail/list',
    create: 'mail/create',
    edit: 'mail/edit/:docId',
    detail: 'mail/detail/:docId',
    /**
     * RouterPath 'mail/detail/:docId'
     * @param docId Firestore Document ID
     */
    editBase: 'mail/edit',
    detailBase: 'mail/detail',
  },
  payment: {
    list: 'payment/list',
    detail: 'payment/detail/:uid',
    /**
     * RouterPath 'payment/detail/:uid'
     * @param uid Firebase Auth UID
     */
    detailBase: 'payment/detail',
  },
  master: {
    root: 'master',
    edit: 'master/edit',
  },
  help: {
    root: 'help'
  },
  setting: {
    root: 'setting'
  },
  serviceSettings: {
    root: 'service-settings'
  },
  history: {
    list: 'history/list'
  },
};
