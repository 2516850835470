import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

import { of } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { cloneDeepWith } from 'lodash';

import { Candidate } from '@lu/models';

@Injectable()
export class OrderCandidatesResolver implements Resolve<Array<Candidate & {_id: string}>> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {orderId} = route.params;
    return this.afStore.collection<Candidate>(`orders/${orderId}/candidates`)
      .valueChanges({idField: '_id'})
      .pipe(
        take(1),
        map(docs => docs.map(doc => cloneDeepWith(doc) as Candidate & {_id: string})),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      );
  }
}
