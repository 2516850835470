import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

import { Client, NewClient } from '@lu/models';
import { Path } from '@lu/path';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { DialogService } from '@lu/services/dialog.service';
import { MatchingService } from '@lu/services/matching.service';

@Component({
  selector: 'lu-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {
  private clientId = '';
  public client: Client;
  public clientForm = new FormGroup({
    foreignKey: new FormControl(null, Validators.required),
    clientName: new FormControl(null), // Except validation because readonly
    clientNameKana: new FormControl(null), // Except validation because readonly
    phoneNumber: new FormControl(null),
    email: new FormControl(null),
    id: new FormControl(null),
    remarks: new FormControl(null, [
      Validators.maxLength(2000)
    ]),
  } as { [K in keyof NewClient]: AbstractControl });
  private readonly path = Path;
  public pending = false;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private dialog: DialogService,
    private matchservice: MatchingService
  ) { }

  ngOnInit() {
    this.clientId = this.aRoute.snapshot.params.clientId;
    this.aRoute.data.pipe(
      filter((data: {
        client: Client
      }) => {
        if (_.isNil(data.client)) {
          alert('データを取得できませんでした。一覧画面に戻ります。');
          this.router.navigate([this.path.client.list]);
          return false;
        }
        return true;
      })
    )
      .subscribe(data => {
        this.client = data.client;
        Object.freeze(this.client);
        this.setDocumentsToForms();
      });
  }

  setDocumentsToForms() {
    this.clientForm.patchValue(this.client);
  }

  async updateRemarks() {
    const clientData: Partial<Client> = {
      remarks: this.clientForm.get('remarks').value
    };
    const data: LodingDialogData = { text: '' };
    const dialog = this.dialog.openLoadingDialog({
      data,
      disableClose: true
    });

    this.pending = true;
    try {
      data.text = '備考を更新しています...';
      this.matchservice.updateClient(this.clientId, clientData).subscribe(
        () => { }, error => {
          console.error('error', error);
        }
      );
      await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1000));

      data.text = '備考の更新が完了しました。';
      data.hiddenBar = true;
      await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1500));

      dialog.close();
      this.router.navigate([`/${this.path.client.list}`]);
      return;
    } catch (err) {
      dialog.close();
      console.error(err);
      alert('備考の更新に失敗しました。');
    }
    this.pending = false;
  }

  log() {
    console.log(this.clientForm);
  }
}
