import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MatchingService } from '@lu/services/matching.service';
import { Message } from '@lu/models';

@Injectable()
export class MailRequestResolver implements Resolve<Message> {
 constructor(
    private matchingService: MatchingService
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {docId} = route.params;
    return this.matchingService.getEachMessage(Number(docId));
  }
}
