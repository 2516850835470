import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';
import { AdminUser } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class AdminResolver implements Resolve<AdminUser> {
  constructor(
    private apiService: MatchingService
  ) { }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const { uid } = route.params;
    const getAdmin = () => {
      return new Promise<AdminUser>((resolve, reject) => {
        this.apiService.getAdminUserWithId(uid)
          .pipe(take(1))
          .subscribe(doc => resolve(doc), err => reject(err));
      });
    };
    const admin = await getAdmin();
    return admin;
  }
}
