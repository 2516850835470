import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { MatAutocompleteSelectedEvent, FloatLabelType } from '@angular/material';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { takeUntil, map, startWith, filter } from 'rxjs/operators';
import * as _ from 'lodash';


@Component({
  selector: 'lu-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss']
})
export class ChipsAutocompleteComponent implements OnInit, OnChanges {
  @ViewChild('selectionInput', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  // 絞り込みが適用されたソースのリスト
  public filteredSource: Observable<any[]>;
  private onChanges$ = new Subject<SimpleChanges>();

  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() formControl = new FormControl(null);
  @Input() placeholder: string;
  @Input() selectable = true;
  @Input() removable = true;
  @Input() unique = true;
  @Input() required = false;
  @Input() max = Infinity;
  @Input() displayWith: MatAutocomplete['displayWith'];
  /**
   * ソースとなる値
   */
  @Input() source: any[] = [];
  /**
   * 選択された値
   */
  @Input() value: any[] = [];
  /**
   * 選択肢から項目が選択された場合にinvoke
   */
  @Output() selectionChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges$.next(changes);
    // Update
    if (!Object.keys(changes).includes('source')) {
      // sourceが変更された場合にのみ後続の処理を実行する
      return;
    }
    this.filteredSource = this.formControl.valueChanges.pipe(
      takeUntil(
        this.onChanges$.pipe(filter(c => {
          return Object.keys(c).includes('source');
        }))
      ),
      startWith(null), // Trigger event before value changes.
      map((keyword: string | null) => {
        return typeof keyword === 'string' ? this._filter(keyword) : this.source || [];
      })
    );
  }

  /**
   * 入力値からリスト絞り込みを行う
   */
  _filter(keyword: string): any[] {
    const filterValue = keyword.toLowerCase();

    return this.source.filter(value => {
      const targetValue = this.displayWith ? this.displayWith(value) : value;
      return targetValue.toLowerCase().indexOf(filterValue) >= 0;
    });
  }

  /**
   * chipのdeleteボタンを押下時にcall
   * 削除された値を選択値から取り除く
   */
  remove(val: any) {
    const pos = _.findIndex(this.value, o => _.isEqualWith(o, val, (a, b) => _.isEqual(a, b)));
    if (pos >= 0) {
      this.value.splice(pos, 1);
    }
    this.selectionChange.emit(this.value);
  }

  /**
   * 選択肢から選択が行われた際にcall
   */
  add($event: MatAutocompleteSelectedEvent) {
    const {value} = $event.option;
    const exists = !!_.find(this.value, o => _.isEqual(o, value));
    if (_.size(this.value) < this.max
      && (!this.unique || !exists)) {
      this.value.push(value);
    }
    this.selectionChange.emit(this.value);
    this.clearInput();
    this.inputRef.nativeElement.blur();
  }

  clearInput() {
    this.formControl.setValue(null);
    this.inputRef.nativeElement.value = '';
  }
}
