// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envChip: {
    project: 'develop',
    color: 'white',
    backgroundColor: '#04439a',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyD1zBEORqvFR-JHhpNhShKGnz83zo5LiO0',
    authDomain: 'dott-matching-develop.firebaseapp.com',
    databaseURL: 'https://dott-matching-develop.firebaseio.com',
    projectId: 'dott-matching-develop',
    storageBucket: 'dott-matching-develop.appspot.com',
    messagingSenderId: '948893958505',
    appId: '1:948893958505:web:e6c75b50eb121b76b33df8',
    measurementId: 'G-YJFGZY5PER'
  },
  hosting: 'https://matching-admin-app-k5wrc4yklq-an.a.run.app',
  apiEndpoint: 'https://matching-strapi-k5wrc4yklq-an.a.run.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
