import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { Observable, Subject, NEVER, BehaviorSubject } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';

import { ScrollPanel } from '@lu/components/async-autocomplete-chips/async-autocomplete-chips.component';
import {
  Client,
} from '@lu/models';

import { MatchingService } from '@lu/services/matching.service';

@Component({
  selector: 'lu-client-select-box',
  templateUrl: './client-select-box.component.html',
  styleUrls: ['./client-select-box.component.scss']
})
export class ClientSelectBoxComponent implements OnInit, OnDestroy {
  @Input() public clientId: number;
  @Output() public clientChanges = new EventEmitter<Client>();

  private searchCtrl = {
    query: { search: [], size: 25, from: 0 },
    hasNext: true,
    pending: false,
    cachedList: [] as Client[],
  };
  public cachedClients: Record<string, Observable<Client & { id: string }>> = {};
  private dataStream = new BehaviorSubject(null);
  public dataStream$ = this.dataStream.asObservable();
  public searchClients$ = new Subject<string>();
  private onDestroy$ = new Subject();

  constructor(
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    this.searchClients$.asObservable()
      .pipe(debounceTime(250))
      .subscribe(keyword => {
        this.searchClient(keyword);
      });
    this.searchClient(); // first
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  get orderClient(): Observable<Client & { id: string }> {
    const cached = this.cachedClients[this.clientId];
    if (!this.clientId) {
      return NEVER;
    }
    if (cached) {
      return cached;
    }
    const subject = new BehaviorSubject(null);
    this.cachedClients[this.clientId] =
      subject.asObservable().pipe(filter(o => !!o));
    this.apiService.getEachClient(this.clientId, '').subscribe(doc => subject.next(doc));
    return this.cachedClients[this.clientId];
  }

  // Search clients with initialize ctrl.
  searchClient(keyword?: string | null) {
    const body = {
      search: [],
      from: 0,
      size: 25
    };
    this.searchCtrl.query = body;
    this.searchCtrl.cachedList = void 0;
    this.dataStream.next(null);
    this.searchCtrl.hasNext = true;
    this.searchCtrl.pending = true;
    if (keyword === undefined || null) {
      keyword = '';
    }
    const searchBody = {
      clientName_containss: keyword,
      _limit: body.size,
      _start: body.from
    };
    this.apiService.getClient(searchBody).subscribe(
      hits => {
        this.searchCtrl.cachedList = hits;
        this.searchCtrl.pending = false;
        this.dataStream.next(hits);
        if (this.searchCtrl.query.size > hits.length) {
          this.searchCtrl.hasNext = false;
        }
      }, error => {
        console.error('Something was wrong in get client call in client select box ', error);
        this.searchCtrl.pending = false;
      }
    );
  }

  /** Append results to order list */
  private searchNext() {
    if (!this.searchCtrl.query
      || !this.searchCtrl.hasNext
      || this.searchCtrl.pending) {
      return;
    }
    this.searchCtrl.pending = true;
    this.searchCtrl.query.from = this.searchCtrl.cachedList.length;
    const searchBody = {
      _limit: this.searchCtrl.query.size,
      _start: this.searchCtrl.query.from
    };
    this.apiService.getClient(searchBody).subscribe(
      hits => {
        this.searchCtrl.cachedList.push(...hits);
        this.searchCtrl.pending = false;
        this.dataStream.next(this.searchCtrl.cachedList);
        if (this.searchCtrl.query.size > hits.length) {
          this.searchCtrl.hasNext = false;
        }
      }, error => {
        console.error('Something was wrong in get client call in client select box ', error);
        this.searchCtrl.pending = false;
      }
    );
  }

  autoCompleteGetWith(field: string) {
    return (value: any) => value ? value[field] : value;
  }

  scrollPanel(e: ScrollPanel) {
    // console.log(e);
    if (e.scrollBottom >= e.threshold) {
      this.searchNext();
    }
  }
}
