import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Subject } from 'rxjs';

import { Group } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { of } from 'rxjs';
import { get } from 'lodash';
import { switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';

export type GroupResolverPayload = Group;

@Injectable()
export class GroupsResolver implements Resolve<GroupResolverPayload[]> {
  private uid: string;
  private groups: GroupResolverPayload[] = [];
  private close$ = new Subject();

  constructor(
    private apiService: MatchingService,
    private afAuth: AngularFireAuth,
  ) {
    this.close$.subscribe(() => {
      this.uid = undefined;
      this.groups = [];
    });
  }

  resolve() {
    this.uid = this.afAuth.auth.currentUser.uid;
    const resolve$ = new Subject<GroupResolverPayload[]>();
    this.afAuth.authState
      .pipe(
        takeUntil(this.close$),
        takeWhile(user => this.uid === get(user, 'uid'))
      )
      .subscribe(() => { }, () => this.close$.next(), () => this.close$.next());

    const permissionChange$ = new Subject();

    this.apiService.getAdminUser({ uid: this.uid })
      .pipe(
        takeUntil(this.close$),
        tap(() => permissionChange$.next()),
        switchMap(permissions => {
          if (permissions && permissions.length > 0) {
            const groupsChange$ = new Subject();
            const loginAdmin = permissions[0];
            permissionChange$.pipe(take(1)).subscribe(() => groupsChange$.next());
            if (loginAdmin.role === 'serviceAdmin') {
              return this.apiService.getGroups({ _sort: 'order:ASC' })
                .pipe(
                  takeUntil(this.close$),
                  takeUntil(permissionChange$),
                  takeUntil(groupsChange$)
                );
            }
            const adminGroups = loginAdmin.groups;
            const someMemberOfGroup = get(adminGroups, 'length') > 0;
            return !someMemberOfGroup ?
              of([] as Array<Group>) :  // return empty array.
              this.apiService.getGroups({ _sort: 'order:ASC', admin_users_in: loginAdmin.id })
                .pipe(
                  takeUntil(this.close$),
                  takeUntil(permissionChange$),
                  takeUntil(groupsChange$)
                );
          }
          return of([]);
        })
      ).subscribe((groups: Group[]) => {
        this.groups = groups;
        resolve$.next(groups);
        resolve$.complete();
      }, () => this.close$.next());

    return resolve$.asObservable();
  }
}
