import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from '@lu/environment';
import { auth } from 'firebase/app';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class FirebaseAuthTokenInterceptor implements HttpInterceptor {
  // リクエストの変換処理。ここに共通処理を記述。
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = auth().currentUser;
    const origins = [
      'http:\/\/localhost:(\d+)',
      environment.apiEndpoint.replace(/\./g, '\\.'), // escape dott string in url.
    ];
    const regExp = new RegExp(`^(${origins.join('|')})`);
    // console.log(request.url.match(regExp));
    if (!currentUser || !request.url.match(regExp)) {
      return next.handle(request);
    }
    return from(currentUser.getIdToken(false))
      .pipe(
        map(idToken => {
          // console.log('idToken:', idToken);
          const req = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${idToken}`)
          });
          return next.handle(req);
        }),
        catchError((err, caught) => next.handle(request)),
        switchMap((arg: Observable<HttpEvent<any>>) => arg)
      );
  }
}
