import {
  Directive,
  AfterViewInit,
  ElementRef,
  OnInit,
  HostListener
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[luBlockAutoFocusScroll]'
})

export class LuBlockAutoFocusScrollDirective implements OnInit {

  private scrollLock = false;
  private prevScrollTop;
  private lineHeight;
  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.lineHeight = this.getLineHeight();
    const containerScroll = fromEvent(document.getElementById('side_nav'), 'scroll');
    const contrainerScrollSubscripe = containerScroll.subscribe((evt: Event) => {
      const target = evt.currentTarget as HTMLTextAreaElement;
      if (this.scrollLock &&
        (Math.abs( target.scrollTop - this.prevScrollTop) > this.lineHeight)) {
        target.scrollTop = this.prevScrollTop;
      }
      this.scrollLock = false;
      this.prevScrollTop = target.scrollTop;
    });
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    this.scrollLock = true;
  }

 getLineHeight = () => {
    const target = this.el.nativeElement;
    const temp = document.createElement('input');
    temp.setAttribute('style', 'margin:0; padding:0; '
      + 'font-family:' + (target.style.fontFamily || 'inherit') + '; '
      + 'font-size:' + (target.style.fontSize || 'inherit'));
    temp.innerHTML = 'A';

    target.parentNode.appendChild(temp);
    const ret = temp.clientHeight;
    temp.parentNode.removeChild(temp);
    return ret;
  }
}
