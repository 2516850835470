import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil, isEmpty } from 'lodash';

export const CustomValidators: {[key: string]: ValidatorFn} = {
  periodVaridator(ctrl: AbstractControl) {
    const {startAt, endAt} = ctrl.value;
    if (startAt === null || endAt === null) {
      return null;
    }
    if (startAt - endAt >= 0) {
      return {period: true};
    }
    return null;
  },
  nullOrLengthVaridator(ctrl: AbstractControl) {
    if (isNil(ctrl.value) || ctrl.value.length > 0) {
      return null;
    }
    return {nullOrLength: true};
  },
  emptyOrLengthVaridator(ctrl: AbstractControl) {
    if (isEmpty(ctrl.value) || ctrl.value.length > 0) {
      return null;
    }
    return {emptyOrLength: true};
  }
};

export class ValidationRules {
  private commons = {
    // email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/, // batch
    email: /@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
    id: /^[a-zA-Z0-9_.]*$/,
    // url: /^https?:\/\/(w{3}\.)?([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/, // batch
    url: /^https?:\/\/(www\.)?.+\..+(\/.+)?$/,
  };

  constructor() {}

  member() {
    return Object.assign(this.commons, {
      bankAccountNameKana: /^[\u30a1-\u30f6\u30fc\uff66-\uff9f\s]*$/,
      bankAccountNumber: /^\d+$/,
      fullNameKana: /^[\u3041-\u3093\u30fc\s]*$/,
      gender: /^(male|female|unanswered)$/,
      // phoneNumber: /^(\+?\d+\s)?\d+(-?\d+)*$/, // batch
      phoneNumber: /^\+?\d+(-?\d+)*$/,
      postalCode: /^\d{3}-?\d{4}$/,
      screenName: /^[a-zA-Z0-9_-]*$/,
      twitter: /^[a-zA-Z0-9_]*$/,
      instagram: this.commons.id,
      tiktok: this.commons.id,
      youtube: /^[a-zA-Z0-9_-]*$/,
    });
  }

  asRegExp(targetObject: object) {
    const regExObj: Record<string, RegExp> = {};
    Object.keys(targetObject).forEach(key => {
      regExObj[key] = new RegExp(targetObject[key]);
    });
    return regExObj;
  }
}
