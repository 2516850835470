import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, of } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';
import { MatchingService } from '@lu/services/matching.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceAdminGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private apiService: MatchingService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.afAuth.authState.pipe(
      take(1),
      switchMap(user => {
        if (!user) {
          return of(false);
        }
        return this.apiService.getAdminUser({uid: user.uid})
          .pipe(
            take(1),
            map(doc => doc[0].role !== 'groupEditor' ? true : false)
          );
      })
    );
  }
}
