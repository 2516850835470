import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { BehaviorSubject, of } from 'rxjs';

import { Path } from '@lu/path';
import { AdminUser } from '@lu/models';
import { tap, switchMap, takeWhile } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MatchingService } from '@lu/services/matching.service';
import { groupEditor, groupManager, serviceAdmin } from '@lu/definitions/role';
import { environment } from '@lu/environment';
import { version } from '../../../../package.json';

@Component({
  selector: 'lu-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {
  public permissions$ = new BehaviorSubject<AdminUser>(null);
  public serviceSettings = false;
  public readonly path = Path;
  public serviceAdmins = serviceAdmin;
  public groupManager = groupManager;
  public groupEditor = groupEditor;
  public adminRole = { serviceAdmin: 'serviceAdmin', groupAdmin: 'groupAdmin', groupEditor: 'groupEditor' };
  public envChip = environment.envChip;
  public version: string = version;

  constructor(
    private afAuth: AngularFireAuth,
    private aRoute: ActivatedRoute,
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    this.aRoute.queryParams
      .pipe(takeWhile(() => !this.serviceSettings))
      .subscribe(params => {
        this.serviceSettings = !!params.serviceSettings;
      });
    this.afAuth.authState
      .pipe(
        tap(() => this.permissions$.next(null)),
        switchMap(user => user ?
          this.apiService.getAdminUser({ uid: user.uid }) : of(null)),
      )
      .subscribe(permissions => {
        if (permissions !== null && permissions.length > 0) {
          const admin = permissions[0];
          this.permissions$.next(admin);
        }
      });
  }
}
