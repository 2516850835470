import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';

import { Subject, zip } from 'rxjs';
import * as _ from 'lodash';

import {
  AdminUser,
  NewAdminUser,
  Group,
  NewGroup,
  GroupPermissions,
  ServicePermissions
} from '@lu/models';
import { Path } from '@lu/path';
import { Role, serviceAdmin, groupManager, groupEditor, defaultPermissions } from '@lu/definitions/role';
import { MatchingService } from '@lu/services/matching.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminService } from '@lu/services/admin.service';
import { DialogService } from '@lu/services/dialog.service';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';

@Component({
  selector: 'lu-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit, OnDestroy {
  public readonly columnToDisplay = ['adminNumber', 'displayName', 'permission', 'email', 'icon-delete', 'icon'];
  public readonly path = Path;
  public servicePermissions: ServicePermissions | GroupPermissions;
  public adminList: Array<AdminUser>;
  public groupList: Array<Group>;
  public role: Role;
  private onDestroy$ = new Subject();
  public pending = false;

  constructor(
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private adminService: AdminService,
    private apiService: MatchingService,
    private dialog: DialogService
  ) { }

  ngOnInit() {
    this.aRoute.data.subscribe((data: {
      servicePermissions: Array<AdminUser>,
      groups: Array<Group>
    }) => {
      const permiss = data.servicePermissions;
      if (permiss.length > 0) {
        switch (permiss[0].role) {
          case AdminUser.RoleEnum.ServiceAdmin:
            this.servicePermissions = serviceAdmin;
            break;
          case AdminUser.RoleEnum.GroupAdmin:
            this.servicePermissions = groupManager;
            break;
          case AdminUser.RoleEnum.GroupEditor:
            this.servicePermissions = groupEditor;
            break;
          default:
            this.servicePermissions = defaultPermissions;
            break;
        }
      } else {
        this.servicePermissions = defaultPermissions;
      }
      this.groupList = data.groups;
      this.subscribeAdmins();
    });
    const { uid } = this.afAuth.auth.currentUser;
    this.adminService.getAdminRole(uid).then(data => {
      if (data && data.role) {
        this.role = data.role;
      } else {
        this.role = null;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  subscribeAdmins() {
    const query = {
      _sort: 'id:ASC',
      leaved : 'false'
    };
    this.apiService.getAdminUser(query).subscribe(
      data => {
        if (data && data.length > 0) {
          this.adminList = data;
        }
      },
      error => {
        console.error('error in admin list page ', error);
      }
    );
  }

  getRoleName(role: AdminUser.RoleEnum) {
    if (role === AdminUser.RoleEnum.ServiceAdmin) {
      return;
    } else if (role === AdminUser.RoleEnum.GroupEditor) {
      return '媒体担当者';
    } else if (role === AdminUser.RoleEnum.GroupAdmin) {
      return '媒体管理者';
    } else {
      return;
    }
  }

  getGroupName(groups: Array<Group>) {
    if (groups.length > 0) {
      let group = '';
      groups.map(
        list => {
          group += list.groupName + ',';
        }
      );
      return group.slice(0, -1);
    } else {
      return '';
    }
  }

  async deleteAdminuser(admin: any) {
    const confirmed: boolean = await new Promise(resolve => {
      this.dialog.openConfirmDialog({
        data: {
          text: 'この管理ユーザーを削除しますか？',
          applyText: 'はい',
          cancelText: 'いいえ',
          applyButtonColor: 'warn',
          cancelButtonColor: 'primary',
        },
        panelClass: ['inverted-button-color-dialog'],
      }).afterClosed()
        .subscribe(result => resolve(!!result));
    });

    if (!confirmed) {
      return;
    }
    const data: LodingDialogData = {
      text: '管理ユーザーを一覧から削除しています...',
      color: 'warn'
    };
    const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });

    try {
      this.pending = true;

      const mailRequestRef = () => {
        return new Promise((resolve, reject) => {
          this.apiService.updateAdminUser(admin.id , { leaved : 'true'})
          .subscribe(
              doc => {
                resolve(doc);
              }, err => reject(err)
            );
        });
      };

      await mailRequestRef();

      await this.sleep(1000);
      data.text = '管理ユーザーを一覧から削除しました。';
      data.hiddenBar = true;
      await this.sleep(1500);

      dialog.close();
      location.reload();
    } catch (err) {
      console.error(err);
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      alert('管理ユーザーを一覧から削除できませんでした。');
    } finally {
      this.pending = false;
    }
  }

  sleep(time = 1000) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  }
}
