import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import { environment } from '@lu/environment';
import { skip, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth
  ) {
    this.watchAuthState();
  }

  watchAuthState() {
    this.afAuth.authState
      .pipe(
        tap(user => environment.production ? null : console.log(user)),
        skip(1), // 読み込み直後の1回をスキップ
      )
      .subscribe(user => {
        // ログインしてなくても良いページにいるかどうかをチェック
        // 認証が必要なページにいた場合はログインページに飛ばす
        // console.log(this.router.url);
        if (!user && !this.router.url.startsWith('/auth')) {
          const redirectTo = this.router.url;
          this.router.navigate(['auth/login'], { queryParams: { redirectTo } });
        }
      });
  }

  async login(email: string, password: string) {
    return await this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .catch(err => {
        console.error(err);
        throw new Error('メールアドレスまたはパスワードが違います。');
      });
  }

  async logout() {
    await this.afAuth.auth.signOut()
      .catch(err => {
        console.error(err);
        throw new Error('ログアウトエラー');
      });
  }
}
