/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Message {
    id: string;
    sendSchedule: Date;
    sendAt?: Date;
    status: Message.StatusEnum;
    groups?: Array<object>;
    fromEmail: string;
    fromName?: string;
    subject: string;
    message: string;
    mail_activities?: Array<object>;
}
// tslint:disable-next-line: no-namespace
export namespace Message {
    export type StatusEnum = 'draft' | 'requested' | 'completed' | 'canceled' | 'failed' | 'deleted';
    export const StatusEnum = {
        Draft: 'draft' as StatusEnum,
        Requested: 'requested' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Canceled: 'canceled' as StatusEnum,
        Failed: 'failed' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}


