import { Component, OnInit, Input } from '@angular/core';
import { forEach, split, flatten, filter, size, find, map as _map, lt, get, isArray } from 'lodash-es';

import { zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { ValidationRules } from '@lu/definitions/validators';
import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import {
  Group,
  Member,
  Address,
  MemberConfidential
} from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';
import { environment } from '@lu/environment';
import * as _ from 'lodash';

export type Profile = any;
export type ReadProfile = Member;

const validationRules = new ValidationRules();
const memberRules = validationRules.member();
const memberRulesRegExp = validationRules.asRegExp(memberRules);

@Component({
  selector: 'lu-user-batch-registration',
  templateUrl: './user-batch-registration.component.html',
  styleUrls: ['./user-batch-registration.component.scss']
})
export class UserBatchRegistrationComponent implements OnInit {
  @Input() profiles: ReadProfile;
  private groups: Array<Group>; //  For batch registration.
  private masters: Array<any>; //  For batch registration.
  public displayCols = [
    { key: 'memberNumber', value: 'ID' },
    { key: 'foreignKey', value: '連携用アカウント' },
    { key: 'leaved', value: 'leaved' },
    { key: 'order', value: 'order' },
    { key: 'fullName', value: '氏名' },
    { key: 'fullNameKana', value: '氏名（ふりがな）' },
    { key: 'displayName', value: 'ニックネーム（表示名）' },
    { key: 'postalCode', value: '郵便番号' },
    { key: 'address', value: '住所' },
    { key: 'email1', value: 'メールアドレス1' },
    { key: 'email2', value: 'メールアドレス2' },
    { key: 'phoneNumber1', value: '電話番号1' },
    { key: 'phoneNumber2', value: '電話番号2' },
    { key: 'gender', value: '性別' },
    { key: 'married', value: '結婚状況' },
    { key: 'birthDay', value: '生年月日' },
    { key: 'uncertainAge', value: '生年月日の非公開' },
    { key: 'height', value: '身長' },
    { key: 'weight', value: '体重' },
    { key: 'occupation_master', value: '職業' },
    { key: 'industry_master', value: '業種' },
    { key: 'job_master', value: '職種' },
    { key: 'hasChildren', value: '子あり・子なし' },
    // { key: 'children', value: '子供' },
    { key: 'childbirthDay', value: '子供の生年月日' },
    { key: 'registeredDate', value: 'ハピコミュ登録日' },
    { key: 'mainSiteName', value: 'BlogURLName' },
    { key: 'mainSiteURL', value: 'BlogURL' },
    { key: 'connection_twitter', value: 'Twitterアカウント' },
    { key: 'connection_instagram1', value: 'Instagramアカウント1' },
    { key: 'connection_instagram2', value: 'Instagramアカウント2' },
    { key: 'connection_tik_tok', value: 'Tiktokアカウント' },
    { key: 'connection_youtube', value: 'Youtubeアカウント' },
    { key: 'otherSiteURL1', value: 'その他URL1' },
    { key: 'otherSiteURL2', value: 'その他URL2' },
    { key: 'otherSiteURL3', value: 'その他URL3' },
    { key: 'member_status_master', value: '会員ステータス' },
    { key: 'groups', value: '媒体' },
    { key: 'tag_masters', value: 'タグ' },
    { key: 'member_confidential_tag_masters', value: '編集部用タグ' },
    { key: 'remarks', value: '編集部備考' },
    { key: 'publishStartAt', value: '公開日時' },
    { key: 'catchphrase', value: 'キャッチコピー' },
    { key: 'profile', value: 'プロフィール' },
    { key: 'summary', value: 'PRボード' },
    { key: 'image1', value: 'プロフィール画像1' },
    { key: 'image2', value: 'プロフィール画像2' },
    { key: 'image3', value: 'プロフィール画像3' },
    { key: 'image4', value: 'プロフィール画像4' },
  ];

  constructor(
    private CSVService: XLXSCSVService,
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    if (!environment.production) {
      console.log('profiles:', this.profiles);
    }
  }

  async subscribeMasters(kinds: string) {
    const getTag = () => {
      return new Promise<any>(resolve => {
        this.apiService.getMasterGroup(kinds, {
          parentMasterGroupId_null: false
        }).subscribe(docs => {
          docs.map(doc => doc.type = kinds);
          resolve(docs);
        });
      });
    };
    return await getTag();
  }

  async getMastersOfMember() {
    // Get latest masters
    return new Promise<void>((resolve, reject) => {
      zip(
        this.subscribeMasters('tag-masters'),
        this.subscribeMasters('member-status-masters'),
        this.subscribeMasters('occupation-masters'),
        this.subscribeMasters('industry-masters'),
        this.subscribeMasters('job-masters'),
        this.subscribeMasters('member-confidential-tag-masters')
      ).pipe(
        map(mastersList => flatten(mastersList))
      ).subscribe(masters => {
        this.masters = masters;
        resolve();
      }, err => reject(err));
    });
  }

  async getAllGroups() {
    const allGp = () => {
      return new Promise<any>(resolve => {
        this.apiService.getGroups('').subscribe(doc => resolve(doc));
      });
    };
    this.groups = await allGp();
  }

  async getOwnGroups(groups: Group[]) {
    const groupIds = _map(groups, 'id');
    const ownGp = () => {
      return new Promise<any>(resolve => {
        this.apiService.getGroups({ id_in: groupIds }).subscribe(doc => resolve(doc));
      });
    };
    this.groups = await ownGp();
  }

  parseJson(list: any[]): ReadProfile[] {
    const profiles: ReadProfile[] = [];
    forEach(list, item => {
      const profile: Partial<ReadProfile> = {};
      forEach(item, (val, key) => {
        let prop: any = val || null; // if empty string, convert to null.
        // Parse values
        switch (key) {
          case 'memberNumber':
            prop = prop ? Number.parseInt(prop, 10) : prop;
            break;
          case 'postalCode':
            prop = prop ? this.CSVService.fixPhoneNumber(prop) : null;
            break;
          case 'height':
          case 'weight':
            const fixedNumber = this.CSVService.fixFullWidthNumber(prop);
            prop = prop ? Number.parseFloat(fixedNumber) : prop;
            break;
          case 'birthDay':
          case 'publishStartAt':
          case 'registeredDate':
            prop = prop ? (this.CSVService.getValidDate(prop) || prop) : prop;
            break;
          case 'hasBankAccount':
            // case 'uncertainAge':
            prop = prop ? this.CSVService.stringToBool(prop) : prop; // non-nullable
            break;
          case 'uncertainAge':
            prop = prop ? this.CSVService.stringToBool(prop) : false; // non-nullable
            break;
          case 'married':
          case 'hasChildren':
            prop = prop ? this.CSVService.stringToBool(prop, true) : prop;
            break;
          // Multiple values
          case 'groups':
          case 'tag_masters':
          case 'member_confidential_tag_masters':
            const values = split(prop, /\n/).filter(o => o);
            prop = values;
            break;
          case 'connection_tik_tok':
            const tik = prop;
            prop =  tik ? tik.replace('https://tiktok.com/@', '') : null;
            break;
          case 'connection_twitter':
            const twit = prop;
            prop =  twit ? twit.replace('https://twitter.com/', '') : null;
            break;
          case 'connection_youtube':
            const yout = prop;
            prop =  yout ? yout.replace('https://youtube.com/channel/', '') : null;
            break;
          case 'connection_instagram1':
            const insta1 = prop;
            prop =  insta1 ? insta1.replace('https://instagram.com/', '') : null;
            break;
          case 'connection_instagram2':
            const insta2 = prop;
            prop =  insta2 ? insta2.replace('https://instagram.com/', '') : null;
            break;
        }
        if (key === 'children') {
          prop = prop ? this.parseChildStrings(prop) : [];
        }
        profile[key] = prop;
      });
      // Force values.
      if (!profile.hasChildren) {
        profile.children = [];
      }
      profiles.push(profile as any);
    });
    return profiles;
  }

  dropLeadingAndTrailingSpace(value: string) {
    if (typeof value !== 'string') {
      return value;
    }
    return value.replace(/(^\s*|\s*$)/g, '');
  }

  parseChildStrings(value: string) {
    const childlist = [];
    const childs = split(value, /\n/).filter(o => o);
    childs.forEach(child => {
      // tslint:disable-next-line: no-shadowed-variable
      const childVal = split(child, ',').map(o => this.dropLeadingAndTrailingSpace(o));
      if (childVal.length) {
        childlist.push({
          gender: childVal[0] || null,
          birthDay: !childVal[1] ? null : (this.CSVService.getValidDate(childVal[1]) || childVal[1])
        });
      }
    });
    return childlist;
  }

  parseAddress(value: string) {
    const addressVal = split(value, ',').map(o => this.dropLeadingAndTrailingSpace(o));
    if (!addressVal.length) {
      return null;
    }
    return {
      // sex: null,
      postalCode: addressVal[0] || null,
      address: addressVal[1] || null
    } as Address;
  }

  parseMemberConfidential(value: string) {
    const confiVal = split(value, /\n/).map(o => this.dropLeadingAndTrailingSpace(o));
    if (!confiVal.length) {
      return null;
    }
    const confidentialTags = confiVal[1];
    const tags = split(confidentialTags, ',').map(o => this.dropLeadingAndTrailingSpace(o));
    return {
      remarks: confiVal[0],
      member_confidential_tag_masters: tags
    } as Omit<MemberConfidential, 'id'>;
  }

  async validateMembersJSON(json: any[], mode: any) {
    const [tags, segments, occupations, industries, jobs, memberConfidentialTags] = [
      filter(this.masters, ['type', 'tag-masters']),
      filter(this.masters, ['type', 'member-status-masters']),
      filter(this.masters, ['type', 'occupation-masters']),
      filter(this.masters, ['type', 'industry-masters']),
      filter(this.masters, ['type', 'job-masters']),
      filter(this.masters, ['type', 'member-confidential-tag-masters']),
    ];
    const promises: Promise<string[]>[] = [];
    let index = 1;
    forEach(json, elem => promises.push(new Promise(resolve => {
      const messages: string[] = [];
      if (mode === 'update') {
        if (!elem.memberNumber) {
          messages.push(`${index}行目: 「ID」は必須項目です。`);
        }
        if (elem.fullName !== undefined && size(elem.fullName) <= 0) {
          messages.push(`${index}行目: 「fullName」は必須項目です。`);
        }
        if (elem.fullNameKana !== undefined && size(elem.fullNameKana) <= 0) {
          messages.push(`${index}行目: 「fullNameKana」は必須項目です。`);
        }
        if (elem.groups !== undefined && size(elem.groups) <= 0) {
          messages.push(`${index}行目: 「groups」は必須項目です。`);
        }
        if (elem.member_status_master !== undefined && elem.member_status_master === null) {
          messages.push(`${index}行目: 「segment」は必須項目です。`);
        }
        if (elem.email1 !== undefined && elem.email1 === null) {
          messages.push(`${index}行目: 「email1」は必須項目です。`);
        }
      }
      if (mode === 'create') {
        if (size(elem.fullName) <= 0) {
          messages.push(`${index}行目: 「fullName」は必須項目です。`);
        }
        if (size(elem.fullNameKana) <= 0) {
          messages.push(`${index}行目: 「fullNameKana」は必須項目です。`);
        }
        if (size(elem.groups) <= 0) {
          messages.push(`${index}行目: 「groups」は必須項目です。`);
        }
        if (elem.member_status_master === null) {
          messages.push(`${index}行目: 「segment」は必須項目です。`);
        }
        if (elem.email1 === null) {
          messages.push(`${index}行目: 「email1」は必須項目です。`);
        }
      }
      // screenName
      if (!memberRulesRegExp.screenName.test(elem.foreignKey)) {
        messages.push(`${index}行目: 「foreignKey」の値が正しくありません。(${elem.foreignKey})`);
      }
      if (elem.fullNameKana && !memberRulesRegExp.fullNameKana.test(elem.fullNameKana)) {
        messages.push(`${index}行目: 「fullNameKana」の値が正しくありません。(${elem.fullNameKana})`);
      }
      // Validate gender
      if (elem.gender
        && !memberRulesRegExp.gender.test(elem.gender)) {
        messages.push(`${index}行目: 「gender」の値が正しくありません。(${elem.gender})`);
      }
      // Validate phoneNumbers
      if (elem.phoneNumber1) {
        const valid = memberRulesRegExp.phoneNumber.test(elem.phoneNumber1);
        if (!valid) {
          messages.push(`${index}行目: 「phoneNumbers」内の電話番号形式が正しくありません。(${elem.phoneNumber1})`);
        }
      }
      if (elem.phoneNumber2) {
        const valid = memberRulesRegExp.phoneNumber.test(elem.phoneNumber2);
        if (!valid) {
          messages.push(`${index}行目: 「phoneNumbers」内の電話番号形式が正しくありません。(${elem.phoneNumber2})`);
        }
      }
      // Validate emails
      if (elem.email1) {
        const valid = memberRulesRegExp.email.test(elem.email1);
        if (!valid) {
          messages.push(`${index}行目: 「emails」内のメールアドレス形式が正しくありません。(${elem.email1})`);
        }
      }
      if (elem.email2) {
        const valid = memberRulesRegExp.email.test(elem.email2);
        if (!valid) {
          messages.push(`${index}行目: 「emails」内のメールアドレス形式が正しくありません。(${elem.email2})`);
        }
      }
      if (elem.postalCode) {
        if (elem.postalCode
          && !memberRulesRegExp.postalCode.test(elem.postalCode)) {
          messages.push(`${index}行目: 「postalCode」の形式が正しくありません。(${elem.postalCode})`);
        }
      }
      // Blog URL
      if (elem.mainSiteURL) {
        const valid = memberRulesRegExp.url.test(elem.mainSiteURL);
        if (!valid) {
          messages.push(`${index}行目: 「URLs」内のURL形式が正しくありません。(${elem.mainSiteURL})`);
        }
      }
      // Twitter
      if (elem.connection_twitter) {
        const valid = memberRulesRegExp.twitter.test(elem.connection_twitter);
        if (!valid) {
          messages.push(`${index}行目: 「twitter」内のアカウント名の形式が正しくありません。(${elem.connection_twitter})`);
        }
      }
      // Instagram
      if (elem.connection_instagram1) {
        const valid = memberRulesRegExp.instagram.test(elem.connection_instagram1);
        if (!valid) {
          messages.push(`${index}行目: 「instagram1」内のアカウント名の形式が正しくありません。(${elem.connection_instagram1})`);
        }
      }
      if (elem.connection_instagram2) {
        const valid = memberRulesRegExp.instagram.test(elem.connection_instagram2);
        if (!valid) {
          messages.push(`${index}行目: 「instagram1」内のアカウント名の形式が正しくありません。(${elem.connection_instagram2})`);
        }
      }

      // Youtube
      if (elem.connection_youtube) {
        const valid = memberRulesRegExp.youtube.test(elem.connection_youtube);
        if (!valid) {
          messages.push(`${index}行目: 「youtube」内のチャンネルの形式が正しくありません。(${elem.connection_youtube})`);
        }
      }
      // TikTok
      if (elem.connection_tik_tok) {
        const valid = memberRulesRegExp.tiktok.test(elem.connection_tik_tok);
        if (!valid) {
          messages.push(`${index}行目: 「tiktok」内のアカウント名の形式が正しくありません。(${elem.connection_tik_tok})`);
        }
      }
      // その他 URL
      if (elem.otherSiteURL1) {
        const valid = memberRulesRegExp.url.test(elem.otherSiteURL1);
        if (!valid) {
          messages.push(`${index}行目: 「URLs」内のURL形式が正しくありません。(${elem.otherSiteURL1})`);
        }
      }
      if (elem.otherSiteURL2) {
        const valid = memberRulesRegExp.url.test(elem.otherSiteURL2);
        if (!valid) {
          messages.push(`${index}行目: 「URLs」内のURL形式が正しくありません。(${elem.otherSiteURL2})`);
        }
      }
      if (elem.otherSiteURL3) {
        const valid = memberRulesRegExp.url.test(elem.otherSiteURL3);
        if (!valid) {
          messages.push(`${index}行目: 「URLs」内のURL形式が正しくありません。(${elem.otherSiteURL3})`);
        }
      }
      if (elem.height
        && (lt(elem.height, 0) || !isFinite(elem.height))) {
        messages.push(`${index}行目: 「height」は0以上の数字である必要があります。(${elem.height})`);
      }
      if (elem.weight
        && (lt(elem.weight, 0) || !isFinite(elem.weight))) {
        messages.push(`${index}行目: 「weight」は0以上の数字である必要があります。(${elem.weight})`);
      }
      // Validate images
      if (elem.image1) {
        const valid = memberRulesRegExp.url.test(elem.image1);
        if (!valid) {
          messages.push(`${index}行目: 「images」内のURL形式が正しくありません。(${elem.image1})`);
        }
      }
      if (elem.image2) {
        const valid = memberRulesRegExp.url.test(elem.image2);
        if (!valid) {
          messages.push(`${index}行目: 「images」内のURL形式が正しくありません。(${elem.image2})`);
        }
      }
      if (elem.image3) {
        const valid = memberRulesRegExp.url.test(elem.image3);
        if (!valid) {
          messages.push(`${index}行目: 「images」内のURL形式が正しくありません。(${elem.image3})`);
        }
      }
      if (elem.image4) {
        const valid = memberRulesRegExp.url.test(elem.image4);
        if (!valid) {
          messages.push(`${index}行目: 「images」内のURL形式が正しくありません。(${elem.image4})`);
        }
      }
      // Validate children
      if (size(elem.children) > 0) {
        elem.children.map(child => {
          if (child.birthDay
            && !(child.birthDay instanceof Date)) {
            messages.push(`${index}行目: 「children」内の日付形式が正しくありません。(${child.birthDay})`);
          }
          if (child.gender
            && !memberRulesRegExp.gender.test(child.gender)) {
            messages.push(`${index}行目: 「children」内の値が正しくありません。(${child.gender})`);
          }
        });
      }
      // Is Exists group name
      if (size(elem.groups) > 0) {
        elem.groups.map(groupName => {
          const exists = find(this.groups, ['groupName', groupName]);
          if (!exists) {
            messages.push(`${index}行目: 「groups」にマスタ未登録か所属していない媒体が含まれています。(${groupName})`);
          }
        });
      }
      // Is Exists Master names
      if (size(elem.tag_masters) > 0) {
        elem.tag_masters.map(tagName => {
          const exists = find(tags, ['name', tagName]);
          if (!exists) {
            messages.push(`${index}行目: 「tags」にマスタ未登録の項目が含まれています。(${tagName})`);
          }
        });
      }
      if (elem.member_confidential_tag_masters) {
        if (size(elem.member_confidential_tag_masters) > 0) {
          elem.member_confidential_tag_masters.map(tagName => {
            const exists = find(memberConfidentialTags, ['name', tagName]);
            if (!exists) {
              messages.push(`${index}行目: 「tags」にマスタ未登録の項目が含まれています。(${tagName})`);
            }
          });
        }
      }
      if (elem.member_status_master && !find(segments, ['name', elem.member_status_master])) { // Requre
        messages.push(`${index}行目: 「segment」にマスタ未登録の項目が含まれています。(${elem.member_status_master})`);
      }
      if (elem.occupation_master
        && !find(occupations, ['name', elem.occupation_master])) {
        messages.push(`${index}行目: 「occupation」にマスタ未登録の項目が含まれています。(${elem.occupation_master})`);
      }
      if (elem.industry_master
        && !find(industries, ['name', elem.industry_master])) {
        messages.push(`${index}行目: 「industry」にマスタ未登録の項目が含まれています。(${elem.industry_master})`);
      }
      if (elem.job_master
        && !find(jobs, ['name', elem.job_master])) {
        messages.push(`${index}行目: 「job」にマスタ未登録の項目が含まれています。(${elem.job_master})`);
      }
      // Validate Date strings
      if (elem.birthDay
        && !(elem.birthDay instanceof Date)) {
        messages.push(`${index}行目: 「birthDay」の日付形式が正しくありません。(${elem.birthDay})`);
      }
      if (elem.registeredDate
        && !(elem.registeredDate instanceof Date)) {
        messages.push(`${index}行目: 「anniversary」の日付形式が正しくありません。(${elem.registeredDate})`);
      }
      if (elem.publishStartAt
        && !(elem.publishStartAt instanceof Date)) {
        messages.push(`${index}行目: 「openPeriod」の日付形式が正しくありません。(${elem.publishStartAt})`);
      }
      resolve(messages);
      // Children
      index++;
    })));
    const results = flatten(await Promise.all(promises));
    if (results.length) {
      throw { results };
    }
  }

  fixInstagram(member: any, instagram: any, followers: any) {
    const list = [];
    if (instagram.length > 0) {
      instagram.forEach((doc: any, i: number) => {
        const follower = followers[i];
        // undefinedの場合はオブジェクトを生成しない、それ以外の場合は生成する
        if (doc !== undefined) {
          list.push({
            username: doc,
            followersCount: follower,
            order: i
          });
        }
      });
    }
    if (size(list) > 0) {
      member.connection_instagrams = list;
    }
  }

  fixMemberConfidential(tags: any[], remarks: string, oldTags: any[]) {
    const result: any = {};

    if (tags !== undefined) {
      let tagIds: any = [];
      if (tags.length > 0) {
        tagIds = _map(tags, name => find(oldTags, ['name', name]).id);
      }
      result.member_confidential_tag_masters = tagIds;
    }

    if (remarks !== undefined) {
      result.remarks = remarks;
    }

    return result;
  }

  fixAddress(address: {
    address: string,
    postalCode: string
  }) {
    const result: any = {};
    if (address.address !== undefined) {
      result.address = address.address;
    }
    if (address.postalCode !== undefined) {
      result.postalCode = address.postalCode;
    }
    return result;
  }

  fixTwitter(profile) {
    let twitter = null;
    if (profile.connection_twitter !== undefined) {
      twitter = {
        screenName: profile.connection_twitter,
        followersCount: profile.connection_twitter_follower
      };
    }
    return twitter;
  }

  clean(member: any) {
    for (const propName in member) {
      if ((member[propName] === null || member[propName] === undefined) ||
        (isArray(member[propName]) && (member[propName]).length <= 0)) {
        delete member[propName];
      }
    }
    return member;
  }

  covertToProfile(profile: any, mode: any) {
    const [tags, segments, occupations, industries, jobs, memberConfidentialTags] = [
      filter(this.masters, ['type', 'tag-masters']),
      filter(this.masters, ['type', 'member-status-masters']),
      filter(this.masters, ['type', 'occupation-masters']),
      filter(this.masters, ['type', 'industry-masters']),
      filter(this.masters, ['type', 'job-masters']),
      filter(this.masters, ['type', 'member-confidential-tag-masters']),
    ];
    const connection = [profile.connection_instagram1, profile.connection_instagram2];
    const confiVal = split(profile.connection_instagrams_follower, /\n/);
    const childbirthday = [];
    if (profile.childbirthDay) {
      const childBirthdayList = split(profile.childbirthDay, /\n/);
      childBirthdayList.forEach((cbd) => {
        const d = cbd.split(',');
        const gender = d[0] ? d[0] : null;
        const birthday = d[1] ? this.CSVService.getValidDate(d[1]) : null;
        childbirthday.push({
          birthDay: birthday,
          gender
        });
      });
    }

    let member: any = {
      order: 0,
      leaved: false
    };
    this.setMemberItem('id', member, profile.memberNumber);
    this.setMemberItem('foreignKey', member, profile.foreignKey);
    this.setMemberItem('fullName', member, profile.fullName);
    this.setMemberItem('fullNameKana', member, profile.fullNameKana);
    this.setMemberItem('displayName', member, profile.displayName);
    this.setMemberItem('summary', member, profile.summary);
    this.setMemberItem('profile', member, profile.profile);
    this.setMemberItem('catchphrase', member, profile.catchphrase);
    this.setMemberItem('groups', member, profile.groups,
      _map(profile.groups, name => find(this.groups, ['groupName', name]).id)
    );
    this.setMemberItem('tag_masters', member, profile.tag_masters,
      _map(profile.tag_masters, name => find(tags, ['name', name]).id)
    );
    this.setMemberItem('member_status_master', member, profile.member_status_master,
      get(find(segments, ['name', profile.member_status_master]), 'id') || null
    );
    this.setMemberItem('occupation_master', member, profile.occupation_master,
      get(find(occupations, ['name', profile.occupation_master]), 'id') || null
    );
    this.setMemberItem('industry_master', member, profile.industry_master,
      get(find(industries, ['name', profile.industry_master]), 'id') || null
    );
    this.setMemberItem('job_master', member, profile.job_master,
      get(find(jobs, ['name', profile.job_master]), 'id') || null
    );
    this.setMemberItem('gender', member, profile.gender);
    this.setMemberItem('birthDay', member, profile.birthDay);
    this.setMemberItem('uncertainAge', member, profile.uncertainAge);
    this.setMemberItem('married', member, profile.married);
    this.setMemberItem('hasChildren', member, profile.hasChildren);
    this.setMemberItem('children', member, profile.childbirthDay, childbirthday  ? childbirthday  : []);
    this.setMemberItem('height', member, profile.height);
    this.setMemberItem('weight', member, profile.weight);
    this.setMemberItem('phoneNumber1', member, profile.phoneNumber1);
    this.setMemberItem('phoneNumber2', member, profile.phoneNumber2);
    this.setMemberItem('email1', member, profile.email1);
    this.setMemberItem('email2', member, profile.email2);
    this.setMemberItem('mainSiteName', member, profile.mainSiteName);
    this.setMemberItem('mainSiteURL', member, profile.mainSiteURL);
    this.setMemberItem('otherSiteName1', member, profile.otherSiteName1);
    this.setMemberItem('otherSiteURL1', member, profile.otherSiteURL1);
    this.setMemberItem('otherSiteName2', member, profile.otherSiteName2);
    this.setMemberItem('otherSiteURL2', member, profile.otherSiteURL2);
    this.setMemberItem('otherSiteName3', member, profile.otherSiteName3);
    this.setMemberItem('otherSiteURL3', member, profile.otherSiteURL3);
    this.setMemberItem('publishStartAt', member, profile.publishStartAt);
    member.member_confidential = this.fixMemberConfidential(
      profile.member_confidential_tag_masters, profile.remarks, memberConfidentialTags);
    member.address = this.fixAddress({
      address: profile.address,
      postalCode: profile.postalCode
    });
    this.setMemberItem('connection_twitter', member, profile.connection_twitter, this.fixTwitter(profile));
    this.fixInstagram(member, connection, confiVal);
    this.setMemberItem('connection_tik_tok', member, profile.connection_tik_tok,
      profile.connection_tik_tok !== null ? { uniqueId: profile.connection_tik_tok } : null
    );
    this.setMemberItem('connection_youtube', member, profile.connection_youtube,
      profile.connection_youtube !== null ? { idOfResponse: profile.connection_youtube } : null
    );
    if (mode === 'update') {
      member = _.omit(member, 'order');
    }
    return member;
  }

  setMemberItem(keyName, member, value, customValue= null) {
    if (value !== undefined) {
      if (customValue) {
        member[keyName] = customValue;
      } else {
        member[keyName] = value;
      }
    }
  }
}
