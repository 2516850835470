import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Member, TagMaster } from '@lu/models';

import { get, isEqual } from 'lodash-es';

@Component({
  selector: 'lu-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: Member | any;
  @ViewChild('cardIcon', { static: true }) cartIcon: ElementRef;
  @ViewChild('contextMenu', { static: true }) contextMenu: ElementRef;
  public tagList: Array<TagMaster> | Array<object>;

  constructor(
    private renderer2: Renderer2,
  ) { }

  ngOnInit() {
    document.body.addEventListener('click', this.closeContextMenu);
    document.body.addEventListener('contextmenu', this.closeContextMenu);
  }

  ngOnChanges(changes: SimpleChanges) {
    const [prevUser, currentUser] = [get(changes, 'user.previousValue'), get(changes, 'user.currentValue')];
    if (!isEqual(get(prevUser, 'tag_masters'), get(currentUser, 'tag_masters'))) {
      this.getTags();
    }
  }

  ngOnDestroy() {
    document.body.removeEventListener('click', this.closeContextMenu);
    document.body.removeEventListener('contextmenu', this.closeContextMenu);
  }

  getTags() {
    if (!get(this.user, 'tag_masters.length')) {
      this.tagList = [];
      return;
    }

    this.tagList = this.user.tag_masters;
  }

  getInstagramURL(username: string): string {
    return 'https://instagram.com/' + username;
  }

  getTwitterURL(screenName: string): string {
    return 'https://twitter.com/' + screenName;
  }

  getMaxFollowersAccount(accounts: any): number {
    let maxFollewrCount = 0;
    if (!Array.isArray(accounts)) {
      return maxFollewrCount;
    }
    // html側でアカウントの種類はフィルタリングされた前提で使う
    accounts.forEach(account => {
      if (account.followersCount !== undefined
        && account.followersCount !== null
        && account.followersCount > maxFollewrCount) {
        maxFollewrCount = account.followersCount;
      }
    });
    return maxFollewrCount;
  }

  closeContextMenu = (event: MouseEvent, forced = false) => { // avoid binding of "this" by using arrow function.
    const element: HTMLElement = this.contextMenu.nativeElement;
    if (!element.classList.contains('opened')) {
      return; // return immediately if context menu hasn't been opened.
    }
    if (forced) {
      this.renderer2.removeClass(element, 'opened');
      return;
    }
    // If anyone other than own parent is clicked, close the context menu.
    const targetElement = event.target as HTMLElement;
    const targetElements = ((container: HTMLElement[]) => {
      let parent: HTMLElement = targetElement.parentElement;
      while (parent) {
        container.unshift(parent);
        parent = parent.parentElement;
      }
      return container;
    })([]);
    if (targetElements.includes(this.cartIcon.nativeElement)) {
      return;
    }
    this.renderer2.removeClass(element, 'opened');
  }

  openContextMenu(event: MouseEvent) {
    const element: HTMLElement = this.contextMenu.nativeElement;
    this.renderer2.setStyle(element, 'left', event.pageX + 'px');
    this.renderer2.setStyle(element, 'top', event.pageY + 'px');
    this.renderer2.addClass(element, 'opened');
    event.preventDefault();
  }

  downloadImage(event: Event, file: any) {
    event.stopPropagation();
    event.preventDefault();
    this.closeContextMenu(null, true);

    const link = document.createElement('a');
    const extention = file.mime.replace(/^.*\//, '');
    const memberNum = String(this.user.id);
    const fileName = `${memberNum}_images_0.${extention}`;
    link.setAttribute('href', file.url);
    link.setAttribute('download', fileName);
    link.click();
    link.remove();
  }
}
