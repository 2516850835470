import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { cloneDeepWith } from 'lodash';

const timestampConverter = (val, key) => {
  // skip document timestamps, ex. created.at, modified.at...
  if (key === 'at') {
    return void 0;
  }
  return val instanceof firestore.Timestamp ? val.toDate() : void 0;
};

@Injectable()
export class OrderSecretConfidentialsResolver implements Resolve<Array<any>> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {orderId} = route.params;
    return this.afStore.collection<any>(`orders/${orderId}/confidentials`, ref => {
      return ref.where('permission.level', '==', 2);
    })
      .valueChanges({idField: '_id'})
      .pipe(
        take(1),
        map(docs => docs.map(doc => cloneDeepWith(doc, timestampConverter) as any)),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      );
  }
}
