import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { Path } from '@lu/path';
import { MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from '../../custom-paginator-configuration';
import { MatchingService } from '@lu/services/matching.service';
import { ProjectHistory } from '@lu/models';

@Component({
  selector: 'lu-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})

export class HistoryListComponent implements OnInit, OnDestroy {
  public readonly columnToDisplay = ['historyNumber', 'orderName', 'displayName', 'timestamp'];
  public readonly path = Path;
  public historyList: Array<History> = [];
  private onDestroy$ = new Subject();
  showList: Array<History>;
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = ['20', '40', '60', '80', '100'];

  constructor(
    private apiService: MatchingService
  ) { }

  ngOnInit() {
    this.getHistoryList();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  async getHistoryList() {
    const allData = () => {
      return new Promise<Array<History>>((myresolve, myreject) => {
        this.apiService.getProjectHistory({ _sort: 'timestamp:DESC', _limit: -1 })
          .subscribe(
            (historyData: any) => {
              myresolve(historyData);
            }, err => {
              console.error('eror in get project history list ', err);
              myreject(err);
            }
          );
      });
    };
    const allHistory = await allData();
    if (allHistory.length > 0) {
      allHistory.map(
        async eachHistory => {
          eachHistory.displayName = await this.getAuthorInfo(eachHistory.author);
        }
      );
      this.historyList = allHistory;
    }
    if (this.historyList.length > 0) {
      this.showList = this.historyList.slice(this.pageIndex, this.pageSize);
    }
  }

  /*get Author Name*/
  async getAuthorInfo(id: number) {
    const adminName = () => {
      return new Promise<string>((myresolve, myreject) => {
        this.apiService.getAdminUserWithId(id)
          .subscribe(doc => {
            if (doc) {
              myresolve(doc.displayName);
            }
          }, err => {
            console.error('Error in get author name ', err);
            myreject(err);
          });
      });
    };
    const admin = await adminName();
    return admin;
  }

  /*paginator*/
  getPaginatorData(event: any) {
    this.showList = [];
    const startingIndex = event.pageIndex * event.pageSize;
    const endingIndex = startingIndex + event.pageSize;
    this.showList = this.historyList.slice(startingIndex, endingIndex);
  }
}

export type History = ProjectHistory & { displayName: string };
