import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    const dull = Number.isFinite(args[0]) ? +args[0] : 1;
    if (Number.isFinite(value)) {
      let count = 0;
      let size = value;
      const units = ['B', 'KB', 'MB', 'GB'];
      while (size > 1024 / dull) {
        size = size / 1024;
        count++;
      }
      const unit = units[count] ? units[count] : units[units.length - 1];
      return Math.floor(size * 10) / 10 + unit;
    }
    return null;
  }

}
