import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MemberConfidential } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class MemberConfidentialsResolver implements Resolve<MemberConfidential> {
  constructor(
    private apiService: MatchingService
  ) { }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const { uid } = route.params;
    const getConfidential = () => {
      return new Promise<MemberConfidential>((resolve, reject) => {
        this.apiService.getMemberConfidential({ members: uid })
          .subscribe(doc => resolve(doc[0]), err => reject(err));
      });
    };

    const confidential = await getConfidential();
    return confidential;
  }
}
