import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgCountPipeModule } from 'angular-pipes';
import { NgSumPipeModule } from 'angular-pipes';
import { NgWherePipeModule } from 'angular-pipes';
import { NgHeadPipeModule } from 'angular-pipes';
import { NgEmptyPipeModule } from 'angular-pipes';
import { NgTailPipeModule } from 'angular-pipes';
import { NgPluckPipeModule } from 'angular-pipes';
import { NgWithoutPipeModule } from 'angular-pipes';

import { FileSizePipe } from './file-size.pipe';
import { RootPathPrefixPipe } from './root-path-prefix.pipe';
import { GetPipe } from './get.pipe';
import { ToDatePipe } from './to-date.pipe';

@NgModule({
  declarations: [
    FileSizePipe,
    RootPathPrefixPipe,
    GetPipe,
    ToDatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileSizePipe,
    RootPathPrefixPipe,
    GetPipe,
    ToDatePipe,
    NgWherePipeModule,
    NgSumPipeModule,
    NgHeadPipeModule,
    NgEmptyPipeModule,
    NgCountPipeModule,
    NgTailPipeModule,
    NgPluckPipeModule,
    NgWithoutPipeModule,
  ]
})
export class PipesModule { }
