import {
  ServicePermissions,
  GroupPermissions,
  SecurityClearance
} from '@lu/models';

const fullControl = {
  read: true,
  create: true,
  update: true,
  delete: true,
  clearance: 3 as SecurityClearance
};
const readOnly = {
  read: true,
  create: false,
  update: false,
  delete: false,
  clearance: 0 as SecurityClearance
};
const forbidden = {
  read: false,
  create: false,
  update: false,
  delete: false,
  clearance: 0 as SecurityClearance
};
Object.freeze(fullControl);
Object.freeze(readOnly);
Object.freeze(forbidden);

export const defaultPermissions: ServicePermissions = {
  administrate: false,
  user: forbidden,
  order: forbidden,
  assign: false,
  client: forbidden,
  group: forbidden,
  payment: forbidden,
  master: forbidden,
  allGroupViewable: false,
  log: {read: false, rollback: false}
};
export const serviceAdmin: ServicePermissions = {
  administrate: true,
  user: fullControl,
  order: fullControl,
  assign: true,
  client: fullControl,
  group: fullControl,
  payment: fullControl,
  master: fullControl,
  allGroupViewable: true,
  log: { read: true, rollback: true }
};
Object.freeze(defaultPermissions);
Object.freeze(serviceAdmin);

export const groupManager: GroupPermissions = {
  administrate: true,
  user: fullControl,
  order: fullControl,
  entry: false,
  assign: true,
  group: readOnly,
  master: forbidden,
  payment: fullControl,
  client: null,
  allGroupViewable: false,
  log: { read: true }
};
export const groupEditor: GroupPermissions = {
  administrate: false,
  user: fullControl,
  order: fullControl,
  entry: false,
  assign: true,
  group: readOnly,
  master: forbidden,
  payment: fullControl,
  client: null,
  allGroupViewable: false,
  log: { read: true },
};
export const groupMember: GroupPermissions = {
  administrate: false,
  user: forbidden,
  order: forbidden,
  entry: true,
  assign: false,
  group: readOnly,
  master: forbidden,
  payment: forbidden,
  client: null,
  allGroupViewable: false,
  log: { read: false }
};
Object.freeze(groupManager);
Object.freeze(groupEditor);
Object.freeze(groupMember);


export interface Role {
  readonly roleName: string;
  readonly permission: {
    readonly service: Readonly<ServicePermissions> | null;
    readonly group: Readonly<GroupPermissions> | null;
  };
}

export const roles: Role[] = [
  {roleName: 'サービス管理者', permission: {service: serviceAdmin, group: null}},
  {roleName: '媒体管理者', permission: {service: null, group: groupManager}},
  {roleName: '媒体担当者', permission: {service: null, group: groupEditor}}
];
Object.freeze(roles);
