import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { MatchingService } from '@lu/services/matching.service';

import { Client } from '@lu/models';

type ESClient = Client;

@Injectable()
export class ClientsScrollStrategy extends DataSource<ESClient> {
  private dataStream = new BehaviorSubject<ESClient[]>([]);
  public dataStream$ = this.dataStream.asObservable();
  private fetchedPages = new Set<number>();
  private subscription = new Subscription();
  private cachedList: ESClient[];
  private queryTotal: number;
  private pageSize = 50;
  public hasNext = true;
  public searchData = '';
  private pending = false;
  private query = { _limit: 25, _start: 0 };

  constructor(
    private matchservice: MatchingService
  ) {
    super();
  }

  // reference https://material.angular.io/cdk/scrolling/overview#creating-items-in-the-viewport
  connect(collectionViewer: CollectionViewer) {
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this.getPageForIndex(range.start);
      const endPage = this.getPageForIndex(range.end - 1);
      for (let i = startPage; i <= endPage; i++) {
        this.fetchPage(i);
      }
    }));
    return this.dataStream;
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);
    this.searchNext();
  }

  public get theEnd(): boolean {
    return !this.hasNext;
  }

  public get isPending(): boolean {
    return this.pending;
  }

  public get dataLength(): number {
    return Array.isArray(this.cachedList) ? this.cachedList.length : 0;
  }

  public get hitLength(): number {
    return this.queryTotal;
  }

  /** With refresh order list. */
  search(query = {
    clientName_containss: this.searchData,
    _limit: this.pageSize,
    _start: 0
  }) {
    this.pending = true;
    this.hasNext = true;
    this.queryTotal = null;
    this.cachedList = null;
    this.query = cloneDeep(query);
    this.pageSize = (this.query._limit - 1);
    this.fetchedPages.clear();
    this.dataStream.next([]);
    this.fetchPage(0);

    this.matchservice.getClient(this.query)
      .subscribe(result => {
        this.pending = false;
        this.queryTotal = result.length;
        this.cachedList = result;
        this.dataStream.next(result);
        if (this.pageSize > result.length) {
          this.hasNext = false;
        }
      }, err => {
        console.error(err);
        this.pending = false;
      });
  }

  /** Append results to order list */
  searchNext() {
    if (!this.query
      || !this.hasNext
      || this.pending) {
      return;
    }
    this.pending = true;
    this.query._start = this.cachedList.length;

    this.matchservice.getClient(this.query)
      .subscribe(result => {
        this.pending = false;
        this.cachedList.push(...result);
        this.dataStream.next(this.cachedList);
        if (this.query._limit > result.length) {
          this.hasNext = false;
        }
      }, err => {
        console.error(err);
        this.pending = false;
      });
  }
}
