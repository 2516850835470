import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  compact,
  findIndex,
  find,
  filter as _filter,
  replace,
  map as _map,
  chunk,
  cloneDeep,
  difference,
  differenceBy,
  forEach,
  get,
  union,
} from 'lodash-es';
import { Subject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import {
  Group,
  ServicePermissions,
  Member,
  GroupPermissions,
  Message,
  NewMessage,
  MailActivity,
  AdminUser,
} from '@lu/models';
import { DialogService } from '@lu/services/dialog.service';
import { UserSearchDialogComponent } from '@lu/components/user-search-dialog/user-search-dialog.component';
import { Path } from '@lu/path';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { MailService } from '@lu/services/mail.service';
import { MatchingService } from '@lu/services/matching.service';
import { defaultPermissions, groupEditor, groupManager, Role, serviceAdmin } from '@lu/definitions/role';
import { AdminService } from '@lu/services/admin.service';
import { environment } from '@lu/environment';
import { MatAutocompleteModule } from '@angular/material';

class MailRequestError extends Error {
  name: string;
  code: string;
  constructor(message: string, props?: { code: string }) {
    super(message);
    this.name = 'MailRequestError';
    this.code = props ? props.code : null;
  }
}
const SendScheduleErrorCode = 'invalid-scheduled-time';


@Component({
  selector: 'lu-mail-detail',
  templateUrl: './mail-detail.component.html',
  styleUrls: ['./mail-detail.component.scss'],
  providers: [
    DialogService
  ]
})
export class MailDetailComponent implements OnInit {
  @ViewChild('adminDialog', { static: false }) private adminDialog: TemplateRef<HTMLElement>;

  public directInputForm = new FormControl(null, [Validators.required, Validators.email]);
  public mailResultSearchForm = new FormGroup({
    type: new FormControl(null),
    sendResult: new FormControl(null),
  });
  // Message Form
  public messageForm = new FormGroup({
    sendSchedule: new FormControl(moment().add(1, 'h').toDate()),
    sendAt: new FormControl(null),
    status: new FormControl(null),
    fromEmail: new FormControl(null),
    fromName: new FormControl(null),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    created_at: new FormControl(null),
    updated_at: new FormControl(null),
    groups: new FormControl([]),
    mail_activities: new FormControl([{
      id: new FormControl(null),
      email: new FormControl(null),
      trackingId: new FormControl(null),
      type: new FormControl(null),
      uid: new FormControl(null),
      result: new FormControl(null),
      timestamp: new FormControl(null),
      message: new FormControl(null),
      created_at: new FormControl(null),
      updated_at: new FormControl(null)
    }])
  } as { [K in keyof NewMessage]: AbstractControl });
  // Mail Request.
  public mailRequestStateForm = new FormGroup({});
  public messages: Message;
  public mailActivities: any[];
  public filteredMailActivities: any[];
  // mail-activity、画面で表示されるチップを作成する元になるデータ
  // mail-activityの作成に必要なデータと、画面に表示されるチップを表示する為に必要なデータを両方もつ。
  public selectedMemberMailActivityBases: any[] = [];
  public selectedAdminMailActivityBases: any[] = [];
  public selectedDirectInputMailActivityBases: any[] = [];
  public mailRequestStatus = Message.StatusEnum;
  public mailRequestItemType = MailActivity.TypeEnum;
  public types = [
    { label: '管理者', value: 'admin' },
    { label: 'メンバー', value: 'member' },
    { label: '直接入力', value: 'directInput' },
  ];
  public resultEvents = [
    { label: 'メール送信中', value: 'processed' },
    { label: 'メール送信成功', value: 'delivered' },
    { label: 'メール送信失敗（一時的なエラー）', value: 'deffered' }, // Temporary rejected.
    { label: 'メール送信失敗（受信拒否）', value: 'bounce' }, // Server hadn't accepted.
    { label: 'メール送信失敗（その他）', value: 'dropped' }, // Failure sending.
  ];
  public action: 'create' | 'edit' | 'detail' = 'create';
  public columnToDisplayForMember = ['id', 'name', 'providerLINE', 'email', 'sendResult'];
  public columnToDisplayForAdmin = ['id', 'name', 'email', 'sendResult'];
  public columnToDisplayForDirectInput = ['email', 'sendResult'];
  public sendImmediately = false;
  public pending = false;
  // Admin status
  public servicePermissions: ServicePermissions | GroupPermissions;
  public adminList: AdminUser[] = [];
  public adminCache: Record<string, Observable<AdminUser>> = {};
  public memberCache: Record<string, Observable<Member>> = {};
  public mailActivityAdmins: AdminUser[] = [];
  public mailActivityMembers: Member[] = [];
  private groupList: Array<Group & { id: number }>;
  private mailList: Array<string>;
  private onDestroy$ = new Subject();
  private path = Path;
  public serviceAdmin = serviceAdmin;
  public groupManager = groupManager;
  public groupEditor = groupEditor;
  public defaultPermission = defaultPermissions;
  public role: Role;
  private isDuplicate: boolean;
  public isServiceAdmin = false;

  constructor(
    private afAuth: AngularFireAuth,
    private csvService: XLXSCSVService,
    private mailService: MailService,
    private aRoute: ActivatedRoute,
    private dialog: DialogService,
    private router: Router,
    private adminService: AdminService,
    private matchingService: MatchingService,
  ) { }

  ngOnInit() {
    const { uid } = this.afAuth.auth.currentUser;
    this.isDuplicate = false;
    this.combineFoems();
    this.aRoute.data.subscribe(data => {
      const permiss = data.servicePermissions;
      if (permiss.length > 0) {
        switch (permiss[0].role) {
          case 'serviceAdmin':
            this.servicePermissions = this.serviceAdmin;
            this.isServiceAdmin = true;
            break;
          case 'groupEditor':
            this.servicePermissions = this.groupEditor;
            this.isServiceAdmin = false;
            break;
          case 'groupAdmin':
            this.servicePermissions = this.groupManager;
            this.isServiceAdmin = false;
            break;
          default:
            this.servicePermissions = this.defaultPermission;
            this.isServiceAdmin = false;
            break;
        }
      } else {
        this.servicePermissions = this.defaultPermission;
        this.isServiceAdmin = false;
      }
      this.groupList = data.groups;

      if (this.isCreate && localStorage.getItem('duplicatedMailItem')) {
        this.isDuplicate = true;
        const request = JSON.parse(localStorage.getItem('duplicatedMailItem'));
        this.setMailRequestAndMailRequestItems(request);
        this.setMailActivitiesToSelectedList();
      } else {
        this.setMailRequestAndMailRequestItems(data.mailRequest);
      }
      if (!this.isCreate) {
        this.setMailActivitiesToSelectedList();
      }

      if (this.isDuplicate) {
        const duplicatedSubject = this.messageForm.get('subject').value;
        this.messageForm.patchValue({
          subject: duplicatedSubject + ' - コピー',
          sendSchedule: moment().add(1, 'h').toDate()
        });
      }
      this.isDuplicate = false;
      this.adminService.getAdminRole(uid).then(datas => {
        if (datas) {
          this.role = datas.role;
        } else {
          this.role = null;
        }
      });
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    localStorage.removeItem('duplicatedMailItem');
  }

  combineFoems() {
    this.mailRequestStateForm.addControl('message', this.messageForm);
  }

  get isCreate() {
    return this.router.url.startsWith(this.path.mail.create, 1);
  }

  get isEdit() {
    return !this.isCreate
      && this.messages.status === Message.StatusEnum.Requested
      || this.messages.status === Message.StatusEnum.Draft;
  }

  get isCompleted() {
    return !this.isCreate
      && this.messages.status === Message.StatusEnum.Completed;
  }

  get isCanceled() {
    return !this.isCreate
      && this.messages.status === Message.StatusEnum.Canceled;
  }

  get isDeleted() {
    return !this.isCreate
      && this.messages.status === Message.StatusEnum.Deleted;
  }

  get requestItemLength() {
    return this.selectedAdminMailActivityBases.length +
      this.selectedMemberMailActivityBases.length +
      this.selectedDirectInputMailActivityBases.length;
  }

  async currentLoginAdmin() {
    const { uid } = this.afAuth.auth.currentUser;
    const getCurrentAdmin = () => {
      return new Promise<AdminUser>((resolve, reject) => {
        this.matchingService.getAdminUser({ uid })
          .subscribe(doc => resolve(doc[0]), err => reject(err));
      });
    };
    return await getCurrentAdmin();
  }

  getDateValue(date: string) {
    return new Date(date);
  }

  setDateValue(ctrl: AbstractControl, event: Date) {
    return ctrl.setValue(event.toISOString());
  }

  setMailRequestAndMailRequestItems(data: any) {
    this.messages = data;
    this.mailActivities = this.messages ? this.messages.mail_activities : [];
    this.filteredMailActivities = [];
    this.messageForm.patchValue(this.messages || {});
    this.subscribeAdmins();
  }

  async setMailActivitiesToSelectedList() {
    const reqType = MailActivity.TypeEnum;
    await this.setAdditionalPropertiesToMailActivities();
    this.selectedDirectInputMailActivityBases = cloneDeep(_filter(this.mailActivities, ['type', reqType.DirectInput]));
    if (this.servicePermissions !== this.defaultPermission) {
      this.selectedAdminMailActivityBases = cloneDeep(_filter(this.mailActivities, ['type', reqType.Admin]));
      this.selectedMemberMailActivityBases = cloneDeep(_filter(this.mailActivities, ['type', reqType.Member]));
    } else {
      this.selectedAdminMailActivityBases = [];
      this.selectedMemberMailActivityBases = [];
    }
    if (!environment.production) {
      console.log('admin:', this.selectedAdminMailActivityBases);
      console.log('member:', this.selectedMemberMailActivityBases);
      console.log('direct:', this.selectedDirectInputMailActivityBases);
    }
  }

  async setAdditionalPropertiesToMailActivities() {
    const reqType = MailActivity.TypeEnum;
    const adminActivities = _filter(this.mailActivities, ['type', reqType.Admin]);
    const memberActivities = _filter(this.mailActivities, ['type', reqType.Member]);
    const adminUids = _map(adminActivities, activity => activity.uid);
    const memberUids = _map(memberActivities, activity => activity.uid);
    this.mailActivityAdmins = union(...await this.getAdminUsersForMailActivities(adminUids));
    this.mailActivityMembers = union(...await this.getMembersForMailActivities(memberUids));
    const filteredMailActivities = compact(_map(this.mailActivities, mailActivity => {
      switch (mailActivity.type) {
        case reqType.Admin:
          const admin = find(this.mailActivityAdmins, ['uid', mailActivity.uid]);
          if (admin) {
            mailActivity.adminId = admin.id;
            mailActivity.displayName = admin.displayName;
            return mailActivity;
          } else {
            return null;
          }
        case reqType.Member:
          const member = find(this.mailActivityMembers, ['uid', mailActivity.uid]);
          if (member) {
            mailActivity.memberId = member.id;
            mailActivity.displayName = member.displayName;
            mailActivity.fullName = member.fullName;
            mailActivity.image = member.image1;
            mailActivity.lineId = member.lineId;
            mailActivity.lineDisplayName = member.lineDisplayName;
            return mailActivity;
          } else {
            return null;
          }
        case reqType.DirectInput:
        default:
          return mailActivity;
      }
    }));
    this.mailActivities = filteredMailActivities;
    this.messageForm.get('mail_activities').patchValue(this.mailActivities);
  }

  async getAdminUsersForMailActivities(uids: any[]) {
    const uidChunk = chunk(uids, 50);
    const getAdmin = (uidList: any[]) => {
      return new Promise<AdminUser[]>((resolve, reject) => {
        this.matchingService.getAdminUser({ uid: uidList })
          .subscribe(admins => resolve(admins), err => reject(err));
      });
    };
    return Promise.all(_map(uidChunk, uidList => {
      return getAdmin(uidList);
    }));
  }

  async getMembersForMailActivities(uids: any[]) {
    const uidChunk = chunk(uids, 50);
    const getMember = (uidList: any[]) => {
      return new Promise<Member[]>((resolve, reject) => {
        this.matchingService.getMember({ uid: uidList })
          .subscribe(members => resolve(members), err => reject(err));
      });
    };
    return Promise.all(_map(uidChunk, uidList => {
      return getMember(uidList);
    }));
  }

  subscribeAdmins() {
    const getAdminPara = {
      _sort: 'id:ASC'
    } as any;
    this.matchingService.getAdminUser(getAdminPara)
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(admins => {
        this.adminList = admins;
        if (!this.isServiceAdmin) {
          const adminMails = this.adminList.map(admin => admin.email);
          const groups = this.groupList.map((gp: Group) => gp.id);
          this.matchingService.getMember({ groups_in: groups, _limit: -1 })
            .subscribe(members => {
              const memberMails = members.map(m => m.email1);
              let directMails: string[] = cloneDeep(_filter(this.mailActivities, ['type', MailActivity.TypeEnum.DirectInput]));
              directMails = directMails.map((direct: any) => direct.email);
              this.mailList = [...adminMails, ...memberMails, ...directMails];
              this.filterMailActivities();
            });
        } else {
          this.filterMailActivities();
        }
      }, err => console.error('error in get admin', err));
  }

  getFailureCount(mailRequestItems: MailActivity[], type: MailActivity.TypeEnum) {
    let failureCount = 0;
    const targetItems = _filter(mailRequestItems, ['type', type]);
    forEach(targetItems, item => {
      const targetResult = find(this.mailActivities, ['trackingId', item.trackingId]);
      const event = get(targetResult, 'result');
      switch (event) {
        case 'dropped':
        case 'bounce':
        case 'deffered':
          failureCount++;
          break;
        default:
          break;
      }
    });
    return failureCount;
  }

  openMemberSearchDialog() {
    const dialogRef = this.dialog.openComponentDialog(UserSearchDialogComponent, {
      height: '90vh',
      data: {
        buttonMessage: 'メンバーを選択する',
        selectAll: true,
        _limit: -1
      }
    });
    const component: UserSearchDialogComponent = dialogRef.componentInstance;
    component.additinalSearchForm = new FormGroup({
      entryStatus: new FormControl('NULL')
    });

    dialogRef.afterClosed()
      .pipe(filter(result => Array.isArray(result)))
      .subscribe((members: any[]) => {
        const reqType = MailActivity.TypeEnum;
        const prev = this.selectedMemberMailActivityBases.map(list => {
          return {
            uid: list.uid,
            email: list.email,
            memberId: list.id,
            displayName: list.displayName,
            fullName: list.fullName,
            image: list.image1
          };
        });
        const current = members.map(list => {
          return {
            uid: list.uid,
            email: list.email1,
            memberId: list.id,
            displayName: list.displayName,
            fullName: list.fullName,
            image: list.image1
          };
        });
        // removed
        difference(current, prev).forEach(list => {
          this.AddToSelection(
            list.uid,
            list.email,
            reqType.Member,
            list.memberId,
            list.displayName,
            list.fullName,
            list.image);
        });
      });
  }

  openAdminListDialog() {
    const cached = cloneDeep(this.selectedAdminMailActivityBases);
    const dialogRef = this.dialog.openTemplateDialog(this.adminDialog, {
      height: '90vh',
      data: {
        adminList: this.adminList,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe(result => {
        // Discard changes
        if (!result) {
          differenceBy(this.selectedAdminMailActivityBases, cached, 'uid')
            .forEach(admin => this.removeFromSelection(admin.uid, this.mailRequestItemType.Admin));
        }
        // silent
        console.log(result);
      });
  }

  async registerMailRequest(status: Message.StatusEnum) {
    const data: LodingDialogData = { text: '' };
    const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });
    const actionText = this.isCreate ? '登録' : '更新';
    const statusText = status === Message.StatusEnum.Draft ? '下書き' : '送信情報';
    this.pending = true;
    try {
      data.text = `メールの${statusText}を${actionText}しています...`;
      if (this.isCreate) {
        await this.createMailRequest(status);
        await this.sleep(1000);
      } else {
        await this.updateMailRequest(status);
        await this.sleep(1000);
      }

      data.text = `メールの${statusText}を${actionText}しました。`;
      data.hiddenBar = true;
      await this.sleep(1500);

      dialog.close();
      this.router.navigate([this.path.mail.list]);
    } catch (err) {
      console.error(err);
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      if (err instanceof MailRequestError ||
        err.code === SendScheduleErrorCode) {
        return;
      }
      alert(`メールの${statusText}を${actionText}できませんでした。`);
    } finally {
      localStorage.removeItem('duplicatedMailItem');
      this.pending = false;
    }
  }

  sleep(time = 1000) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  }

  async createMailRequest(status: Message.StatusEnum) {
    const requestMailActivities: MailActivity[] = [];
    const loginAdmin = await this.currentLoginAdmin();
    const sendSchedule = this.sendImmediately ?
      moment(new Date()).add(1, 'm').toDate() :
      this.messageForm.get('sendSchedule').value;
    const groups = this.servicePermissions.administrate ? [] : _map(this.groupList, group => group.id);
    if (status === Message.StatusEnum.Requested && !this.validateSendSchedule()) {
      this.dialog.openConfirmDialog({
        data: {
          text: '配信日時が現在日時から5分以内か、過去に設定されています。配信日時は現在日時から5分以上未来に設定してください。',
          cancel: false,
          applyText: '確認'
        }
      });
      throw new MailRequestError('Invalid schedules.', { code: SendScheduleErrorCode });
    }
    this.messageForm.patchValue({
      sendSchedule,
      status,
      groups,
      fromName: loginAdmin.displayName,
      fromEmail: loginAdmin.email,
    });
    requestMailActivities.push(
      ...this.selectedMemberMailActivityBases,
      ...this.selectedAdminMailActivityBases,
      ...this.selectedDirectInputMailActivityBases,
    );
    const schedule = this.messageForm.get('sendSchedule') ?
      new Date(this.messageForm.get('sendSchedule').value).toISOString() : null;
    const sentat = this.messageForm.get('sentAt') ?
      new Date(this.messageForm.get('sentAt').value).toISOString() : null;
    const groupIds = this.isServiceAdmin ? [] : _map(this.groupList, group => group.id);
    this.messageForm.patchValue({
      sendSchedule: schedule,
      sentAt: sentat,
      mail_activities: [],
      groups: groupIds
    });
    this.matchingService.createMessage(this.messageForm.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(messageDoc => {
        if (messageDoc) {
          for (const items of chunk(requestMailActivities, 20)) {
            items.forEach(item => {
              const activityBody = {
                email: item.email,
                trackingId: item.trackingId,
                type: item.type,
                uid: String(item.uid),
                result: null,
                timestamp: null,
                message: messageDoc.id,
              };
              this.matchingService.createMailActivity(activityBody).subscribe(
                doc => {
                  if (doc) {
                    return true;
                  }
                  return false;
                }, err => console.error(err)
              );
            });
          }
        }
      }, err => console.error(err));
  }

  async updateMailRequest(status: Message.StatusEnum) {
    const { docId } = this.aRoute.snapshot.params;
    const sendSchedule = this.sendImmediately ?
      moment(new Date()).add(1, 'm').toDate() :
      this.messageForm.get('sendSchedule').value;
    const groups = this.isServiceAdmin ? [] : _map(this.groupList, group => group.id);
    if (status === Message.StatusEnum.Requested && !this.validateSendSchedule()) {
      this.dialog.openConfirmDialog({
        data: {
          text: '配信日時が現在日時から5分以内か、過去に設定されています。配信日時は現在日時から5分以上未来に設定してください。',
          cancel: false,
          applyText: '確認'
        }
      });
      throw new MailRequestError('Invalid schedules.', { code: SendScheduleErrorCode });
    }
    const schedule = sendSchedule ?
      new Date(sendSchedule).toISOString() : null;
    this.messageForm.patchValue({
      sendSchedule: schedule,
      status,
      groups,
    });

    this.matchingService.updateMessage(docId, this.messageForm.value)
      .subscribe(editDoc => {
        // Get changes.
        // unchangedは使ってないのでCOしておく
        const adminItems = this.mailActivities.filter(item => item.type === MailActivity.TypeEnum.Admin);
        const memberItems = this.mailActivities.filter(item => item.type === MailActivity.TypeEnum.Member);
        const directInputItems = this.mailActivities.filter(item => item.type === MailActivity.TypeEnum.DirectInput);
        const addedAdmins = differenceBy(this.selectedAdminMailActivityBases, adminItems, 'id');
        const removedAdmins = differenceBy(adminItems, this.selectedAdminMailActivityBases, 'id');
        // const unchangedAdmins = intersectionBy(adminItems, this.selectedAdminList, '_id');
        const addedMembers = differenceBy(this.selectedMemberMailActivityBases, memberItems, 'id');
        const removedMembers = differenceBy(memberItems, this.selectedMemberMailActivityBases, 'id');
        // const unchangedMembers = intersectionBy(memberItems, this.selectedMemberList, '_id');
        const addedDirects = differenceBy(this.selectedDirectInputMailActivityBases, directInputItems, 'id');
        const removedDirects = differenceBy(directInputItems, this.selectedDirectInputMailActivityBases, 'id');
        // const unchangedDirects = intersectionBy(directInputItems, this.selectedDirectInputList, '_id');
        const added = [...addedAdmins, ...addedMembers, ...addedDirects];
        const removed = [...removedAdmins, ...removedMembers, ...removedDirects];
        // const unchanged = [...unchangedAdmins, ...unchangedMembers, ...unchangedDirects];

        for (const items of chunk(added, 20)) {
          items.forEach(item => {
            item.message = docId;
            this.matchingService.createMailActivity(item)
              .subscribe(doc => {
                if (doc) { return true; }
                return false;
              }, err => console.error(err));
          });
        }

        for (const items of chunk(removed, 20)) {
          items.forEach(item => {
            this.matchingService.deleteMailActivity(Number(item.id))
              .subscribe(doc => {
                if (doc) { return true; }
                return false;
              }, err => console.error(err));
          });
        }
      }, err => console.error(err));
  }

  convertToHtml(message: string) {
    message = replace(message, /\r?\n/g, '<br />');
    return `<!DOCTYPE html>
  <html lang="ja">
    <body>${message}</body>
  </html>`;
  }

  validateSendSchedule() {
    if (this.sendImmediately) {
      return true;
    }
    const sendSchedule = moment(this.messageForm.get('sendSchedule').value);
    const now = moment(new Date());
    return sendSchedule.subtract(5, 'm').isAfter(now);
  }

  removeFromSelection(uidOrEmail: string, type: MailActivity.TypeEnum) {
    const reqType = MailActivity.TypeEnum;
    let list: any[];
    let field: string;
    switch (type) {
      case reqType.Member:
        field = 'uid';
        list = this.selectedMemberMailActivityBases;
        break;
      case reqType.Admin:
        field = 'uid';
        list = this.selectedAdminMailActivityBases;
        break;
      case reqType.DirectInput:
        field = 'email';
        list = this.selectedDirectInputMailActivityBases;
        break;
    }
    const index = findIndex(list, [field, String(uidOrEmail)]);
    if (index !== -1) {
      list.splice(index, 1);
    }
    if (!environment.production) {
      console.log('admin:', this.selectedAdminMailActivityBases);
      console.log('member:', this.selectedMemberMailActivityBases);
      console.log('direct:', this.selectedDirectInputMailActivityBases);
    }
  }

  AddToSelection(
    uid: any,
    email: string,
    type: MailActivity.TypeEnum,
    id?: number,
    displayName?: string,
    fullName?: string,
    image?: object,
  ) {
    const { docId } = this.aRoute.snapshot.params;
    const reqType = MailActivity.TypeEnum;
    const data: any = {
      email: null,
      trackingId: null,
      type,
      uid: null,
      result: null,
      timestamp: null,
      message: null,
      id,
      displayName,
      fullName,
      image,
      memberId: null,
      adminId: null
    };
    if (!this.isCreate) {
      data.message = Number(docId);
    }
    let list: any[];
    let field: string;
    switch (type) {
      case reqType.Member:
        field = 'uid';
        list = this.selectedMemberMailActivityBases;
        data.uid = uid;
        data.email = email;
        data.memberId = id;
        break;
      case reqType.Admin:
        field = 'uid';
        list = this.selectedAdminMailActivityBases;
        data.uid = uid;
        data.email = email;
        data.adminId = id;
        break;
      case reqType.DirectInput:
        field = 'email';
        list = this.selectedDirectInputMailActivityBases;
        data.uid = null;
        data.email = email;
        break;
    }
    const exists = find(list, [field, uid]);
    if (!exists) {
      list.push(data);
    }
    this.directInputForm.reset();
    // 全選択でものすごいログが出てしまうので開発時も必要な時以外はコメントアウトしておく
    // if (!environment.production) {
    //   console.log('admin:', this.selectedAdminMailActivityBases);
    //   console.log('member:', this.selectedMemberMailActivityBases);
    //   console.log('direct:', this.selectedDirectInputMailActivityBases);
    // }
  }

  getSendEvent(request: MailActivity) {
    if (request) {
      return !request.result ? null : find(this.resultEvents, ['value', request.result]);
    }
    return null;
  }

  filterMailActivities() {
    let filteredMailActivities = [];
    if (this.isServiceAdmin) {
      filteredMailActivities = [...this.mailActivities];
    } else {
      this.mailActivities.forEach(item => {
        if (this.mailList.indexOf(item.email) >= 0) {
          filteredMailActivities.push(item);
        }
      });
    }
    const { type, sendResult } = this.mailResultSearchForm.value;
    if (type && sendResult) {
      const filteredItems = _filter(filteredMailActivities, ['result', sendResult]);
      this.filteredMailActivities = _filter(filteredItems, ['type', type]);
      return;
    }
    if (type) {
      this.filteredMailActivities = _filter(filteredMailActivities, ['type', type]);
      return;
    }
    if (sendResult) {
      this.filteredMailActivities = _filter(filteredMailActivities, ['result', sendResult]);
      return;
    }
    this.filteredMailActivities = filteredMailActivities;
  }

  async downloadMailResultCSV() {
    const dialog = this.dialog.openLoadingDialog({
      data: { text: 'CSVを作成しています...' },
      disableClose: true,
    });
    // Define headers
    const headers = this.mailService.getHeaders();
    // JSON、ヘッダーを渡してメンバー一覧のCSVを作れる形式のJSONに整形する
    const formattedJson = await this.mailService.formatJSONForMailCsv(
      this.filteredMailActivities,
      this.mailActivities,
      headers,
      this.mailActivityAdmins,
      this.mailActivityMembers);
    const fileName = `配信先一覧_${moment().format('YYYY-MM-DD')}.csv`;
    this.csvService.downloadCSV(formattedJson, fileName);
    dialog.close();

  }

  log(value) {
    console.log(value);
  }
}
