import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminUser } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class ServicePermissionsResolver implements Resolve<AdminUser[]> {
  constructor(
    private afAuth: AngularFireAuth,
    private apiService: MatchingService,
  ) { }
  resolve() {
    const { uid } = this.afAuth.auth.currentUser;
    return this.apiService.getAdminUser({ uid });
  }
}

