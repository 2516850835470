import {
  AfterViewInit,
  Directive,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  fromEvent,
  merge,
} from 'rxjs';
import {
  map,
} from 'rxjs/operators';

@Directive({
  selector: '[luDroppable]'
})
export class DroppableDirective implements AfterViewInit {
  @Output() dragLeave = new EventEmitter<DragEvent>();
  @Output() dragOver = new EventEmitter<DragEvent>();
  @Output() droped = new EventEmitter<DragEvent>();
  @Output() fileDroped = new EventEmitter<FileList>();
  private dropArea: HTMLElement;

  constructor(
    private elementRef: ElementRef,
  ) {}

  public ngAfterViewInit(): void {
    this.dropArea = this.elementRef.nativeElement;
    this.initializeEvents();
  }

  initializeEvents() {
    let eventCounter = 0;
    const dropEvent = fromEvent(this.dropArea, 'drop')
      .pipe(
        map((event: DragEvent) => {
          eventCounter = 0;
          this.dragLeave.emit(event);
          this.droped.emit(event);
          if (event.dataTransfer.files.length > 0) {
            this.fileDroped.emit(event.dataTransfer.files);
          }
          return {event, type: 'drop'};
        })
      );
    const enterEvent = fromEvent(this.dropArea, 'dragenter')
      .pipe(
        map((event: DragEvent) => {
          if (eventCounter === 0) {
            this.dragOver.emit(event);
          }
          eventCounter++;
          return {event, type: 'dragenter'};
        })
      );
    const leaveEvent = fromEvent(this.dropArea, 'dragleave')
      .pipe(
        map((event: DragEvent) => {
          eventCounter--;
          if (eventCounter === 0) {
            this.dragLeave.emit(event);
          }
          return {event, type: 'dragleave'};
        })
      );
    const overEvent = fromEvent(this.dropArea, 'dragover')
      .pipe(
        map((event: DragEvent) => {
          return {event, type: 'dragover'};
        })
      );

    merge(enterEvent, leaveEvent, overEvent, dropEvent)
      .subscribe(obj => {
        obj.event.preventDefault();
        obj.event.stopPropagation();
        obj.event.dataTransfer.dropEffect = 'copy';
      });
  }
}
