import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { VirutualScrollStrategy } from './virutual-scroll-strategy';
import { DialogService } from '@lu/services/dialog.service';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { Path } from '@lu/path';
import {
  ServicePermissions,
  GroupPermissions,
  Group,
  Message,
  MailActivity,
  AdminUser,
} from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';
import { defaultPermissions, groupEditor, groupManager, serviceAdmin } from '@lu/definitions/role';

@Component({
  selector: 'lu-mail-list',
  templateUrl: './mail-list.component.html',
  styleUrls: ['./mail-list.component.scss'],
  providers: [VirutualScrollStrategy]
})
export class MailListComponent implements OnInit, OnDestroy {
  public searchForm = new FormGroup({
    author: new FormControl(null),
    term: new FormGroup({
      startAt: new FormControl(null),
      endAt: new FormControl(null),
    }),
    keyword: new FormControl(null),
  });
  public servicePermissions: ServicePermissions | GroupPermissions;
  public groupList: Group[];
  public adminList: any[];
  public readonly columnToDisplay = ['requestNumber', 'status', 'subject', 'schedule', 'author', 'actions'];
  public readonly path = Path;
  public readonly requestStatus = Message.StatusEnum;
  public pending = false;
  private onDestroy$ = new Subject();
  public serviceAdmin = serviceAdmin;
  public groupManager = groupManager;
  public groupEditor = groupEditor;
  public defaultPermission = defaultPermissions;
  public pageSize = 20;
  public isServiceAdmin = false;

  constructor(
    public dataSource: VirutualScrollStrategy,
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private dialog: DialogService,
    private matchingService: MatchingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.aRoute.data.subscribe((data: {
      servicePermissions: Array<AdminUser>,
      groups: Array<Group>
    }) => {
      const permiss = data.servicePermissions;
      if (permiss.length > 0) {
        switch (permiss[0].role) {
          case AdminUser.RoleEnum.ServiceAdmin:
            this.servicePermissions = this.serviceAdmin;
            this.isServiceAdmin = true;
            break;
          case AdminUser.RoleEnum.GroupEditor:
            this.servicePermissions = this.groupEditor;
            this.isServiceAdmin = false;
            break;
          case AdminUser.RoleEnum.GroupAdmin:
            this.servicePermissions = this.groupManager;
            this.isServiceAdmin = false;
            break;
          default:
            this.servicePermissions = this.defaultPermission;
            this.isServiceAdmin = false;
            break;
        }
      } else {
        this.servicePermissions = this.defaultPermission;
        this.isServiceAdmin = false;
      }
      this.groupList = data.groups;
      this.subscribeAdmins();
      this.search();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  get uid() {
    return this.afAuth.auth.currentUser.uid;
  }

  subscribeAdmins() {
    if (this.servicePermissions) {
      const getAdminPara = {
        _sort: 'id:ASC'
      } as any;
      this.matchingService.getAdminUser(getAdminPara)
        .pipe(
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          doc => {
            this.adminList = doc;
          }, err => console.error('error in get admin', err)
        );
    }
  }

  search() {
    const query = this.generateQuery();
    this.dataSource.search(query);
  }

  generateQuery() {
    let query: any = {};
    const { author, term, keyword }: {
      author: string,
      term: { startAt: Date | null, endAt: Date | null },
      keyword: string
    } = this.searchForm.value;
    const startDate = term.startAt !== null ? (term.startAt).toISOString() : null;
    let endDate = null;
    if (term.endAt !== null) {
      endDate = moment(term.endAt).add(1, 'days').toISOString();
    }
    query = {
      fromEmail_in: author,
      sendSchedule_gte: startDate,
      sendSchedule_lt: endDate,
      subject_containss: keyword,
      status_ne: Message.StatusEnum.Deleted,
      _sort: 'sendSchedule:DESC',
      _start: 0,
      _limit: this.pageSize
    };
    if (!this.isServiceAdmin) {
      query.groups_in = this.groupList.map(gp => gp.id);
    }
    return query;
  }

  canReturnToDraft(request: Message) {
    const sendSchedule = moment(request.sendSchedule);
    const now = moment(new Date());
    return sendSchedule.subtract(5, 'm').isAfter(now)
      && request.status === Message.StatusEnum.Requested;
  }

  async returnToDraftMailRequest(request: Message) {
    const data: LodingDialogData = {
      text: '予約メールの送信リクエストを下書き状態に戻しています...',
      color: 'warn'
    };
    const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });

    try {
      this.pending = true;

      const mailRequestRef = () => {
        return new Promise((resolve, reject) => {
          this.matchingService.updateMessage(request.id, { status: Message.StatusEnum.Draft })
            .subscribe(
              doc => {
                resolve(doc);
              }, err => reject(err)
            );
        });
      };

      await mailRequestRef();

      await this.sleep(1000);
      data.text = '予約メールの送信リクエストを下書き状態に戻しました。';
      data.hiddenBar = true;
      await this.sleep(1500);

      dialog.close();
      location.reload();
    } catch (err) {
      console.error(err);
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      alert('予約メールの送信リクエストを下書き状態に戻せませんでした。');
    } finally {
      this.pending = false;
    }
  }

  duplicateEmailTemplate(request: Message) {
    const filteredRequest = {...request};
    filteredRequest.mail_activities.forEach((mailActivities: MailActivity) => {
      mailActivities.trackingId = null;
      mailActivities.result = null;
      mailActivities.timestamp = null;
    })
    localStorage.setItem('duplicatedMailItem', JSON.stringify(filteredRequest));
    this.router.navigate([`/${this.path.mail.create}`]);
  }

  canDelete(request: Message) {
    const sendSchedule = moment(request.sendSchedule);
    const now = moment(new Date());
    return sendSchedule.subtract(5, 'm').isAfter(now)
      && (request.status === Message.StatusEnum.Draft
        || request.status === Message.StatusEnum.Requested);
  }

  async deleteMailRequest(request: Message) {
    const confirmed: boolean = await new Promise(resolve => {
      this.dialog.openConfirmDialog({
        data: {
          text: 'この予約メールの送信リクエストを一覧から削除します。よろしいですか？',
          applyText: '削除する',
          cancelText: '戻る',
          applyButtonColor: 'warn',
          cancelButtonColor: 'primary',
        },
        panelClass: ['inverted-button-color-dialog'],
      }).afterClosed()
        .subscribe(result => resolve(!!result));
    });

    if (!confirmed) {
      return;
    }
    const data: LodingDialogData = {
      text: '予約メールの送信リクエストを一覧から削除しています...',
      color: 'warn'
    };
    const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });

    try {
      this.pending = true;

      const mailRequestRef = () => {
        return new Promise((resolve, reject) => {
          this.matchingService.updateMessage(request.id, { status: Message.StatusEnum.Deleted })
            .subscribe(
              doc => {
                resolve(doc);
              }, err => reject(err)
            );
        });
      };

      await mailRequestRef();

      await this.sleep(1000);
      data.text = '予約メールの送信リクエストを一覧から削除しました。';
      data.hiddenBar = true;
      await this.sleep(1500);

      dialog.close();
      location.reload();
    } catch (err) {
      console.error(err);
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      alert('予約メールの送信リクエストを一覧から削除できませんでした。');
    } finally {
      this.pending = false;
    }
  }

  sleep(time = 1000) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  }

}
