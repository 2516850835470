import { Injectable } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep, isNil } from 'lodash';

import {
  Project
} from '@lu/models';

import { MatchingService } from '@lu/services/matching.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from '@lu/environment';

@Injectable()
export class EsOrderVirutualScrollStrategy extends DataSource<Project> {
  private dataStream = new BehaviorSubject<(Project)[]>([]);
  public dataStream$ = this.dataStream.asObservable();
  private fetchedPages = new Set<number>();
  private subscription = new Subscription();
  private cachedList: Project[] = [];
  private queryTotal: number;
  private pageSize = 25;
  private hasNext = true;
  private pending = false;
  private query = {} as any;
  private periodQuery = {} as any;
  private listLength: number;

  constructor(
    private apiService: MatchingService
  ) {
    super();
  }

  // reference https://material.angular.io/cdk/scrolling/overview#creating-items-in-the-viewport
  connect(collectionViewer: CollectionViewer) {
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this.getPageForIndex(range.start);
      const endPage = this.getPageForIndex(range.end - 1);
      for (let i = startPage; i <= endPage; i++) {
        this.fetchPage(i);
      }
    }));
    return this.dataStream;
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);
    this.searchNextOrders();
  }

  public get theEnd(): boolean {
    return !this.hasNext;
  }

  public get isPending(): boolean {
    return this.pending;
  }

  public get dataLength(): number {
    return this.listLength ? this.listLength : 0;
  }

  public get hitLength(): number {
    return this.queryTotal;
  }

  public filterPeriod(range: { start: Date, end: Date }, orderItem: any) {
    const list = [];
    if (range.start && range.end) {
      const rangeStart = range.start;
      const rangeEnd = range.end;
      _.forEach(orderItem, (order: any) => {
        const eventStart = new Date(order.eventStartAt);
        const eventEnd = new Date(order.eventEndAt);

        const isStartBetween = moment(rangeStart).isBetween(eventStart, eventEnd);
        const isEndBetween = moment(rangeEnd).isBetween(eventStart, eventEnd);

        const isStartBefore = moment(rangeStart).isBefore(eventStart);
        const isEndAfter = moment(rangeEnd).isAfter(eventEnd);

        if ((isStartBetween || isEndBetween) || (isStartBefore && isEndAfter)) {
          list.push(order);
        }
      });
    } else if (range.start && isNil(range.end)) {
      const rangeStart = range.start;
      _.forEach(orderItem, (order: any) => {
        const eventStart = new Date(order.eventStartAt);
        const eventEnd = new Date(order.eventEndAt);

        const isStartBetween = moment(rangeStart).isBetween(eventStart, eventEnd);
        // Project end after range start
        const isStartBefore = moment(rangeStart).isBefore(eventEnd);

        if (isStartBetween || isStartBefore) {
          list.push(order);
        }
      });
    } else if (range.end && isNil(range.start)) {
      const rangeEnd = range.end;
      _.forEach(orderItem, (order: any) => {
        const eventEnd = new Date(order.eventEndAt);
        const eventStart = new Date(order.eventStartAt);

        const isEndBetween = moment(rangeEnd).isBetween(eventStart, eventEnd);
        // Project end after range end
        const isEndAfter = moment(rangeEnd).isAfter(eventEnd);

        if (isEndBetween || isEndAfter) {
          list.push(order);
        }
      });
    } else { }
    return list;
  }

  /** With refresh order list. */
  searchOrders(query: any, period: any) {
    this.pending = true;
    this.hasNext = true;
    this.queryTotal = null;
    this.cachedList = [];
    this.query = cloneDeep(query);
    this.periodQuery = cloneDeep(period);
    const range = {
      start: null,
      end: null
    };
    if (this.periodQuery.eventStartAt || this.periodQuery.eventEndAt) {
      range.start = this.periodQuery.eventStartAt ? this.periodQuery.eventStartAt : null;
      range.end = this.periodQuery.eventEndAt ? this.periodQuery.eventEndAt : null;
    }
    this.dataStream.next([]);
    this.apiService.getProject(this.query).subscribe(orderItem => {
      let list = [];
      if (range.start || range.end) {
        list = this.filterPeriod(range, orderItem);
        this.cachedList = list;
        this.queryTotal = list.length;
      } else {
        this.cachedList = orderItem;
        this.queryTotal = orderItem.length;
      }
      this.dataStream.next(this.cachedList);
      this.pending = false;
      this.hasNext = false;
      if (!environment.production) {
        console.log('total:', this.queryTotal);
        console.log('cachedListSize:', this.cachedList.length);
        console.log('searchProjectQuery:', this.query);
      }
    }, error => {
      console.error('Something was wrong in dataStream ===> ', error);
      this.pending = false;
    });
  }

  /** Append results to order list */
  public searchNextOrders() {
    if (!this.query
      || !this.hasNext
      || this.pending) {
      return;
    }
    this.pending = true;
    this.query._start = this.listLength;
    const range = {
      start: null,
      end: null
    };
    if (this.periodQuery.eventStartAt || this.periodQuery.eventEndAt) {
      range.start = this.periodQuery.eventStartAt ? this.periodQuery.eventStartAt : null;
      range.end = this.periodQuery.eventEndAt ? this.periodQuery.eventEndAt : null;
    }
    this.apiService.getProject(this.query).subscribe(
      orderItem => {
        this.listLength += orderItem.length;
        let currentLength = null;
        this.pending = false;
        let list = [];
        if (range.start || range.end) {
          list = this.filterPeriod(range, orderItem);
          currentLength = list.length;
          this.cachedList = this.cachedList.concat(list);
        } else {
          this.cachedList = this.cachedList.concat(orderItem);
        }
        this.dataStream.next(this.cachedList);
        if (currentLength && currentLength <= 0) {
          this.hasNext = false;
          this.listLength = 0;
        } else if (this.pageSize === orderItem.length) {
          this.hasNext = true;
        } else if (orderItem.length === 0) {
          this.hasNext = false;
        } else {
          this.hasNext = false;
        }
      }, error => {
        console.error('Something was wrong in dataStream next ===> ', error);
        this.pending = false;
      }
    );
  }
}
