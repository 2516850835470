import { Injectable } from '@angular/core';
import { UserSearchCondition } from '@lu/components/user-search/user-search.component';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSearchService {
  public userSearch: UserSearchCondition;
  public userSearchSubject = new Subject<UserSearchCondition>();
  public userSearch$: Observable<UserSearchCondition> = this.userSearchSubject.asObservable();

  constructor() {
    this.userSearchSubject.subscribe((result: UserSearchCondition) => {
      this.userSearch = result;
    });
  }

  get getSearchedData() {
    return this.userSearch;
  }
}
