/**
 * DOCUMENTATION
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewMember {
    uid?: string;
    order: number;
    foreignKey?: string;
    fullName: string;
    fullNameKana: string;
    displayName?: string;
    summary?: string;
    profile?: string;
    catchphrase?: string;
    lineId?: string;
    groups?: Array<string>;
    tag_masters?: Array<string>;
    member_status_master?: string;
    occupation_master?: string;
    industry_master?: string;
    job_master?: string;
    gender?: NewMember.GenderEnum;
    birthDay?: Date;
    uncertainAge: boolean;
    married?: boolean;
    hasChildren?: boolean;
    children?: Array<string>;
    height?: number;
    weight?: number;
    email1: string;
    email2?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    mainSiteURL?: string;
    mainSiteName?: string;
    otherSiteURL1?: string;
    otherSiteName1?: string;
    otherSiteURL2?: string;
    otherSiteName2?: string;
    otherSiteURL3?: string;
    otherSiteName3?: string;
    registeredDate?: Date;
    publishStartAt?: Date;
    leaved: boolean;
    leavedAt?: Date;
    projects?: Array<string>;
    entries?: Array<string>;
    member_confidential?: string;
    address?: string;
    bank_account?: string;
    payments?: Array<string>;
    reports?: Array<string>;
    connection_tik_tok?: string;
    connection_twitter?: string;
    connection_youtube?: string;
    connection_instagrams?: Array<string>;
    candidates?: Array<string>;
    notifications?: Array<string>;
    lineDisplayName?: string;
    createdBy?: string;
    updatedBy?: string;
}
// tslint:disable-next-line: no-namespace
export namespace NewMember {
    export type GenderEnum = 'male' | 'female' | 'unanswered' | 'notKnown';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum,
        Unanswered: 'unanswered' as GenderEnum,
        NotKnown: 'notKnown' as GenderEnum
    };
}


