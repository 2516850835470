import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { take, catchError } from 'rxjs/operators';
import { Member } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class MemberResolver implements Resolve<Member> {
 constructor(
    private matchservice: MatchingService
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {uid} = route.params;
    return this.matchservice.getEachMember(uid, '')
      .pipe(
        take(1),
        catchError(err => {
          console.error(err);
          throw err;
        })
      );
  }
}
