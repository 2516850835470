import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { roles } from '@lu/definitions/role';
import { MatchingService } from './matching.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private apiService: MatchingService
  ) { }

  async getAdminRole(uid: string) {
    const getMemberRole = () => {
      return new Promise<string>((resolve, reject) => {
        this.apiService.getAdminUser({ uid })
          .subscribe(
            admin => {
              admin.length > 0 ? resolve(admin[0].role) : resolve(null);
            }, err => { console.error(err); reject(err); }
          );
      });
    };
    const memberRole = await getMemberRole();
    if (memberRole) {
      switch (memberRole) {
        case 'serviceAdmin':
          return { role: roles[0] };
        case 'groupAdmin':
          return { role: roles[1] };
        default:
          return { role: roles[2] };
      }
    }
  }
}
