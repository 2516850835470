import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { firestore } from 'firebase/app';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

import { Path } from '@lu/path';
import { Role, roles, groupManager, serviceAdmin, groupEditor } from '@lu/definitions/role';
import { DialogService } from '@lu/services/dialog.service';
import { NewAdminUser, AdminUser, Group, GroupPermissions, ServicePermissions } from '@lu/models';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';

import { MatchingService } from '@lu/services/matching.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'lu-admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.scss']
})
export class AdminUserDetailComponent implements OnInit {
  private uid: string;
  public userServicePermissions: ServicePermissions | GroupPermissions;
  public admin: AdminUser;
  public adminServicePermissions: ServicePermissions;
  public groupList: Array<Group>;
  public adminUserForm = new FormGroup({
    uid: new FormControl(null),
    order: new FormControl(0),
    displayName: new FormControl(null, Validators.required),
    email: new FormControl(null, [
      Validators.email,
      Validators.required,
      Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)]),
    groups: new FormControl(null),
    role: new FormControl(null),
    summary: new FormControl(null, Validators.maxLength(2000)),
    leaved: new FormControl(false),
  } as { [K in keyof NewAdminUser]: AbstractControl });
  public adminRoleForm = new FormGroup({
    role: new FormControl({
      roleName: null,
      permission: {
        service: null,
        group: null
      },
    } as Role, Validators.required),
  });
  public roleList = roles;
  private readonly path = Path;
  public action: 'create' | 'update' = 'create';
  public pending = false;
  public loginUser: AdminUser;
  public isServiceAdmin = false;

  public roleName: AdminUser.RoleEnum;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private dialog: DialogService,
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    this.uid = this.aRoute.snapshot.params.uid;
    if (!this.router.url.startsWith(this.path.admin.create, 1)) {
      this.action = 'update';
    }
    this.aRoute.data
      .pipe(filter(data => {
        if (this.isUpdate && _.isNil(this.uid)) {
          alert('データを取得できませんでした。一覧画面に戻ります。');
          this.router.navigate([this.path.admin.list]);
          return false;
        }
        return true;
      }))
      .subscribe((data: {
        servicePermissions: Array<AdminUser>,
        groups: Array<Group>,
        admin: AdminUser
      }) => {
        if (data.servicePermissions[0].role === 'serviceAdmin') {
          this.userServicePermissions = serviceAdmin;
          this.isServiceAdmin = true;
        } else if (data.servicePermissions[0].role === 'groupAdmin') {
          this.userServicePermissions = groupManager;
          this.isServiceAdmin = false;
        } else {
          this.userServicePermissions = groupEditor;
          this.isServiceAdmin = false;
        }
        this.groupList = data.groups;
        if (this.isUpdate) {
          this.admin = data.admin;
          this.setDocumentsToForms();
        }
      });
  }

  get isCreate() {
    return this.action === 'create';
  }
  get isUpdate() {
    return this.action === 'update';
  }

  isChecked(id: string) {
    return this.adminUserForm.value.groups.find((list: Group) => list.id === id);
  }

  hasGroup(guid: string) {
    const groups: string[] = this.adminUserForm.value.groups;
    if (guid === null) {
      return groups === null ? true : false;
    }
    return groups === null ? false : groups.includes(guid);
  }

  findIndex(groups, guid) {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === guid) {
        return i;
      }
    }
    return -1;
  }

  groupChanges(event: MatCheckboxChange) {
    const { checked } = event;
    const guid = event.source.value;
    const groupsCtrl = this.adminUserForm.get('groups');
    const groups: any[] = groupsCtrl.value || [];
    const groupPos = this.findIndex(groups, guid);
    // All Groups
    if (guid === null && checked) {
      groupsCtrl.setValue(null);
      return;
    }
    // Add or remove from group.
    if (checked) {
      groups.push(guid);
      groupsCtrl.setValue(_.union(groups));
    } else if (groupPos >= 0) {
      groups.splice(groupPos, 1);
      groupsCtrl.setValue(groups);
    }
  }

  async setDocumentsToForms() {
    this.adminUserForm.patchValue(_.cloneDeep(this.admin));
    this.roleName = this.admin.role;
    if (this.roleName === AdminUser.RoleEnum.ServiceAdmin) {
      this.adminRoleForm.patchValue({ role: this.roleList[0] });
    } else if (this.roleName === AdminUser.RoleEnum.GroupAdmin) {
      this.adminRoleForm.patchValue({ role: this.roleList[1] });
    } else {
      this.adminRoleForm.patchValue({ role: this.roleList[2] });
    }
  }

  async registerAdmin() {
    const data: LodingDialogData = { text: '' };
    const dialog = this.dialog.openLoadingDialog({
      data,
      disableClose: true
    });

    this.pending = true;
    try {
      data.text = `管理者を${this.isCreate ? '作成' : '更新'}しています...`;
      if (this.isCreate) {
        await this.createAdmin();
        await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1000));
      } else {
        await this.updateAdmin();
        await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1000));
      }

      data.text = `管理者の${this.isCreate ? '作成' : '更新'}が完了しました。`;
      data.hiddenBar = true;
      await new Promise<void>(resolve => setTimeout(() => { resolve(); }, 1500));

      dialog.close();
      this.router.navigate([`/${this.path.admin.list}`]);
      return;
    } catch (err) {
      console.error(err);
      // Waiting for dialog closing because native alertdialog make block to scripts.
      await new Promise(resolve => {
        dialog.afterClosed().subscribe(resolve);
        dialog.close();
      });
      alert(`管理者の${this.isCreate ? '作成' : '更新'}に失敗しました。`);
    }
    this.pending = false;
  }

  async createAdmin() {
    try {
      const adminRole = this.adminRoleForm.get('role').value.roleName;
      let group = [];
      let roleName: AdminUser.RoleEnum;
      if (adminRole === 'サービス管理者') {
        roleName = AdminUser.RoleEnum.ServiceAdmin;
        group = [];
      } else if (adminRole === '媒体管理者') {
        roleName = AdminUser.RoleEnum.GroupAdmin;
        group = this.adminUserForm.get('groups').value;
      } else {
        roleName = AdminUser.RoleEnum.GroupEditor;
        group = this.adminUserForm.get('groups').value;
      }
      const newData: NewAdminUser = {
        uid: uuid(),
        groups: group,
        order: this.adminUserForm.get('order').value,
        email: this.adminUserForm.get('email').value,
        displayName: this.adminUserForm.get('displayName').value,
        summary: this.adminUserForm.get('summary').value,
        role: roleName,
        leaved: false,
      };
      this.apiService.createAdminUser(newData).subscribe(
        data => {
          if (data) {
            return true;
          }
        }, error => {
          console.error('error', error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  async updateAdmin() {
    const adminRole = this.adminRoleForm.get('role').value.roleName;
    let group = [];
    let roleName: AdminUser.RoleEnum;
    if (adminRole === 'サービス管理者') {
      roleName = AdminUser.RoleEnum.ServiceAdmin;
      group = [];
    } else if (adminRole === '媒体管理者') {
      roleName = AdminUser.RoleEnum.GroupAdmin;
      group = this.adminUserForm.get('groups').value;
    } else {
      roleName = AdminUser.RoleEnum.GroupEditor;
      group = this.adminUserForm.get('groups').value;
    }
    const groupIds: Array<string> = group.map((obj) => {
      if (typeof obj === 'object') {
        return obj.id;
      }
      return obj;
    });
    const updateData: NewAdminUser = {
      uid: this.adminUserForm.get('uid').value,
      order: this.adminUserForm.get('order').value,
      email: this.adminUserForm.get('email').value,
      displayName: this.adminUserForm.get('displayName').value,
      summary: this.adminUserForm.get('summary').value,
      role: roleName,
      groups: groupIds,
      leaved: this.adminUserForm.get('leaved').value,
    };
    this.apiService.updateAdminUser(this.uid, updateData).subscribe(
      data => {
        if (data) {
          return true;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  roleSelectChange(event: MatSelectChange) {
    const role = event.value as Role;
    const groups = _.get(this.admin, 'groups') || _.get(this.adminUserForm, 'value.groups');
    if (_.get(role, 'permission.service.administrate')) {
      this.adminUserForm.patchValue({ groups: null });
    } else {
      this.adminUserForm.patchValue({ groups });
    }
  }

  log() {
    console.log(this.adminUserForm);
    console.log(this.adminRoleForm);
  }
}
