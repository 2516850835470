import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  ComponentFactoryResolver,
  TemplateRef,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { defer, from, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  findIndex,
  isEqual,
  filter as _filter,
  assign,
  chunk,
  isError
} from 'lodash-es';

import { EsMemberVirutualScrollStrategy } from './es-member-virutual-scroll-strategy';
import { environment } from '@lu/environment';
import { Path } from '@lu/path';
import {
  ServicePermissions,
  Group,
  Member,
  BankAccount,
  Address,
  GroupPermissions,
  NewAddress,
  AdminUser,
  MemberConfidential,
} from '@lu/models';
import { UserBatchRegistrationComponent, ReadProfile } from '@lu/components/user-batch-registration/user-batch-registration.component';
import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { DialogService } from '@lu/services/dialog.service';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { UserSearchComponent } from '@lu/components/user-search/user-search.component';
import { MemberService } from '@lu/services/member.service';
import { MemberInviteDialogComponent } from '@lu/components/member-invite-dialog/member-invite-dialog.component';
import { MatchingService } from '@lu/services/matching.service';
import { defaultPermissions, groupEditor, groupManager, serviceAdmin } from '@lu/definitions/role';
import { ConfirmationDialogData } from '@lu/components/confirmation-dialog/confirmation-dialog.component';
import * as moment from 'moment';
import { CsvdlItemsSelectableComponent } from '@lu/components/csvdl-items-selectable/csvdl-items-selectable.component';

@Component({
  selector: 'lu-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [
    EsMemberVirutualScrollStrategy,
    DialogService,
  ]
})
export class UserListComponent implements OnInit, AfterViewInit {
  @ViewChild('memberSearch', { static: true }) public memberSearch: UserSearchComponent;
  @ViewChild('loadedUserList', { static: false }) private loadedUserList: TemplateRef<HTMLElement>;
  @ViewChild('userBatchContainer', { static: false, read: ViewContainerRef }) private userBatchContainer: ViewContainerRef;
  @ViewChild('accordion', { static: false }) private accordion: ElementRef;
  @ViewChild('accordionHead', { static: false }) private accordionHead: ElementRef;
  @ViewChild('createFileInput', { static: false }) private createFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('updateFileInput', { static: false }) private updateFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('csvData', { static: true }) public csvData: CsvdlItemsSelectableComponent;
  public serviceAdmin = serviceAdmin;
  public groupAdmin = groupManager;
  public groupEditor = groupEditor;
  public userSearchForm = new FormGroup({});
  public uid: string;
  public userList: Member[];
  public servicePermissions: ServicePermissions | GroupPermissions;
  private groupList: Array<Group>;
  public pageSize = 36;
  public membersTotal: number;
  public accordionOpened = false;
  public readonly path = Path;
  public sendWelcomeMail = false; // v1.0.0
  public userSearchedData: any;
  public isDisabledDownloadBtn: any = false;
  public csvHeadersValue: any;
  private headers = [
    'ID', '連携用アカウント', '氏名', '氏名（ふりがな）', 'ニックネーム（表示名）', '会員ステータス',
    '媒体', 'キャッチコピー', '郵便番号', '住所', 'プロフィール', 'メールアドレス', '電話番号',
    '性別', '結婚状況', '子あり・子なし', '子供', '生年月日', '生年月日の非公開', '職業', '業種',
    '職種', '身長', '体重', 'ハピコミュ登録日', 'その他URL', 'タグ', '編集部用タグ', '編集部備考',
    'PRボード', 'プロフィール画像', '公開日時', 'BlogURL', 'Twitterアカウント', 'Twitterフォロワー数',
    'Instagramアカウント1', 'Instagramフォロワー数', 'Youtubeアカウント', 'Tiktokアカウント'
  ];

  constructor(
    public dataSource: EsMemberVirutualScrollStrategy,
    private title: Title,
    private renderer2: Renderer2,
    private aRoute: ActivatedRoute,
    private dialog: DialogService,
    private CSVService: XLXSCSVService,
    private memberService: MemberService,
    private componentResolver: ComponentFactoryResolver,
    private apiService: MatchingService,
  ) { }

  ngOnInit() {
    this.aRoute.data.subscribe(data => {
      const permiss = data.servicePermissions;
      if (permiss.length > 0) {
        switch (permiss[0].role) {
          case AdminUser.RoleEnum.ServiceAdmin:
            this.servicePermissions = serviceAdmin;
            break;
          case AdminUser.RoleEnum.GroupEditor:
            this.servicePermissions = groupEditor;
            break;
          case AdminUser.RoleEnum.GroupAdmin:
            this.servicePermissions = groupManager;
            break;
          default:
            this.servicePermissions = defaultPermissions;
            break;
        }
      } else {
        this.servicePermissions = defaultPermissions;
      }
      this.groupList = data.groups;
      this.userSearchedData = data.usersearch;
    });
    if (!!this.userSearchedData) {
      this.memberSearch.followersCountStepsForm.setValue(this.userSearchedData.followersCountData);
      this.memberSearch.userSearchForm.setValue(this.userSearchedData.data);
    }
    this.setTitle();
    this.search();
    this.getMembersTotal();
  }

  changeCheckboxData() {
    const checkbox = [...this.csvData.basicInfoList, ...this.csvData.personalInfoList, ...this.csvData.profileList
      , ...this.csvData.otherList];
    const headers = this.memberService.getHeaders();
    let csvHeaders = [];
    let csvHeadersValue = [];
    checkbox.map(checkData => {
      csvHeaders = headers.filter((data) => {
        return checkData.key === data.key && checkData.checked === true;
      });
      csvHeadersValue = [...csvHeadersValue, ...csvHeaders];
    });
    this.isDisabledDownloadBtn = csvHeadersValue.length > 0 ? false : true;
    this.csvHeadersValue = csvHeadersValue;
  }

  ngAfterViewInit() {
    // for development
    if (!environment.production && this.aRoute.snapshot.queryParams.opened) {
      requestAnimationFrame(() => this.toggleAccordion());
    }
  }

  setTitle() {
    const currentTitle = this.title.getTitle();
    const titleSuffix = ' | ユーザー一覧';
    this.title.setTitle(currentTitle.replace(/(\s\|\s.+)?$/, titleSuffix));
  }

  getMembersTotal() {
    const query = this.memberSearch.buildSearchQurey();
    query._limit = -1;
    query._start = 0;
    if (query._sort && query._sort === 'connection_instagrams_followersCount:DESC') {
      delete query._sort;
    }
    this.apiService.getMember(query)
      .subscribe((result: any) => {
        // console.log(result)
        this.membersTotal = result.length;
      });
  }

  search() {
    const search = this.memberSearch.buildSearchQurey();
    search._limit = -1;
    search._start = 0;
    if (!environment.production) {
      console.log(search);
    }
    this.dataSource.searchMembers({
      search,
    });
  }

  toggleAccordion() {
    const accordionHTML: HTMLElement = this.accordion.nativeElement;
    const accordionHeadHTML: HTMLElement = this.accordionHead.nativeElement;
    const headHeight = accordionHeadHTML.offsetHeight;
    const childrenElement = accordionHTML.children;
    if (this.accordionOpened) {
      // close accordion
      this.renderer2.setStyle(accordionHTML, 'height', headHeight + 'px');
    } else {
      let childrenHeight = 0;
      _.forEach(childrenElement, (elem, i) => {
        const virtual = _.some(elem.classList, className => className.match(/^virtual-.+/));
        if (!virtual) {
          childrenHeight += (elem as HTMLElement).offsetHeight;
        }
      });
      // open accordion
      this.renderer2.setStyle(accordionHTML, 'height', childrenHeight + 'px');
    }
    this.accordionOpened = !this.accordionOpened;
  }

  browseFile(mode: string): void {
    const fileInput = mode === 'create' ? this.createFileInput : this.updateFileInput;
    fileInput.nativeElement.value = null;
    fileInput.nativeElement.click();
  }

  openProcessFailDialog(err: any) {
    const html = '<div>更新処理が失敗しました。</div>';
    this.dialog.openConfirmDialog({
      autoFocus: false,
      data: {
        html,
        apply: false
      }
    });
  }

  showErrorForUpdate(err: Error) {
    this.openProcessFailDialog(err);
  }

  generateMembersJSON(mode: string): Promise<void> {
    const data: LodingDialogData = { text: 'CSVデータ読み込み中...' };
    const loadingDialog = this.dialog.openLoadingDialog({ data, disableClose: true });
    const fileInput = mode === 'create' ? this.createFileInput : this.updateFileInput;
    const file = _.nth(fileInput.nativeElement.files, 0);
    const factory = this.componentResolver.resolveComponentFactory(UserBatchRegistrationComponent);
    const component = this.userBatchContainer.createComponent(factory);

    if (!(file instanceof File)) {
      return;
    }
    this.CSVService.convertFileToJSON(file)
      .pipe(
        map(list => {
          if (list) {
            const convertedList = this.memberService.convertHeadersFromJPStringToKey(list);
            return component.instance.parseJson(convertedList);
          }
        }),
        // Validate read values.
        switchMap(profiles => defer(async () => {
          await component.instance.getMastersOfMember();
          if (this.servicePermissions.group.read) {
            await component.instance.getAllGroups();
          } else {
            await component.instance.getOwnGroups(this.groupList);
          }
          await component.instance.validateMembersJSON(profiles, mode);
          loadingDialog.close();
          return profiles;
        })),
        switchMap(profiles => this.openConfirmationDialog(profiles, mode).afterClosed()
          .pipe(
            filter(result => !!result),
            map(() => profiles.map(prof => component.instance.covertToProfile(prof, mode)))
          )
        )
      )
      .subscribe(async members => {
        const [apply, cancelText] = [false, '確認'];
        // tslint:disable-next-line: no-shadowed-variable
        const data: LodingDialogData = { text: `メンバーを${mode === 'create' ? '登録' : '更新'}しています...` };
        const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });
        switch (mode) {
          case 'create':
            const memberCSVAdd = () => {
              return new Promise((resolve, reject) => {
                this.registerMembers(members)
                  .subscribe(() => {
                    resolve(0);
                  }, (err: HttpErrorResponse) => {
                    dialog.close();
                    console.error(err);
                    const html = `
                      <div>メンバーを登録できませんでした。</div>
                    `;
                    this.dialog.openConfirmDialog({
                      data: { html, apply, cancelText }
                    });
                    reject(err);
                  });
              });
            };
            await memberCSVAdd();
            dialog.close();
            this.dialog.openConfirmDialog({
              data: {
                apply,
                cancelText,
                text: 'メンバー登録が完了しました。',
              }
            });
            break;
          case 'update':
            const chunkSize = 10;
            const memberChunks = chunk(members, chunkSize);
            let errshow = false;
            let errMsg = null;
            const memberUpdate = () => {
              return new Promise((myresolve, myreject) => {
                const results = [];
                memberChunks.forEach((member: any, i: number) => {
                  const currentSize = i * chunkSize + member.length;
                  data.text = `メンバーを更新しています... (${currentSize}/${members.length})`;
                  if (!errshow) {
                    member.forEach(async (m: any) => {
                      if (m.member_confidential && m.member_confidential.member_confidential_tag_masters) {
                        m.member_confidential_tag_masters = m.member_confidential.member_confidential_tag_masters;
                      }
                      if (m.member_confidential && m.member_confidential.remarks) {
                        m.remarks = m.member_confidential.remarks;
                      }

                      m = _.omit(m, ['image1', 'image2', 'image3', 'image4', 'connection_twitter.followersCount']);
                      console.log(m)
                      this.apiService.updateMember(m.id, m)
                        .subscribe(async result => {
                          console.log('result', result);
                          results.push(result);
                          if (results.length === members.length) {
                            myresolve(results);
                          }
                        }, err => {
                          dialog.close();
                          console.error(err);
                          errshow = true;
                          errMsg = err;
                          myreject(err);
                        });
                    });

                  }
                });
              });
            };

            try {
              await memberUpdate();
            } catch (err) {
              dialog.close();
              errshow = true;
            }
            if (errshow) {
              dialog.close();
              this.showErrorForUpdate(errMsg);
            } else {
              dialog.close();
              this.dialog.openConfirmDialog({
                data: {
                  apply,
                  cancelText,
                  text: 'メンバー更新が完了しました。',
                }
              });
            }
            break;
          default:
            break;
        }
      }, err => {
        console.error(err);
        loadingDialog.close();
        this.openInvalidErrorDialog(err);
      });
  }

  registerMembers(json: any[]) {
    return this.apiService.createMemberFromCSV(json, String(this.sendWelcomeMail));
  }

  openConfirmationDialog(profiles: ReadProfile[], mode: string) {
    const dialogRef = this.dialog.openTemplateDialog(this.loadedUserList, {
      data: { profiles, mode },
      autoFocus: false
    });
    return dialogRef;
  }

  openInvalidErrorDialog(err: any) {
    let html = '<div>CSVファイルの以下の入力内容に誤りがあります。</div>';
    if (Array.isArray(err.results)) {
      _.forEach(err.results as string[], (message, i) => {
        if (i === 0) {
          html += `<br>`;
        }
        html += `<div>${message}</div>`;
      });
    } else {
      html += `<br /><div>CSVの形式が正しくありません。</div>`;
    }
    this.dialog.openConfirmDialog({
      panelClass: ['mat-dialog-scrollable'],
      minWidth: '600px',
      maxHeight: '90vh',
      autoFocus: false,
      data: {
        html,
        apply: false
      }
    });
  }

  downloadMembersCsv() {
    let query = {} as any; // search: [], size: 1000, from: 0
    const memberResult: Member[] = [];
    const request$ = body => this.apiService.getMember(body)
      .pipe(
        switchMap((result: Member[]) => {
          if (this.servicePermissions.administrate) {
            return of(result);
          }
          // Has no permissions
          const promises: Promise<Member>[] = [];
          result.forEach(member => promises.push(
            new Promise(async resolve => {
              const address: NewAddress = { postalCode: null, address: null };
              try {
                assign(member.address = address);
              } catch (err) {
                console.error(member, err);
              } finally {
                resolve({ ...member });
              }
            })
          ));
          return from(Promise.all(promises));
        }),
        switchMap(results => {
          memberResult.push(...results);
          return of(memberResult);
        }),
      );
    const dialog = this.dialog.openLoadingDialog({
      data: { text: 'CSVを作成しています...' },
      disableClose: true,
    });
    const headers = this.memberService.getHeaders();
    const { administrate } = this.servicePermissions;

    if (administrate) {
      const pos = findIndex(headers, ['key', 'profile']);
      const addressHeaders = [
        { key: 'postalCode', value: '郵便番号' },
        { key: 'address', value: '住所' },
      ];
      headers.splice(pos, 0, ...addressHeaders);
    }
    // Assign group list before query building.
    this.memberService.setGroups(this.groupList);
    query = this.memberSearch.buildSearchQurey();
    query.leaved = false;
    query._limit = -1;
    query._start = 0;
    request$(query).subscribe((member: any) => {
      // JSON、ヘッダーを渡してメンバー一覧のCSVを作れる形式のJSONに整形する
      member.forEach((mem: any) => {
        mem.memberNumber = mem.id;
      });
      const formattedJson = this.memberService.formatJSONForMemberCsv(member, this.csvHeadersValue);
      const fileName = `member-list_${moment().format('YYYY-MM-DD')}.csv`;
      this.CSVService.downloadCSV(formattedJson, fileName);
      dialog.close();
    }, (err: any) => {
      console.error(err);
      dialog.afterClosed().subscribe(() => {
        const data: ConfirmationDialogData = {
          text: 'CSVが作成出来ませんでした。',
          apply: false,
          cancelText: '確認'
        };
        this.dialog.openConfirmDialog({ data });
      });
      dialog.close();
    });
  }

  downloadTemplateCSV() {
    const templateData = [];
    this.headers.forEach(csvHead => {
      const templateHeader = {};
      templateHeader[csvHead] = '';
      templateData.push(templateHeader);
    });
    const fileName = `member-update-template.csv`;
    this.CSVService.downloadCSV(templateData, fileName);
  }


  unionArray(list: any[], item) {
    if (findIndex(list, o => isEqual(o, item)) >= 0) {
      return;
    }
    list.push(item);
  }

  arrayRemove(list: any[], item) {
    const index = findIndex(list, o => isEqual(o, item));
    if (index === -1) {
      return;
    }
    list.splice(index, 1);
  }

  openInvitationDailog() {
    const dialog = this.dialog.openComponentDialog(MemberInviteDialogComponent, {
      autoFocus: false,
      panelClass: ['invitation-dialog']
    });
  }
}
