import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { take, map } from 'rxjs/operators';
import { cloneDeepWith } from 'lodash';

import { Client } from '@lu/models';
import { MatchingService } from '@lu/services/matching.service';

@Injectable()
export class ClientResolver implements Resolve<Client> {
  constructor(
    private matchservice: MatchingService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const { clientId } = route.params;
    return this.matchservice.getEachClient(clientId, {})
      .pipe(
        take(1),
        map(client => cloneDeepWith(client) as Client)
      );
  }
}
