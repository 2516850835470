import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { MatAutocompleteSelectedEvent, FloatLabelType } from '@angular/material';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';

import { Observable, Subject, from } from 'rxjs';
import { tap, delayWhen } from 'rxjs/operators';
import * as _ from 'lodash';

export interface ScrollPanel {
  autoComplete: MatAutocomplete;
  event: WheelEvent;
  threshold: number;
  scrollTop: number;
  scrollBottom: number;
}


@Component({
  selector: 'lu-async-autocomplete-chips',
  templateUrl: './async-autocomplete-chips.component.html',
  styleUrls: ['./async-autocomplete-chips.component.scss']
})
export class AsyncAutocompleteChipsComponent implements OnInit {
  @ViewChild('selectionInput', { static: true }) inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true, read: MatAutocomplete }) autoComplete: MatAutocomplete;

  // 絞り込みが適用されたソースのリスト
  public filteredSource: Observable<any[]>;

  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() formControl = new FormControl(null);
  @Input() placeholder: string;
  @Input() selectable = true;
  @Input() removable = true;
  @Input() unique = true;
  @Input() required = false;
  @Input() max = Infinity;
  @Input() thresholdPercent = .8;
  @Input() displayWith: MatAutocomplete['displayWith'];
  /**
   * ソースとなる値
   */
  @Input() source: Observable<any[]> | null;
  /**
   * 選択された値
   */
  @Input() value: any[] = [];
  /**
   * 選択肢から項目が選択された場合にinvoke
   */
  @Output() keywordChange = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() scrollPanel = new EventEmitter<ScrollPanel>();

  constructor() {}

  ngOnInit() {
    this.formControl.valueChanges.subscribe(this.keywordChange);
    // reference
    // https://stackoverflow.com/questions/51293949/infinite-scroll-for-autocomplete-in-angular-material-6
    this.autoComplete.opened.pipe(
        delayWhen(() => from(new Promise<void>(resolve => {
          // Waiting for create panel.
          const getPanel = () => {
            if (this.autoComplete.panel) {
              return resolve();
            }
            requestAnimationFrame(() => getPanel());
          };
          getPanel();
        }))),
      ).subscribe(() => {
        this.removeScrollEventListener();
        this.autoComplete.panel.nativeElement
          .addEventListener('scroll', this.onScroll.bind(this));
      });
  }

  /**
   * chipのdeleteボタンを押下時にcall
   * 削除された値を選択値から取り除く
   */
  remove(val: any) {
    const pos = _.findIndex(this.value, o => _.isEqualWith(o, val, (a, b) => _.isEqual(a, b)));
    if (pos >= 0) {
      this.value.splice(pos, 1);
    }
    this.selectionChange.emit(this.value);
  }

  /**
   * 選択肢から選択が行われた際にcall
   */
  add($event: MatAutocompleteSelectedEvent) {
    const {value} = $event.option;
    const exists = !!_.find(this.value, o => _.isEqual(o, value));
    if (_.size(this.value) < this.max
      && (!this.unique || !exists)) {
      this.value.push(value);
    }
    this.selectionChange.emit(this.value);
    this.clearInput();
    this.inputRef.nativeElement.blur();
  }

  clearInput() {
    this.formControl.setValue(null);
    this.inputRef.nativeElement.value = '';
  }

  removeScrollEventListener() {
    this.autoComplete.panel.nativeElement
      .removeEventListener('scroll', this.onScroll);
  }

  onScroll(event: WheelEvent) {
    const element = event.target as HTMLElement;
    const threshold = this.thresholdPercent * 100 * element.scrollHeight / 100;
    const current = element.scrollTop + element.clientHeight;
    // console.log(`scroll ${current}, threshold: ${threshold}`);
    this.scrollPanel.next({
      autoComplete: this.autoComplete,
      event,
      threshold,
      scrollBottom: current,
      scrollTop: element.scrollTop,
    });
  }
}
