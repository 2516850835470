import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { chunk } from 'lodash-es';
import { from, of, NEVER } from 'rxjs';
import { filter, switchMap, map, catchError, tap, delayWhen, delay } from 'rxjs/operators';

import { Path } from '@lu/path';

import {
  AdminUser,
  Client,
  NewClient,
  ServicePermissions,
  GroupPermissions
} from '@lu/models';

import { XLXSCSVService } from '@lu/services/xlsx-csv.service';
import { DialogService } from '@lu/services/dialog.service';
import { LodingDialogData } from '@lu/components/loading-dialog/loading-dialog.component';
import { ClientsScrollStrategy } from './clients-scroll-strategy';
import { MatTable } from '@angular/material';
import { MatchingService } from '@lu/services/matching.service';
import { defaultPermissions, groupEditor, groupManager, serviceAdmin } from '@lu/definitions/role';

declare const Encoding: any; // lazy loading

const fixPhoneNumber = (phoneNum: string) => {
  if (!_.isString(phoneNum)) {
    return phoneNum;
  }
  let result = phoneNum.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
  result = result.replace(/[‐－―ー−]/g, '-');
  result = result.replace(/[＋]/g, '+');
  result = result.replace(/[\s]/g, ' ');
  return result;
};

@Component({
  selector: 'lu-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  providers: [ClientsScrollStrategy]
})
export class ClientListComponent implements OnInit, AfterViewInit {
  @ViewChild('clientsTable', { static: false, read: MatTable }) private table: MatTable<HTMLElement>;
  @ViewChild('fileInput', { static: false }) private fileInput: ElementRef<HTMLInputElement>;
  public clientSearchForm = new FormGroup({
    clientName: new FormControl('')
  });
  public clientList: Array<Client> = [];
  public servicePermissions: ServicePermissions | GroupPermissions;
  public path = Path;
  public serviceAdmin = serviceAdmin;
  public groupEditor = groupEditor;
  public groupManager = groupManager;
  public defaultPermission = defaultPermissions;

  public readonly columnToDisplay = [
    'clientNumber',
    'clientName',
    'phoneNumber',
    'email',
    'edit'
  ];
  private pageSize = 50;

  constructor(
    public dataSource: ClientsScrollStrategy,
    private aRoute: ActivatedRoute,
    private dialog: DialogService,
    private CSVService: XLXSCSVService,
    private matchservice: MatchingService
  ) { }

  ngOnInit() {
    this.loadClients();
    this.aRoute.data
      .subscribe((data: {
        servicePermissions: Array<AdminUser>
      }) => {
        const permiss = data.servicePermissions;
        if (permiss.length > 0) {
          switch (permiss[0].role) {
            case AdminUser.RoleEnum.ServiceAdmin:
              this.servicePermissions = serviceAdmin;
              break;
            case AdminUser.RoleEnum.GroupAdmin:
              this.servicePermissions = groupManager;
              break;
            case AdminUser.RoleEnum.GroupEditor:
              this.servicePermissions = groupEditor;
              break;
            default:
              this.servicePermissions = defaultPermissions;
              break;
          }
        } else {
          this.servicePermissions = defaultPermissions;
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.dataStream$
      .subscribe(clients => {
        if (this.clientList) {
          requestAnimationFrame(() => this.table.renderRows());
        }
        this.clientList = clients;
      });
  }

  generatedSerchQuery(clientName: string) {
    let query = {} as any;
    const orConditionList = [];
    const keywords = clientName.split(/\s+/).filter(v => !!v);

    if (keywords.length) {
      keywords.forEach(key => {
        const halfWidth = this.toHalfWidth(key);

        let fullWidth = this.toFullWidth(key);
        fullWidth = fullWidth === '' ? key : fullWidth;
        orConditionList.push(
          {
            clientName_containss: fullWidth.toLowerCase()
          },
          {
            clientName_containss: halfWidth.toLowerCase()
          },
          {
            clientName_containss: fullWidth.toUpperCase()
          },
          {
            clientName_containss: halfWidth.toUpperCase()
          }
        );
      });


      const idRegexp = /^id:(\d+)$/i;
      keywords.filter(k => idRegexp.test(k)).forEach(k => {
        const clientNumber = +k.match(idRegexp)[1];
        orConditionList.push({
          id: clientNumber
        });
      });

      keywords.forEach(key => {
        const email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email.test(key)) {
          orConditionList.push(
            {
              email: key
            }
          );
        }
      });
    }

    query = this.generatedOrCondition(orConditionList);

    query._sort = 'id:ASC';

    query._limit = this.pageSize;

    query._start = 0;

    return query;
  }

  toHalfWidth(chars: string) {
    let ascii = '';
    for (let i = 0, l = chars.length; i < l; i++) {
      let c = chars[i].charCodeAt(0);

      // make sure we only convert half-full width char
      if (c >= 0xFF00 && c <= 0xFFEF) {
        // tslint:disable-next-line: no-bitwise
        c = 0xFF & (c + 0x20);
      }

      ascii += String.fromCharCode(c);
    }

    return ascii;
  }

  toFullWidth(txtstring: string) {
    let tmp = '';
    for (let i = 0; i < txtstring.length; i++) {
      if (txtstring.charCodeAt(i) === 32) {
        tmp = tmp + String.fromCharCode(12288);
      }
      if (txtstring.charCodeAt(i) < 127) {
        tmp = tmp + String.fromCharCode(txtstring.charCodeAt(i) + 65248);
      }
    }
    return tmp;
  }

  generatedOrCondition(querList: any) {
    const index = querList.length;
    const orQuery = {} as any;
    if (index <= 0) {
      return orQuery;
    }
    querList.forEach((element: any, i: number) => {
      const key = Object.keys(element)[0];
      const value = Object.values(element)[0];
      orQuery[`_where[_or][${i}][${key}]`] = value;
    });

    return orQuery;
  }

  loadClients() {
    const query = this.generatedSerchQuery(this.clientSearchForm.get('clientName').value);
    this.dataSource.search(query);
  }

  searchClient() {
    const query = this.generatedSerchQuery(this.clientSearchForm.get('clientName').value);
    this.dataSource.search(query);
  }

  browseFile(): void {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  registerClients() {
    const file = _.nth(this.fileInput.nativeElement.files, 0);
    const dialog = this.dialog.openLoadingDialog({
      data: { text: 'データをチェックしています...' },
      disableClose: true,
    });
    if (!Encoding || !(file instanceof File)) {
      return;
    }
    this.CSVService.convertFileToJSON(file)
      .pipe(
        map(list => list.map(item => {
          item.phoneNumber = fixPhoneNumber(item.phoneNumber);
          return item;
        })),
        switchMap(list => of(list).pipe(
          tap(clients => this.validateClientsJSON(clients)),
          catchError(err => {
            dialog.afterClosed().subscribe(() => {
              this.openInvalidErrorDialog(err);
            });
            dialog.close();
            return NEVER;
          }),
        )),
        delayWhen(clients => {
          return from(this.hasDuplicatedForeignKeys(clients))
            .pipe(catchError(err => {
              console.error(err);
              dialog.afterClosed().subscribe(() => {
                this.openInvalidErrorDialog(err, '<div>登録済みのクライアントが含まれています。</div>');
              });
              dialog.close();
              return NEVER;
            }));
        }),
        switchMap(clients => {
          dialog.close();
          return dialog.afterClosed()
            .pipe(map(() => clients));
        })
      )
      .subscribe(clients => {
        this.openClientsRegistrationConfirmDialog(clients);
      });
  }

  async addClients(json: NewClient[]) {
    const data: LodingDialogData = { text: 'クライアント情報を登録しています...' };

    const dialog = this.dialog.openLoadingDialog({ data, disableClose: true });
    const chunkSize = 25;
    const chunks = chunk(json, chunkSize);
    try {
      for (let i = 0; i < chunks.length; i++) {
        const list = chunks[i];
        const currentSize = i * chunkSize + list.length;
        data.text = `クライアント情報を登録しています... (${currentSize}/${json.length})`;

        json.filter(client => {
          this.matchservice.addClient(client).subscribe(
            addedClient => {
              if (addedClient) {
                this.clientList.push(addedClient);
              }
            },
            error => {
              console.error('error', error);
            }
          );
        });
        await new Promise<void>(r => setTimeout(() => r(), 500));
      }
    } catch (err) {
      throw err;
    } finally {
      dialog.close();
    }
  }

  validateClientsJSON(json: Client[]) {
    const phoneNumberReg = new RegExp(/^(\+?\d+\s)?\d+(-?\d+)*$/);
    const emailReg = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    // CSVの行数と表示を合わせる為、2スタート
    const results: string[] = [];
    let index = 1;
    for (const elem of json) {
      if (_.size(elem.foreignKey) <= 0) {
        results.push(`${index}行目: 「foreignKey」は必須項目です。`);
      }
      if (_.size(elem.clientName) <= 0) {
        results.push(`${index}行目: 「clientName」は必須項目です。`);
      }
      if (_.size(elem.clientNameKana) <= 0) {
        results.push(`${index}行目: 「clientNameKana」は必須項目です。`);
      }
      if (!_.isNil(elem.phoneNumber)
        && !phoneNumberReg.test(elem.phoneNumber)) {
        results.push(`${index}行目: 「phoneNumber」の形式が正しくありません。(${elem.phoneNumber})`);
      }
      if (!_.isNil(elem.email)
        && !emailReg.test(elem.email)) {
        results.push(`${index}行目: 「email」の形式が正しくありません。(${elem.email})`);
      }
      if (_.size(elem.remarks) > 2000) {
        results.push(`${index}行目: 「remarks」は2000文字以内にする必要があります。(${_.size(elem.remarks)}文字)`);
      }
      index++;
    }
    if (results.length) {
      throw { results };
    }
  }

  async hasDuplicatedForeignKeys(list: Pick<Client, 'foreignKey'>[]) {
    const results: (string | null)[] = [];
    const chunkSize = 500;
    const chunks = chunk(list, chunkSize);
    for (let i = 0; i < chunks.length; i++) {
      const ch = chunks[i];
      results.push(...await Promise.all(ch.map((item, index) => {
        const { foreignKey: key } = item;
        const pos = (i * chunkSize) + index + 1;
        return new Promise<string>(async resolve => {
          const snap = this.clientList.filter(data => {
            return data.foreignKey === key;
          }
          );
          if (snap.length > 0) {
            return resolve(`${pos}行目: 「foreignKey」は既に登録されています。(${key})`);
          }
          resolve(null);
        });
      })));
    }
    // Throw error if exists duplicated foreignKey.
    const filteredResults = results.filter(result => !!result);
    if (filteredResults.length > 0) {
      throw { results: filteredResults };
    }
  }

  openClientsRegistrationConfirmDialog(json: NewClient[]) {
    const dialogRef = this.dialog.openConfirmDialog({
      data: {
        text: '読み込んだクライアント情報を登録します。よろしいですか？'
      }
    });
    dialogRef.afterClosed()
      .pipe(
        filter(result => !!result),
        switchMap(() => this.addClients(json)),
        delay(1000),
      )
      .subscribe(async () => {
        const data: LodingDialogData = {
          text: 'クライアントの登録が完了しました',
          hiddenBar: true,
        };
        const dialog = this.dialog.openLoadingDialog({ data });

        await new Promise<void>(resolve => setTimeout(() => resolve(), 1500));
        dialog.close();

        this.loadClients();
      }, err => {
        console.error(err);
        this.dialog.openConfirmDialog({
          data: {
            text: 'クライアントを登録できませんでした。',
            apply: false,
            cancelText: '確認'
          }
        });
      });
  }

  openInvalidErrorDialog(err: { results: string[] }, html = '<div>CSVファイルの以下の入力内容に誤りがあります。</div>') {
    if (Array.isArray(err.results)) {
      _.forEach(err.results as string[], (message, i) => {
        if (i === 0) {
          html += `<br>`;
        }
        html += `<div>${message}</div>`;
      });
    }
    this.dialog.openConfirmDialog({
      panelClass: ['mat-dialog-scrollable'],
      minWidth: '600px',
      maxHeight: '90vh',
      data: {
        html,
        apply: false
      }
    });
  }
}
