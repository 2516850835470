import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable()
export class MailRequestRequestsResolver implements Resolve<Array<any>> {
 constructor(
    private afStore: AngularFirestore,
  ) {}

 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const {docId} = route.params;
    const path = `mailRequests/${docId}/requests`;
    // get each latest documents.
    return this.afStore.collection<any>(path)
      .valueChanges({idField: '_id'})
      .pipe(
        take(1),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      );
  }
}
