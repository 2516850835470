import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatchingService } from '@lu/services/matching.service';

@Component({
  selector: 'lu-entry-cancel-dialog',
  templateUrl: './entry-cancel-dialog.component.html',
  styleUrls: ['./entry-cancel-dialog.component.scss']
})
export class EntryCancelDialogComponent implements OnInit {

  defaultCancelText = `集英社ハピプラ・コミュニティからのお知らせです。

大変申し訳ございませんが、ご応募いただきました案件へのご参加を見送ることとなりましたので、お知らせいたします。

別案件へのエントリー、お待ちしております。`;

  public entryCancelForm = new FormGroup({
    message: new FormControl(this.defaultCancelText, Validators.required)
  });

  constructor(
    public dialogRef: MatDialogRef<EntryCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private apiService: MatchingService
  ) { }

  ngOnInit() {
  }

  cancelEntry() {
    const body = {
      ids: this.data.memberIds,
      message: this.entryCancelForm.get('message').value,
      projectId: this.data.projectId
    };
    this.apiService.sendCancelMessage(body)
      .subscribe(() => {
        this.dialogRef.close(true);
      }, err => console.error(err));
  }

}
