import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Path } from '@lu/path';
import { AuthService } from '@lu/services/auth.service';
import * as _ from 'lodash';
import { environment } from '@lu/environment';

@Component({
  selector: 'lu-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.pattern(/@{1}([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
    ]),
    password: new FormControl(null, [
      Validators.required,
    ])
  });
  public completed = false;
  public pending = false;
  public mode: 'signIn' | 'sendPasswordResetMail' = null;
  public readonly path = Path;
  private onDestroy$ = new Subject();

  constructor(
    private authService: AuthService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
    const { mode } = this.aRoute.snapshot.queryParams;
    this.updateAuthActionMode(mode);
    // Watch url update
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.aRoute.snapshot.queryParams.mode),
        filter(currentMode => currentMode !== this.mode)
      )
      .subscribe(currentMode => {
        this.updateAuthActionMode(currentMode);
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  updateQuery(newquery: any) {
    const query = this.aRoute.snapshot.queryParams;
    this.router.navigate([`${this.path.auth.login}`], { queryParams: { ...query, ...newquery } });
  }

  updateAuthActionMode(mode: any) {
    this.mode = mode;
    this.completed = false;
    switch (mode) {
      case 'signIn':
      case 'sendPasswordResetMail':
        this.loginForm.get('password').clearValidators();
        break;
      default:
        this.loginForm.get('password').setValidators(Validators.required);
        break;
    }
    this.loginForm.get('password').updateValueAndValidity();
  }

  authAction() {
    switch (this.mode) {
      case 'sendPasswordResetMail':
        const { email } = this.loginForm.value;
        this.sendPasswordResetMail(email);
        break;
      default:
        this.login();
        break;
    }
  }

  async login() {
    // tslint:disable-next-line: deprecation
    event.preventDefault();
    // console.log(this.loginForm);
    const { redirectTo } = this.aRoute.snapshot.queryParams;
    const { email, password } = this.loginForm.value;
    const user: any = await (async () => {
      if (this.mode === 'signIn') {
        return await this.signInWithEmailLink(email);
      }
      return await this.signInWithEmailAndPassword(email, password);
    })();
    if (!user) {
      return;
    }
    this.router.navigate([redirectTo || `/${this.path.admin.list}`]);
  }

  signInWithEmailAndPassword(email: string, password: string) {
    this.pending = true;
    return this.authService.login(email, password)
      .catch((err: any) => {
        console.error(err);
        this.pending = false;
        this.loginForm.setErrors({ auth: { message: 'Identifier or password invalid.' } });
        this.loginForm.valueChanges
          .pipe(take(1))
          .subscribe(() => {
            this.loginForm.setErrors({ auth: null });
          });
      });
  }

  signInWithEmailLink(email: string) {
    this.pending = true;
    return this.afAuth.auth.signInWithEmailLink(email, window.location.href)
      .catch((err: Error) => {
        console.error(err);
        this.pending = false;
        this.loginForm.setErrors({
          auth: { message: 'ログインコードの有効期限が切れています。' },
          signInWithEmail: { email }
        });
        this.loginForm.valueChanges
          .pipe(take(1))
          .subscribe(() => {
            this.loginForm.setErrors({ auth: null, signInWithEmail: null });
          });
      });
  }

  async sendPasswordResetMail(email: string) {
    this.pending = true;
    try {
      await this.afAuth.auth.sendPasswordResetEmail(email, {
        url: `${environment.hosting}/${this.path.auth.login}`,
      });
      this.completed = true;
    } catch (err) {
      console.error(err);
      this.loginForm.setErrors({
        sendPasswordResetMail: { message: 'メールを送信できませんでした。' }
      });
    }
    this.pending = false;
  }

  log() {
    console.log(this.loginForm);
  }
}
