import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

import { findIndex, union, find, get } from 'lodash-es';
import { tap } from 'rxjs/operators';

import { Project, Member } from '@lu/models';
import { EsMemberVirutualScrollStrategy } from '@lu/pages/user-list/es-member-virutual-scroll-strategy';
import { UserSearchComponent } from '@lu/components/user-search/user-search.component';
import { environment } from '@lu/environment';
import { MatchingService } from '@lu/services/matching.service';
import { DialogService } from '@lu/services/dialog.service';

type memberFields = 'image1' | 'id' | 'displayName' | 'fullName' | 'fullNameKana' | 'uid';
export interface UserSearchDialogData {
  order?: Project;
  buttonMessage: string;
  selectAll?: boolean;
  _limit?: number;
}

@Component({
  selector: 'lu-user-search-dialog',
  templateUrl: './user-search-dialog.component.html',
  styleUrls: ['./user-search-dialog.component.scss'],
  providers: [EsMemberVirutualScrollStrategy]
})
export class UserSearchDialogComponent implements OnInit {
  @ViewChild(UserSearchComponent, { static: true }) userSearch: UserSearchComponent;
  @Input() containLeaved = false;
  @Input() additinalSearchForm: FormGroup;
  @Input() additinalConditions: Record<string, {
    label: string,
    type?: 'input' | 'textarea' | 'checkbox' | 'radio' | 'select',
    value: {
      label: string,
      query: any
    }[]
  }>;
  @Input() startAt: string;
  @Input() endAt: string;
  public selectedMemberList: (Pick<Member, memberFields>)[] = [];
  public userSearchForm = new FormGroup({});
  private pageSize = 20;

  constructor(
    public dialogRef: MatDialogRef<UserSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserSearchDialogData,
    public dataSource: EsMemberVirutualScrollStrategy,
    public apiService: MatchingService,
    public dialogService: DialogService
  ) { }

  ngOnInit() {
    this.search();
  }

  generateOrQuery() {
    return { _or: [] };
  }

  search() {
    const query = {
      search: this.userSearch.buildSearchQurey()
    };
    // Assert forced query.
    query.search._start = 0;
    query.search._limit = this.data._limit ? this.data._limit : this.pageSize;
    Object.values(get(this.additinalSearchForm, 'value') || {}).forEach((value: any) => {
      if (value !== 'NULL') {
        if (value === 'noScheduled') {
          this.generatedRangeProject().subscribe(
            results => {
              let entryMembers = [];
              results.forEach(r => {
                const entries: any = r.entries;
                if (entries.length > 0) {
                  entries.forEach((e: any) => {
                    entryMembers.push(e.member);
                  });
                }
              });
              entryMembers = entryMembers.filter((v, i, a) => a.indexOf(v) === i);

              const orQueryForNotEntryPeriod = this.generateOrQuery();
              query.search._where.push(
                { id_ne: entryMembers }
              );
              orQueryForNotEntryPeriod._or.push(
                { entries_null: true }
              );
              query.search._where.push(orQueryForNotEntryPeriod);
              this.dataSource.searchMembers(query);
            }, err => console.error(err)
          );
        } else if (value === 'haveAfford') {
          this.generatedRangeProject().subscribe(
            results => {
              let entryMembers = [];
              results.forEach(r => {
                const entries: any = r.entries;
                if (entries.length > 0) {
                  entries.forEach((e: any) => {
                    entryMembers.push(e.member);
                  });
                }
              });
              entryMembers = entryMembers.filter((v, i, a) => a.indexOf(v) === i);

              const orQueryForNotEntryPeriod = this.generateOrQuery();
              query.search._where.push(
                { id: entryMembers }
              );
              orQueryForNotEntryPeriod._or.push(
                { entries_null: false }
              );
              query.search._where.push(orQueryForNotEntryPeriod);
              this.dataSource.searchMembers(query);
            }, err => console.error(err)
          );
        }
      } else {
        this.dataSource.searchMembers(query);
      }
    });
    if (!environment.production) {
      console.log(query);
    }
  }

  generatedRangeProject() {
    const orderQuery = { _where: [], _limit: 10000, _start: 0 };
    const orQuery = this.generateOrQuery();
    orQuery._or.push(
      {
        eventStartAt_gt: this.startAt,
        eventEndAt_lt: this.endAt
      },
      {
        eventStartAt_gte: this.startAt,
        eventEndAt_lte: this.endAt
      },
      {
        eventStartAt_gte: this.startAt,
        eventEndAt_lte: this.endAt,
        eventEndAt_gte: this.endAt
      },
      {
        eventStartAt_lte: this.startAt,
        eventEndAt_gte: this.endAt,
        eventEndAt_lte: this.endAt
      }
    );
    orderQuery._where.push(orQuery);
    return this.apiService.getProject(orderQuery);
  }

  addToSelection(member: Member) {
    const exists = find(this.selectedMemberList, ['id', member.id]);
    if (exists) {
      return;
    }
    this.selectedMemberList.push(member);
    this.selectedMemberList = union(this.selectedMemberList);
  }

  async addAllToSelection() {
    this.dataSource.dataStream$
    .pipe(tap(() => {
      this.dataSource.selectAllMembers();
    }))
    .subscribe(all => {
      this.dialogRef.close(all);
    });
  }

  removeFromSelection(uid: string) {
    const index = findIndex(this.selectedMemberList, ['id', uid]);
    if (index === -1) {
      return;
    }
    this.selectedMemberList.splice(index, 1);
  }
}
