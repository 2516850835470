import { Injectable } from '@angular/core';
import { cloneDeep, find, filter } from 'lodash-es';

import { AdminUser, Member, MailActivity } from '@lu/models';
import { MatchingService } from './matching.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  private headers = [
    { key: 'id', value: 'ID' },
    { key: 'name', value: '名前' },
    { key: 'providerLINE', value: 'LINE連携' },
    { key: 'email', value: 'メールアドレス' },
    { key: 'sendResult', value: '送信結果' },
  ];
  public resultEvents = [
    { label: 'メール送信中', value: 'processed' },
    { label: 'メール送信成功', value: 'delivered' },
    { label: 'メール送信失敗（一時的なエラー）', value: 'deffered' }, // Temporary rejected.
    { label: 'メール送信失敗（受信拒否）', value: 'bounce' }, // Server hadn't accepted.
    { label: 'メール送信失敗（その他）', value: 'dropped' }, // Failure sending.
  ];

  constructor(
    private apiService: MatchingService
  ) { }

  hasProviderLINE(member: any): boolean {
    if (member.lineId || member.lineDisplayName) {
      return true;
    }
    return false;
  }

  getHeaders() {
    return cloneDeep(this.headers);
  }

  getSendResult(result: any) {
    if (result.result) {
      return find(this.resultEvents, ['value', result.result]).label;
    }
    return '-';
  }

  async formatJSONForMailCsv(
    items: MailActivity[],
    results: any[],
    headers: { key: string, value: string }[],
    admins: AdminUser[],
    members: Member[]): Promise<any> {
    console.log({ items, results, headers });
    const formattedJson = [];
    const [formattedJsonAdmin, formattedJsonMember, formattedJsonDirect] = [
      this.formatJSONForMailCsvAdmin(filter(items, ['type', MailActivity.TypeEnum.Admin]), results, headers, admins),
      this.formatJSONForMailCsvMember(filter(items, ['type', MailActivity.TypeEnum.Member]), results, headers, members),
      this.formatJSONForMailCsvDirect(filter(items, ['type', MailActivity.TypeEnum.DirectInput]), results, headers),
    ];
    // console.log({formattedJsonAdmin, formattedJsonMember, formattedJsonDirect});
    formattedJson.push(...formattedJsonMember, ...formattedJsonAdmin, ...formattedJsonDirect);
    return formattedJson;
  }

  formatJSONForMailCsvAdmin(
    items: MailActivity[],
    results: any[],
    headers: { key: string, value: string }[],
    admins: AdminUser[]) {
    const adminJSON = [];
    for (const item of filter(items, ['type', MailActivity.TypeEnum.Admin])) {
      const mailData = {};
      const admin = find(admins, ['uid', item.uid]);
      // console.log({ admin });
      for (const header of headers) {
        switch (header.key) {
          case 'id':
            mailData[header.value] = admin.id;
            break;
          case 'name':
            mailData[header.value] = admin.displayName;
            break;
          case 'providerLINE':
            mailData[header.value] = '';
            break;
          case 'email':
            mailData[header.value] = admin.email;
            break;
          case 'sendResult':
            const result = item ? item : null;
            mailData[header.value] = result ? this.getSendResult(result) : '-';
            break;
          default:
            mailData[header.value] = item[header.key];
            break;
        }
      }
      adminJSON.push(mailData);
    }
    // console.log('admin json ==> ', adminJSON);
    return adminJSON;
  }

  formatJSONForMailCsvMember(
    items: MailActivity[],
    results: any[],
    headers: { key: string, value: string }[],
    members: Member[]) {
    const memberJSON = [];
    for (const item of filter(items, ['type', MailActivity.TypeEnum.Member])) {
      const mailData = {};
      const member = find(members, ['uid', item.uid]);
      // console.log({member});
      for (const header of headers) {
        switch (header.key) {
          case 'id':
            mailData[header.value] = member.id;
            break;
          case 'name':
            const displayName = member.displayName || '-';
            const fullName = member.fullName || '-';
            mailData[header.value] = `${displayName}（${fullName}）`;
            break;
          case 'providerLINE':
            const hasProvider = this.hasProviderLINE(member);
            // console.log({hasProvider});
            mailData[header.value] = hasProvider ? 'LINE連携あり' : 'LINE連携なし';
            break;
          case 'email':
            mailData[header.value] = member.email1;
            break;
          case 'sendResult':
            const result = item ? item : null;
            mailData[header.value] = result ? this.getSendResult(result) : '-';
            break;
          default:
            mailData[header.value] = item[header.key];
            break;
        }
      }
      memberJSON.push(mailData);
    }
    return memberJSON;
  }

  formatJSONForMailCsvDirect(
    items: MailActivity[],
    results: any[],
    headers: { key: string, value: string }[]) {
    const directJSON = [];
    for (const item of filter(items, ['type', MailActivity.TypeEnum.DirectInput])) {
      const mailData = {};
      for (const header of headers) {
        switch (header.key) {
          case 'id':
          case 'name':
          case 'providerLINE':
            mailData[header.value] = '';
            break;
          case 'email':
            mailData[header.value] = item.email;
            break;
          case 'sendResult':
            const result = item ? item : null;
            mailData[header.value] = result ? this.getSendResult(result) : '-';
            break;
          default:
            mailData[header.value] = item[header.key];
            break;
        }
      }
      directJSON.push(mailData);
    }
    return directJSON;
  }
}
